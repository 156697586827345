interface Props {
  size?: number;
  color?: string;
}

export const IssueGiftCardIcon = ({ size = 82, color = '#414A53' }: Props) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 82 82"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="41" cy="41" r="41" fill="#F9F9F9" />
    <path
      d="M29.8294 41.0002H40.6717M55.6195 37.4163L28.1821 26.1919C25.8006 25.2177 23.4221 27.5927 24.3929 29.9756L26.6387 35.4879L28.8304 41.0003L26.6387 46.5126L24.3929 52.0249C23.4221 54.4078 25.8006 56.7829 28.1821 55.8086L55.6195 44.5842C58.8276 43.2718 58.8276 38.7287 55.6195 37.4163Z"
      stroke={color}
      strokeWidth="3.83333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
