const getAdminUserName = (first_name?: string, last_name?: string) => {
  if (first_name && last_name) {
    return first_name.concat(' ', last_name);
  }
  if (first_name && !last_name) {
    return first_name;
  }
  if (!first_name && last_name) {
    return last_name;
  }
  return 'Admin';
};

export default getAdminUserName;
