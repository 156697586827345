import { Box, BoxProps, Divider, Typography } from '@mui/material';
import React from 'react';
import { OrderType } from 'src/@types/v2/orders/list';
import { fCurrency } from '@utils/formatter-number';
import { fDateFull } from '@utils/date-time';
import { calculatePriceBeforeDiscount, getQuantity } from '@utils/product';
import { BoxFlexBC } from 'src/styles';
import { getOrderStatusLabel } from '@utils/orders';

interface Props {
  selectedOrderDetails: OrderType;
}

interface IProps {
  title: string;
  value: string;
  middleValue?: string;
}

type ItemProps = IProps & BoxProps;

function LeftSection({ selectedOrderDetails }: Props) {
  const { billing } = selectedOrderDetails;

  return (
    <>
      <Box mx={3} mt="24px">
        <BoxFlexBC>
          <Typography variant="h6">
            {getOrderStatusLabel(selectedOrderDetails.order_status)}
          </Typography>
          {/* <Typography color="primary" variant="subtitle1">
            View detail
          </Typography> */}
        </BoxFlexBC>
        <Divider sx={{ my: '20px' }} />
        <Item mt={1} title="No order" value={`#${selectedOrderDetails.order_number}`} />
        <Item mt={1} title="Order placed" value={fDateFull(selectedOrderDetails.createdAt)} />
        <Item mt={1} title="Store" value={selectedOrderDetails.branch.location_name || 'N/A'} />
      </Box>
      <Divider sx={{ my: '20px' }} />
      <Box mx={3}>
        <Typography variant="h6">Deliver to</Typography>
        <Typography variant="body1" mt={2}>
          <strong>{billing?.name}</strong>
        </Typography>
        <Item mt={1} title="Address" value={selectedOrderDetails.shipping?.address} />
        <Item mt={1} title="Detail Address" value={selectedOrderDetails.shipping?.address_detail} />
        <Item mt={1} title="Note to courier" value={selectedOrderDetails.shipping?.shipping_note} />
      </Box>
      <Divider sx={{ my: '20px' }} />
      <Box mx={3}>
        <Typography variant="h6">Payment details</Typography>
        <Item
          mt={2}
          title="Sub total"
          middleValue={`${getQuantity(selectedOrderDetails.items)} Products`}
          value={fCurrency(calculatePriceBeforeDiscount(selectedOrderDetails))}
        />
        {selectedOrderDetails.discount_total ? (
          <Item
            mt={1}
            title="Discount"
            value={`-${fCurrency(selectedOrderDetails.discount_total)}`}
          />
        ) : null}
        {selectedOrderDetails.additional_cost.map((cost) => (
          <Item key={cost._id} mt={1} title={cost.name} value={fCurrency(cost.amount)} />
        ))}
        <Divider sx={{ my: '20px' }} />
        <BoxFlexBC>
          <Typography variant="h6">Total</Typography>
          <Typography variant="h6">{selectedOrderDetails.total} kr.</Typography>
        </BoxFlexBC>
      </Box>
      <Divider sx={{ my: '20px' }} />
      <BoxFlexBC mx={3}>
        <Typography>Payment method</Typography>
        <Typography>{selectedOrderDetails.payment.payment_method_title || 'N/A'}</Typography>
      </BoxFlexBC>
    </>
  );
}

const Item = ({ title, value, middleValue, ...other }: ItemProps) => (
  <BoxFlexBC {...other}>
    <Typography variant="body1" color="text.secondary">
      {title}
    </Typography>
    <Typography variant="body1">{middleValue}</Typography>
    <Typography variant="body1">{value}</Typography>
  </BoxFlexBC>
);

export default LeftSection;
