import { SnackbarType } from 'src/@types/v2/snackbar';

interface ShowSnackbarType {
  message: string;
  variant: 'success' | 'warning' | 'info' | 'error';
  enqueueSnackbar?: SnackbarType;
  redirect?: () => void;
}

const showSnackbar = ({ message, variant, enqueueSnackbar, redirect }: ShowSnackbarType) =>
  enqueueSnackbar
    ? enqueueSnackbar(message, {
        variant,
        autoHideDuration: redirect ? 1500 : 5000,
        onClose: () => {
          if (redirect) {
            redirect();
          }
        },
        style: { whiteSpace: 'pre-line' },
      })
    : null;

export default showSnackbar;
