import { useState } from 'react';
import { useRouter } from 'next/router';
import { Box, Divider, Typography, Stack, MenuItem } from '@mui/material';
import { useSnackbar } from '@components/snackbar';
// routes
import { PATH_AUTH } from '@routes/paths';
// hooks
import { usePropelAuthContext } from 'src/auth/useAuthContext';
import useIsMountedRef from '@hooks/useIsMountedRef';
// components
import { AvatarCustom } from '@components/images';
import MenuPopover from '@components/menu-popover/MenuPopover';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    linkTo: '/',
  },
  // {
  //   label: 'Profile',
  //   linkTo: '/dashboard',
  // },
  // {
  //   label: 'Settings',
  //   linkTo: PATH_DASHBOARD.user.account,
  // },
];

// ----------------------------------------------------------------------

interface Props {
  isCollapse: boolean;
}

export default function AccountPopover({ isCollapse }: Props) {
  const router = useRouter();

  const { user, role, logout } = usePropelAuthContext();

  const isMountedRef = useIsMountedRef();

  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleClickItem = (linkTo: string) => {
    router.push(linkTo);
    handleClose();
  };

  const handleLogout = async () => {
    try {
      await logout();
      router.replace(PATH_AUTH.login);

      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        onClick={handleOpen}
        sx={{
          borderRadius: 1,
          py: isCollapse ? 0 : 1,
          px: 2,
          cursor: 'pointer',
          color: 'text.primary',
          '&:hover': {
            bgcolor: '#F4F6F8',
          },
        }}
      >
        {user && (
          <AvatarCustom
            url={user.profile.profile_picture.url}
            alt={user.profile.profile_picture.alt}
            profileColor={user.profile.profile_color}
            title={`${user.first_name} ${user.last_name}`}
            subtitle={role as string}
          />
        )}
      </Stack>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, pl: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user && `${user.first_name} ${user.last_name}`}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user && `${user.email.email_address}`}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
