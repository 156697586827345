import { useMemo, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography, Stack, Avatar, Menu } from '@mui/material';
import { useSnackbar } from '@components/snackbar';
import Iconify from '@components/iconify/Iconify';
import { usePropelAuthContext } from 'src/auth/useAuthContext';
import { useBranches } from '@redux/reducers/settings/branches';
import { useDispatch } from '@redux/store';
import { getAdminBranches } from '@redux/actions/settings/branches';
import { useAxios } from 'src/auth/AxiosContext';
import { showSnackbar } from '@utils/snackbar';
import { getCurrentStore } from '@utils/store';
import BranchSelector from './BranchSelector';
import StoreSelector from './StoreSelector';

interface Props {
  isCollapse: boolean;
}

export default function StorePopover({ isCollapse }: Props) {
  const axiosInstance = useAxios();
  const { user, currentStoreId, globalBranchIds, setGlobalBranchIds } = usePropelAuthContext();
  const { adminBranches, isLoading } = useBranches();
  const [localActiveBranches, setLocalActiveBranches] = useState<string[]>(globalBranchIds);
  const [open, setOpen] = useState<HTMLElement | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const isOpen = Boolean(open);

  const currentStore = useMemo(() => {
    if (user && currentStoreId) {
      const store = getCurrentStore(
        currentStoreId,
        user.stores.map((s) => s.store)
      );
      if (store) {
        return store;
      }
    }
    return null;
  }, [user, currentStoreId]);

  useEffect(() => {
    dispatch(getAdminBranches(axiosInstance));
  }, [dispatch]);

  useEffect(() => {
    if (globalBranchIds.length === 0 && adminBranches.length > 0) {
      setGlobalBranchIds(adminBranches.map((b) => b._id));
    }
  }, [globalBranchIds, adminBranches]);

  useEffect(() => {
    setLocalActiveBranches(globalBranchIds);
  }, [globalBranchIds]);

  const handleSelectAllBranch = () => {
    if (localActiveBranches.length === adminBranches.length) {
      setLocalActiveBranches([]);
    } else {
      setLocalActiveBranches(adminBranches.map((branch) => branch._id));
    }
  };

  const handleSaveBranches = () => {
    if (!localActiveBranches?.length) {
      showSnackbar({
        message: 'Please select at least one location.',
        variant: 'error',
        enqueueSnackbar,
      });
      return;
    }
    setGlobalBranchIds(localActiveBranches);

    showSnackbar({
      message: 'Active locations saved successfully',
      variant: 'success',
      enqueueSnackbar,
    });
    handleClose();
  };

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setLocalActiveBranches(globalBranchIds);
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <Box
        sx={{
          borderRadius: 1,
          py: isCollapse ? 0 : 1,
          px: 2,
          cursor: 'pointer',
          color: 'text.primary',
          '&:hover': {
            bgcolor: '#F4F6F8',
          },
        }}
        onClick={handleOpen}
      >
        <Stack direction="row">
          <Stack gap="3px">
            <Typography variant="body2" color="text.secondary">
              Store name
            </Typography>
            <Stack direction="row" alignItems="center" gap={1}>
              {currentStore && currentStore.logo.url && (
                <Avatar
                  sx={{ width: '21px', height: '21px', borderRadius: '3px' }}
                  src={currentStore.logo.url}
                  alt={currentStore.logo.alt}
                  variant="square"
                />
              )}
              <Typography variant="subtitle1" fontWeight={600}>
                {currentStore && currentStore.business_name}
              </Typography>
            </Stack>
          </Stack>
          <Stack sx={{ my: 'auto', ml: 10 }}>
            <IconWrapperStyle>
              <Iconify icon="bx:bx-chevron-up" width={25} height={25} />
            </IconWrapperStyle>
            <IconWrapperStyle>
              <Iconify icon="bx:bx-chevron-down" width={25} height={25} />
            </IconWrapperStyle>
          </Stack>
        </Stack>
      </Box>
      <Menu
        open={isOpen}
        anchorEl={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              width: 500,
              borderRadius: '10px',
            },
          },
        }}
        transitionDuration={200}
        sx={{ mt: 3 }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        <Stack direction="row">
          <StoreSelector />
          <BranchSelector
            isLoading={isLoading}
            handleSelectAllBranch={handleSelectAllBranch}
            handleSaveBranches={handleSaveBranches}
            localActiveBranches={localActiveBranches}
            setLocalActiveBranches={setLocalActiveBranches}
            branches={adminBranches}
            handleClose={handleClose}
          />
        </Stack>
      </Menu>
    </>
  );
}

const IconWrapperStyle = styled('div')(({ theme }) => ({
  width: 24,
  height: 10,
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'lightgray',
}));
