import { RoleDataType } from 'src/@types/v2/team-management';

const ROLES_DATA: RoleDataType[] = [
  {
    id: 'owner',
    role: 'Owner',
    descriptions: [
      { status: true, description: 'Access to the complete dashboard and all features.' },
      { status: true, description: 'Access to POS application with full permissions.' },
      { status: true, description: 'Ability to manage team members and assign roles.' },
    ],
  },
  {
    id: 'manager',
    role: 'Manager',
    descriptions: [
      { status: true, description: 'Access to the complete dashboard and all features.' },
      { status: true, description: 'Access to POS application with full permissions.' },
      {
        status: true,
        description:
          'This enables all permissions except billing, Only the account owner can manage billing',
      },
    ],
  },
  {
    id: 'branch-manager',
    role: 'Branch Manager',
    descriptions: [
      {
        status: true,
        description: `Limited access to the dashboard for monitoring and managing the specific branch's.`,
      },
      {
        status: true,
        description:
          'Access to the POS application with permissions limited to their designated branch.',
      },
      {
        status: true,
        description:
          'Ability to manage team members working at their branch and assign roles for that specific location.',
      },
    ],
  },
  {
    id: 'chef-head-kitchen',
    role: 'Chef/Head Kitchen',
    descriptions: [
      { status: true, description: 'No access to the dashboard.' },
      { status: true, description: 'Access to Kitchen display.' },
    ],
  },
  {
    id: 'prep-cook',
    role: 'Prep Cook',
    descriptions: [
      { status: true, description: 'No access to the dashboard.' },
      { status: true, description: 'Access to Kitchen display.' },
    ],
  },
  {
    id: 'cashier',
    role: 'Cashier',
    descriptions: [
      { status: true, description: 'No access to the dashboard.' },
      {
        status: true,
        description:
          'Full access to the POS application for processing payments and managing sales transactions.',
      },
    ],
  },
  {
    id: 'waitstaff-server',
    role: 'Waitstaff/Server',
    descriptions: [
      { status: true, description: 'No access to the dashboard.' },
      {
        status: true,
        description:
          'Full access to the POS application for processing payments and managing sales transactions.',
      },
    ],
  },
  {
    id: 'bartender',
    role: 'Bartender',
    descriptions: [
      { status: true, description: 'No access to the dashboard.' },
      { status: true, description: 'Access to Kitchen display.' },
    ],
  },
];

export default ROLES_DATA;
