import {
  AccordionDetails,
  CircularProgress,
  Divider,
  Stack,
  Switch,
  Typography,
  Accordion,
  AccordionSummary,
  Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import React, { useState } from 'react';
import Iconify from '@components/iconify/Iconify';
import { BoxFlexCC } from 'src/styles';
import { DeliveryIcon, DineInIcon, PickUpIcon } from 'src/assets/icon/order-method';
import { StoreIcon } from 'src/assets/icon/StoreIcon';
import { BranchItemType } from './WaitingTime';

const waitingTimeitems: {
  icon: JSX.Element;
  name: string;
  value: 'pickup' | 'delivery' | 'dine_in';
}[] = [
  {
    icon: <PickUpIcon size={16} color="#414A53" />,
    name: 'Pick up',
    value: 'pickup',
  },
  {
    icon: <DeliveryIcon size={16} color="#414A53" />,
    name: 'Delivery',
    value: 'delivery',
  },
  {
    icon: <DineInIcon size={16} color="#414A53" />,
    name: 'Dine in',
    value: 'dine_in',
  },
];

function BranchItem({
  branch,
  handleChange,
  handleSwitchEnable,
  handleSubmitBranch,
  index,
  loading,
}: {
  branch: BranchItemType;
  handleChange: (branch: BranchItemType) => void;
  handleSwitchEnable: (branch: BranchItemType, type: 'pickup' | 'delivery' | 'dine_in') => void;
  handleSubmitBranch: (item: BranchItemType) => void;
  index: number;
  loading: boolean;
}) {
  const [expanded, setExpanded] = useState(0);

  const handleChangeExpanded = () => {
    setExpanded(expanded === index ? -1 : index);
  };

  const increase = (type: 'pickup' | 'delivery' | 'dine_in') => {
    const val = branch[type];
    val.waiting_time += 5;
    handleChange({ ...branch, [type]: val });
  };
  const decrease = (type: 'pickup' | 'delivery' | 'dine_in') => {
    const val = branch[type];
    if (val.waiting_time > 0) {
      val.waiting_time -= 5;
      handleChange({ ...branch, [type]: val });
    }
  };

  return (
    <Box>
      <Accordion
        sx={{ boxShadow: 'none !important', padding: '0.75rem 0' }}
        expanded={expanded === index}
        onChange={handleChangeExpanded}
      >
        <AccordionSummary
          sx={{ padding: 0 }}
          expandIcon={<Iconify icon="eva:arrow-ios-downward-fill" width={20} height={20} />}
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <StoreIcon />
            <Typography fontWeight={600} fontSize="18px">
              {branch._data.location_name}
            </Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0, paddingBottom: '1rem' }}>
          <Box
            sx={{
              p: '16px',
              border: '1px solid #E5E7E9',
              borderRadius: '5px',
            }}
          >
            {waitingTimeitems.map((item, idx) => {
              let accepting;
              if (item.value === 'delivery') {
                accepting = branch.delivery.accept_delivery;
              } else if (item.value === 'dine_in') {
                accepting = branch.dine_in.accept_dine_in;
              } else {
                accepting = branch.pickup.accept_pickup;
              }

              return (
                <Stack
                  key={idx}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    py: '16px',
                    borderBottom: '1px solid #E5E7E9',
                    '&:first-of-type': {
                      pt: 0,
                    },
                    '&:last-child': {
                      pb: 0,
                      borderBottom: 'none',
                    },
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    sx={{
                      width: '100%',
                    }}
                  >
                    <BoxFlexCC
                      sx={{
                        width: '32px',
                        height: '32px',
                        borderRadius: '50%',
                        bgcolor: '#F4F6F8',
                      }}
                    >
                      {item.icon}
                    </BoxFlexCC>
                    <Stack
                      sx={{
                        flex: 1,
                      }}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{
                          width: '100%',
                        }}
                      >
                        <Typography fontWeight={600} variant="body1">
                          {item.name}
                        </Typography>
                        <Switch
                          checked={accepting}
                          onChange={() => handleSwitchEnable(branch, item.value)}
                        />
                      </Stack>

                      {accepting && (
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          sx={{
                            width: '100%',
                            mt: 1,
                          }}
                        >
                          <Typography variant="body2" color="#494F54">
                            Estimate waiting time
                          </Typography>
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <BoxFlexCC
                              sx={{
                                width: '32px',
                                height: '32px',
                                borderRadius: '5px',
                                border: '1px solid #E5E7E9',
                                cursor: 'pointer',
                                '&:hover': {
                                  bgcolor:
                                    branch[item.value].waiting_time > 0 ? '#F4F6F8' : 'transparent',
                                },
                              }}
                              onClick={() => decrease(item.value)}
                            >
                              <Iconify
                                icon="akar-icons:minus"
                                width={20}
                                height={20}
                                color={branch[item.value].waiting_time === 0 ? '#E5E7E9' : ''}
                              />
                            </BoxFlexCC>
                            <Typography variant="body2">
                              {loading ? (
                                <CircularProgress size={14} />
                              ) : (
                                `${branch[item.value].waiting_time} min`
                              )}
                            </Typography>
                            <BoxFlexCC
                              sx={{
                                width: '32px',
                                height: '32px',
                                borderRadius: '5px',
                                border: '1px solid #E5E7E9',
                                cursor: 'pointer',
                                '&:hover': {
                                  bgcolor: '#F4F6F8',
                                },
                              }}
                              onClick={() => increase(item.value)}
                            >
                              <Iconify icon="akar-icons:plus" width={20} height={20} />
                            </BoxFlexCC>
                          </Stack>
                        </Stack>
                      )}
                    </Stack>
                  </Stack>
                </Stack>
              );
            })}
          </Box>
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            justifyContent="flex-end"
            sx={{
              py: '24px',
              position: 'sticky',
              bottom: 0,
              right: 0,
              borderTop: '1px solid #E5E7E9',
              bgcolor: 'white',
            }}
          >
            <LoadingButton
              variant="contained"
              color="primary"
              size="large"
              onClick={() => handleSubmitBranch(branch)}
              // loading={loading}
            >
              Save
            </LoadingButton>
          </Stack>
        </AccordionDetails>
      </Accordion>
      <Divider />
    </Box>
  );
}

export default BranchItem;
