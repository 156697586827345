import { AxiosInstance } from 'axios';
import { showSnackbar } from '@utils/snackbar';
import { SnackbarType } from 'src/@types/v2/snackbar';
import {
  setProductData,
  setCurrentProduct,
  hasError,
  reset,
  startLoading,
  setSubmitting,
} from '@redux/reducers/products';
import { AppDispatch, RootState } from '@redux/store';
import { getProductRequestData, convertToProductFormData } from '@utils/product';
import { ProductType } from 'src/@types/v2/products';
import { ProductFormProps } from 'src/validations/restaurant/product/product-form';

export function getProducts(branchIds: string[], axiosInstance: AxiosInstance) {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(startLoading());
      const { sortBy } = getState().products;

      const branchQuery = branchIds.length > 0 ? `branch=${branchIds.join(',')}` : '';
      const sortByQuery = sortBy ? `sort_by=${sortBy}` : '';

      let queries = '';
      if (branchQuery && sortByQuery) {
        queries = [branchQuery, sortByQuery].join('&');
      } else if (sortByQuery) {
        queries = sortByQuery;
      }

      const { data } = await axiosInstance.get(`/v2/products?${queries}`);
      dispatch(setProductData(data.data));
    } catch (error: any) {
      dispatch(hasError(error));
    }
  };
}

export function getPOSProducts(axiosInstance: AxiosInstance, globalBranchIds: string[]) {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(startLoading());
      const { sortBy } = getState().products;

      const branchQuery = globalBranchIds.length > 0 ? `branch=${globalBranchIds.join(',')}` : '';
      const sortByQuery = sortBy ? `sort_by=${sortBy}` : '';

      let queries = '';
      if (branchQuery && sortByQuery) {
        queries = [branchQuery, sortByQuery].join('&');
      } else if (sortByQuery) {
        queries = sortByQuery;
      }

      const { data } = await axiosInstance.get(`/v2/products?availability=pos&${queries}`);
      dispatch(setProductData(data.data));
    } catch (error: any) {
      dispatch(hasError(error));
    }
  };
}

export function getProductById(id: string, axiosInstance: AxiosInstance) {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(reset());
      dispatch(startLoading());
      const { data } = await axiosInstance.get(`/v2/products/${id}`);
      dispatch(setCurrentProduct(data.data));
      return data.data as ProductType;
    } catch (error) {
      dispatch(hasError(error));
      return null;
    }
  };
}

export function getProductFormData(id: string, axiosInstance: AxiosInstance) {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoading());
      const { data } = await axiosInstance.get(`/v2/products/${id}`);
      const currentProduct = data.data as ProductType;
      const formatted = await convertToProductFormData(currentProduct, dispatch, axiosInstance);
      dispatch(setCurrentProduct(formatted));
      return formatted;
    } catch (error) {
      dispatch(hasError(error));
      return null;
    }
  };
}

export function addNewProduct(
  values: ProductFormProps,
  enqueueSnackbar: SnackbarType,
  axiosInstance: AxiosInstance,
  redirect: () => void
) {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setSubmitting(true));
      const bodyData = await getProductRequestData(values, axiosInstance);
      const { data } = await axiosInstance.post('/v2/products', bodyData);
      const { data: initialData } = getState().products;
      const newData = [...initialData, data.data];

      dispatch(setProductData(newData));
      dispatch(setSubmitting(false));
      showSnackbar({
        message: 'Product added successfully',
        variant: 'success',
        enqueueSnackbar,
        redirect,
      });
    } catch (error: any) {
      dispatch(hasError(error));

      showSnackbar({
        message:
          'An error occurred while add product. If the error persists, please contact support.',
        variant: 'error',
        enqueueSnackbar,
      });
    }
  };
}

export function updateProduct(
  values: ProductFormProps,
  productId: string,
  enqueueSnackbar: SnackbarType,
  axiosInstance: AxiosInstance,
  redirect: () => void
) {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setSubmitting(true));

      const bodyData = await getProductRequestData(values, axiosInstance);
      await axiosInstance.put(`/v2/products/${productId}`, bodyData);
      const { data: initialData } = getState().products;
      const newData = initialData.map((product) => {
        if (product._id === productId) {
          return {
            ...product,
            ...bodyData,
          };
        }
        return product;
      });
      dispatch(setProductData(newData));
      dispatch(setSubmitting(false));

      showSnackbar({
        message: 'Product updated successfully',
        variant: 'success',
        enqueueSnackbar,
        redirect,
      });
    } catch (error: any) {
      dispatch(hasError(error));

      showSnackbar({
        message:
          'An error occurred while edit product. If the error persists, please contact support.',
        variant: 'error',
        enqueueSnackbar,
      });
    }
  };
}

export function updateProductCategory(id: string[], axiosInstance: AxiosInstance) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());

    try {
      const updatePromises = id.map((_id: string) =>
        axiosInstance.patch(`/v2/products/${_id}`, {
          category: null,
        })
      );
      await Promise.all(updatePromises);
      await dispatch(getProducts([], axiosInstance));
    } catch (error: any) {
      dispatch(hasError(error));
    }
  };
}

export function deleteProducts(
  ids: string[],
  enqueueSnackbar: SnackbarType,
  axiosInstance: AxiosInstance
) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());

    try {
      await Promise.all(ids.map((id) => axiosInstance.delete(`/v2/products/${id}`))).then(() => {
        dispatch(getProducts([], axiosInstance));
      });

      showSnackbar({
        message: 'Product deleted successfully',
        variant: 'success',
        enqueueSnackbar,
      });
    } catch (error: any) {
      console.log(error);
      showSnackbar({
        message: 'Error deleting product. If the error persists, please contact support.',
        variant: 'error',
        enqueueSnackbar,
      });
      dispatch(hasError(error));
    }
  };
}

export function updateProductsPosition(
  products: ProductType[],
  enqueueSnackbar: SnackbarType,
  axiosInstance: AxiosInstance,
  cb?: () => void
) {
  return async (dispatch: AppDispatch) => {
    dispatch(setSubmitting(true));

    try {
      await Promise.all(
        products.map((product) =>
          axiosInstance.put(`/v2/products/${product._id}`, {
            position: product.position,
          })
        )
      );

      dispatch(getProducts([], axiosInstance));

      showSnackbar({
        message: 'Product position updated successfully',
        variant: 'success',
        enqueueSnackbar,
      });
    } catch (error: any) {
      console.log(error);
      showSnackbar({
        message: 'Error updating product position. If the error persists, please contact support.',
        variant: 'error',
        enqueueSnackbar,
      });
      dispatch(hasError(error));
    } finally {
      dispatch(setSubmitting(false));
      if (cb) cb();
    }
  };
}
