interface Props {
  size?: number;
  color?: string;
}

export const StoreIcon = ({ size = 24, color = '#414A53' }: Props) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.9961 10V19H20.9961V10H18.9961ZM17.9961 20H5.99609V22H17.9961V20ZM4.99609 19V10H2.99609V19H4.99609ZM5.99609 20C5.44381 20 4.99609 19.5523 4.99609 19H2.99609C2.99609 20.6569 4.33924 22 5.99609 22V20ZM18.9961 19C18.9961 19.5523 18.5484 20 17.9961 20V22C19.6529 22 20.9961 20.6569 20.9961 19H18.9961Z"
      fill={color}
    />
    <path
      d="M8.82428 7.27744L7.82793 7.19149L7.82712 7.20215L8.82428 7.27744ZM15.1679 7.27744L16.1651 7.20215L16.1642 7.19149L15.1679 7.27744ZM3.45827 3.5V2.5C3.00375 2.5 2.6064 2.80653 2.49103 3.24616L3.45827 3.5ZM2.56723 6.89528L1.59998 6.64144C1.59711 6.65237 1.59443 6.66334 1.59193 6.67436L2.56723 6.89528ZM8.78996 7.73207L7.79351 7.64726L7.7928 7.65678L8.78996 7.73207ZM20.5339 3.5L21.5012 3.24616C21.3858 2.80653 20.9884 2.5 20.5339 2.5V3.5ZM21.425 6.89528L22.4003 6.67436C22.3978 6.66334 22.3951 6.65237 22.3922 6.64144L21.425 6.89528ZM15.2022 7.73207L16.1994 7.65677L16.1986 7.64726L15.2022 7.73207ZM9.15015 4.5H14.842V2.5H9.15015V4.5ZM13.8457 3.58595L14.1716 7.36339L16.1642 7.19149L15.8383 3.41405L13.8457 3.58595ZM9.82058 7.36339L10.1465 3.58595L8.15385 3.41405L7.82798 7.19149L9.82058 7.36339ZM11.9961 9.5C10.6735 9.5 9.73811 8.45645 9.82145 7.35273L7.82712 7.20215C7.6475 9.58117 9.62784 11.5 11.9961 11.5V9.5ZM14.1707 7.35273C14.2541 8.45645 13.3187 9.5 11.9961 9.5V11.5C14.3643 11.5 16.3447 9.58117 16.1651 7.20215L14.1707 7.35273ZM3.45827 4.5H9.15015V2.5H3.45827V4.5ZM8.15376 3.4152L7.79356 7.64726L9.78636 7.81687L10.1466 3.5848L8.15376 3.4152ZM3.53447 7.14912L4.42552 3.75384L2.49103 3.24616L1.59998 6.64144L3.53447 7.14912ZM5.64763 9.5C4.21176 9.5 3.2794 8.27782 3.54252 7.1162L1.59193 6.67436C1.01458 9.22325 3.07221 11.5 5.64763 11.5V9.5ZM7.7928 7.65678C7.71788 8.64903 6.82449 9.5 5.64763 9.5V11.5C7.77634 11.5 9.62653 9.93428 9.78712 7.80736L7.7928 7.65678ZM20.5339 2.5H14.842V4.5H20.5339V2.5ZM13.8456 3.5848L14.2058 7.81687L16.1986 7.64726L15.8384 3.4152L13.8456 3.5848ZM22.3922 6.64144L21.5012 3.24616L19.5667 3.75384L20.4577 7.14912L22.3922 6.64144ZM18.3446 11.5C20.92 11.5 22.9776 9.22325 22.4003 6.67436L20.4497 7.1162C20.7128 8.27782 19.7804 9.5 18.3446 9.5V11.5ZM14.2051 7.80736C14.3657 9.93428 16.2158 11.5 18.3446 11.5V9.5C17.1677 9.5 16.2743 8.64903 16.1994 7.65678L14.2051 7.80736Z"
      fill={color}
    />
  </svg>
);
