import { useState, useEffect, useMemo } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Typography, Drawer, Box, Button, Skeleton, IconButton, Stack } from '@mui/material';
// components
import { useSnackbar } from '@components/snackbar';
import { IconButtonAnimate } from '@components/animate';
import StopwatchIcon from 'src/assets/icon/StopwatchIcon';
import { BoxFlexBC } from 'src/styles';
import { CrossIcon } from 'src/assets/icon/CrossIcon';
import SearchTextField from '@components/search/SearchTextField';
import { useBranches } from '@redux/reducers/settings/branches';
import { useAxios } from 'src/auth/AxiosContext';
import { BranchSettingType, BranchType } from 'src/@types/v2/settings/branch';
import BranchItem from './BranchItem';
// ----------------------------------------------------------------------

export type BranchItemType = BranchSettingType & {
  _data: BranchType;
};

export default function WaitingTime() {
  const axiosInstance = useAxios();
  const { adminBranches } = useBranches();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState<HTMLElement | null>(null);
  const [filterName, setFilterName] = useState('');
  const [data, setData] = useState<BranchItemType[]>([]);
  const [loading, setLoading] = useState(false);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
    setLoading(true);
  };

  const handleClose = () => {
    setOpen(null);
  };

  useEffect(() => {
    if (adminBranches && open !== null) {
      const promises = adminBranches.map(async (branch) =>
        axiosInstance.get(`/v2/branches/${branch._id}/settings`)
      );
      Promise.all(promises).then((res) => {
        setLoading(false);
        setData(
          res.map((item, idx) => {
            if (item.data.data) {
              return {
                ...item.data.data,
                _data: adminBranches[idx],
                pickup: {
                  ...item.data.data.pickup,
                  accept_pickup: item.data.data.pickup.accept_pickup || false,
                  waiting_time: item.data.data.pickup.waiting_time || 0,
                },
                delivery: {
                  ...item.data.data.delivery,
                  accept_delivery: item.data.data.delivery.accept_delivery || false,
                  waiting_time: item.data.data.delivery.waiting_time || 0,
                },
                dine_in: {
                  ...item.data.data.dine_in,
                  accept_dine_in: item.data.data.dine_in.accept_dine_in || false,
                  waiting_time: item.data.data.dine_in.waiting_time || 0,
                },
              };
            }
            return {
              _data: adminBranches[idx],
              pickup: {
                accept_pickup: false,
                waiting_time: 0,
              },
              delivery: {
                accept_delivery: false,
                waiting_time: 0,
              },
              dine_in: {
                accept_dine_in: false,
                waiting_time: 0,
              },
            };
          })
        );
      });
    }
  }, [open]);

  const handleChangeBranch = (branch: BranchItemType) => {
    const index = data.findIndex((item) => item._data._id === branch._data._id);
    const newData = [...data];
    newData[index] = branch;
    setData(newData);
  };

  const handleSwitchEnable = (branch: BranchItemType, type: 'pickup' | 'delivery' | 'dine_in') => {
    const index = data.findIndex((item) => item._data._id === branch._data._id);
    const newData = [...data];

    if (type === 'pickup') {
      newData[index].pickup.accept_pickup = !newData[index].pickup.accept_pickup;
    } else if (type === 'delivery') {
      newData[index].delivery.accept_delivery = !newData[index].delivery.accept_delivery;
    } else {
      newData[index].dine_in.accept_dine_in = !newData[index].dine_in.accept_dine_in;
    }

    setData(newData);
  };

  const handleSubmitBranch = async (item: BranchItemType) => {
    setLoading(true);
    if (!item._id) {
      await axiosInstance.post(`/v2/branches/${item._data._id}/settings`, item);
    } else {
      await axiosInstance
        .put(`/v2/branches/${item._data._id}/settings`, item)
        .then(() => {
          enqueueSnackbar('Update branch settings successfully', { variant: 'success' });
          setLoading(false);
        })
        .catch(() => {
          enqueueSnackbar('Update branch settings failed', { variant: 'error' });
          setLoading(false);
        });
    }
  };

  const filteredBranches = useMemo(
    () =>
      data.filter((curData) =>
        curData._data.location_name.toLowerCase().includes(filterName.toLowerCase())
      ),
    [data, filterName]
  );

  return (
    <>
      <IconButtonAnimate
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
          ...(open && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
        }}
      >
        <StopwatchIcon />
      </IconButtonAnimate>

      <Drawer
        anchor="right"
        open={Boolean(open)}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: '450px',
          },
        }}
      >
        <Stack
          sx={{
            px: '24px',
          }}
        >
          <Box
            sx={{
              position: 'sticky',
              top: 0,
              right: 0,
              zIndex: 9,
              py: '24px',
              bgcolor: 'white',
              borderBottom: '1px solid #E5E7E9',
            }}
          >
            <BoxFlexBC mb={1}>
              <Typography variant="h5">Waiting time</Typography>
              <IconButton onClick={handleClose}>
                <CrossIcon />
              </IconButton>
            </BoxFlexBC>

            <SearchTextField
              onFilterName={(val) => {
                setFilterName(val);
              }}
              filterName={filterName}
              placeholder="Search location"
              sx={{
                fontSize: '14px',
                '&::placeholder': {
                  fontSize: '14px',
                },
              }}
            />
          </Box>

          <Stack>
            {loading &&
              new Array(6).fill(0).map((_, index) => (
                <Skeleton
                  key={index}
                  variant="rounded"
                  height={60}
                  sx={{
                    mt: '16px',
                  }}
                />
              ))}
            {!loading &&
              filteredBranches.map((branch, idx) => (
                <BranchItem
                  handleChange={handleChangeBranch}
                  key={idx}
                  branch={branch}
                  handleSwitchEnable={handleSwitchEnable}
                  handleSubmitBranch={handleSubmitBranch}
                  index={idx}
                  loading={loading}
                />
              ))}
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            justifyContent="flex-end"
            sx={{
              py: '24px',
              position: 'sticky',
              bottom: 0,
              right: 0,
              borderTop: '1px solid #E5E7E9',
              bgcolor: 'white',
            }}
          >
            <Button variant="outlined" color="primary" size="large" onClick={handleClose}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Drawer>
    </>
  );
}
