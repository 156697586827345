import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material';
import React from 'react';
import { BoxFlexSS } from 'src/styles';
import { DropdownFixDataType } from './types';

type DropdownFixType = {
  data: DropdownFixDataType[];
  size: 'small' | 'medium';
  onClickItem: (val: string) => void;
  label: string;
  width?: number;
  withIcon?: boolean;
};

export default function DropdownFix({
  data,
  size,
  label,
  width,
  onClickItem,
  withIcon = false,
}: DropdownFixType) {
  return (
    <FormControl size={size}>
      <Select sx={{ width }} fullWidth placeholder={label} value={label}>
        {data.map((item, i) => (
          <MenuItem
            dense
            key={i}
            onClick={() => onClickItem(item.value)}
            value={item.value}
            style={{ display: item.hidden ? 'none' : '' }}
          >
            <BoxFlexSS>
              {withIcon && <Box mr={1}>{item.icon}</Box>}
              <Typography variant="body2" color={item.variant === 'error' ? 'error' : ''}>
                {item.value}
              </Typography>
            </BoxFlexSS>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
