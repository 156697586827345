function sanitizeObject<T extends object>(obj: T) {
  return Object.keys(obj).reduce((acc, key) => {
    if (obj[key as keyof T] !== undefined) {
      acc[key as keyof T] = obj[key as keyof T];
    }
    return acc;
  }, {} as T);
}

export default sanitizeObject;
