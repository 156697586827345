import { Typography } from '@mui/material';
import React from 'react';
import Avatar from '@components/images/Avatar';
import { getInitials } from '@utils/formatter-string';
import { getSequenceColor } from '@utils/styles';

interface Props {
  text: string;
  bgColor?: string;
  fontSize?: number;
  size?: number;
}

const AvatarInitial = ({
  text,
  size = 40,
  fontSize = 16,
  bgColor = getSequenceColor(getInitials(text)),
}: Props) => (
  <Avatar
    sx={{
      color: 'white',
      backgroundColor: bgColor,
      width: size,
      height: size,
    }}
  >
    <Typography variant="subtitle2" fontSize={fontSize}>
      {getInitials(text)}
    </Typography>
  </Avatar>
);

export default AvatarInitial;
