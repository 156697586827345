import React from 'react';
import { useRouter } from 'next/router';
// muis
import { Button, Card, Dialog, Typography, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import { useSnackbar } from '@components/snackbar';
import { TrashIcon } from 'src/assets/icon/TrashIcon';
import { useDispatch } from '@redux/store';
import { deleteGiftcards } from '@redux/actions/giftcard';
import { useGiftcard } from '@redux/reducers/giftcard';
import { useAxios } from 'src/auth/AxiosContext';
import { PATH_DASHBOARD } from '@routes/paths';

export interface Props {
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const RootStyle = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(4, 4),
}));

export default function DeleteGiftcardDialog({ modalOpen, setModalOpen, ...props }: Props) {
  const axiosInstance = useAxios();
  const dispatch = useDispatch();
  const { push } = useRouter();
  const { giftcard } = useGiftcard();
  const { enqueueSnackbar } = useSnackbar();

  const handleDeleteClick = () => {
    // TODO(aldoarya): Handle subtext in notistack. Currently it has no detailed description like in Figma
    if (giftcard?._id) {
      dispatch(
        deleteGiftcards([giftcard._id], enqueueSnackbar, axiosInstance, () =>
          push(PATH_DASHBOARD.restaurant.giftcard)
        )
      );
    }
  };

  const onClose = () => {
    setModalOpen(false);
  };

  return (
    <Dialog onClose={onClose} maxWidth="xs" fullWidth open={modalOpen} {...props}>
      <RootStyle>
        <Box>
          <Box sx={{ mb: 1 }}>
            <TrashIcon />
          </Box>
          <Typography variant="h5" gutterBottom>
            Are you sure you want to delete the gift card?
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            The gift card will be deleted and will can't be used
          </Typography>

          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            marginTop="32px"
          >
            <Grid item xs={6}>
              <Button fullWidth variant="outlined" onClick={onClose}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth variant="contained" color="error" onClick={handleDeleteClick}>
                Delete gift card
              </Button>
            </Grid>
          </Grid>
        </Box>
      </RootStyle>
    </Dialog>
  );
}
