import { useRouter } from 'next/router';
import { Icon } from '@iconify/react';
import { Box, Stack, Toolbar, Button, IconButton, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { useSnackbar } from '@components/snackbar';
import HeaderBreadcrumbs from '@components/header/components/HeaderBreadcrumbs';
import { IconButtonAnimate } from '@components/animate';
import Iconify from '@components/iconify/Iconify';
import Logo from '@components/images/Logo';
import { PATH_DASHBOARD } from '@routes/paths';
import { HEADER } from 'src/config-global';
import { reset, setPriority, useCart } from '@redux/reducers/orders/cart';
import useResponsive from '@hooks/useResponsive';
import useOffSetTop from '@hooks/useOffSetTop';
import { BoxFlexSS } from 'src/styles';
import { useDispatch } from '@redux/store';
import { useAxios } from 'src/auth/AxiosContext';
import { createOrder } from '@redux/actions/orders/cart';
import { isPhoneValid } from '@utils/formatter-string';
import HeaderRootStyle from '../styles';

type Props = {
  onOpenSidebar: VoidFunction;
  isCollapse?: boolean;
  verticalLayout?: boolean;
};

export default function OrderCreateHeader({
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
}: Props) {
  const axiosInstance = useAxios();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const router = useRouter();
  const { form } = useCart();
  const {
    prioritized,
    delivery_details,
    pickup_details,
    customer_note,
    billing,
    items,
    order_type,
  } = form;
  const dispatch = useDispatch();

  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
  const isDesktop = useResponsive('up', 'lg');

  const handleBack = () => {
    dispatch(reset());
    router.back();
  };

  const { enqueueSnackbar } = useSnackbar();

  const handleSaveOrder = async () => {
    setIsSubmitting(() => true);
    await dispatch(
      createOrder({
        payload: form,
        enqueueSnackbar,
        axiosInstance,
      })
    );
    setIsSubmitting(() => false);
  };

  const isAllRequiredFieldFilled = () => {
    if (billing && items.length > 0 && order_type) {
      const isCompanyOrder = billing?.customer_type === 'company';
      if (
        isCompanyOrder &&
        (!isPhoneValid(billing?.metadata?.phone) ||
          !billing?.metadata?.who_ordering ||
          !customer_note)
      ) {
        return false;
      }

      if (order_type === 'delivery' && delivery_details) {
        return true;
      }
      if (order_type === 'pickup' && pickup_details) {
        return true;
      }
    }
    return false;
  };

  return (
    <HeaderRootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 5 },
        }}
      >
        {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

        {!isDesktop && (
          <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}
        <BoxFlexSS>
          <IconButton
            onClick={handleBack}
            size="small"
            sx={{ border: 1, borderColor: 'lightgray', mr: 2 }}
          >
            <Icon icon="eva:chevron-left-fill" color="gray" width={20} height={20} />
          </IconButton>
          <HeaderBreadcrumbs
            mb={0}
            heading="Create new order"
            headingVariant="h5"
            gutterBottom={false}
            links={[
              {
                name: 'Orders',
                href: PATH_DASHBOARD.orders.root,
              },
              { name: 'New order' },
            ]}
          />
        </BoxFlexSS>

        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          {!prioritized && (
            <Button
              size="large"
              onClick={() => {
                dispatch(setPriority(true));
              }}
              variant="outlined"
              sx={{
                color: '#EA9444',
                border: '1px solid #EA9444',
                '&:hover': {
                  backgroundColor: '#f7f1eb',
                  border: '1px solid #EA9444',
                },
              }}
            >
              <Iconify
                icon="ic:round-star-outline"
                sx={{
                  width: 24,
                  height: 24,
                }}
              />
              <Typography variant="body1" sx={{ ml: 1 }}>
                Set order as prioritize
              </Typography>
            </Button>
          )}
          {prioritized && (
            <Button
              onClick={() => {
                dispatch(setPriority(false));
              }}
              variant="outlined"
              sx={{
                color: 'white',
                border: '1px solid #EA9444',
                backgroundColor: '#EA9444',
                '&:hover': {
                  backgroundColor: '#EA9444',
                  border: '1px solid #EA9444',
                },
              }}
            >
              <Iconify
                icon="ic:round-star-outline"
                sx={{
                  width: 24,
                  height: 24,
                }}
              />
              <Typography variant="body1" sx={{ ml: 1 }}>
                Prioritized order
              </Typography>
            </Button>
          )}
          <Button size="large" variant="outlined" onClick={handleBack}>
            Cancel
          </Button>
          <LoadingButton
            size="large"
            variant="contained"
            disabled={!isAllRequiredFieldFilled()}
            onClick={handleSaveOrder}
            loading={isSubmitting}
          >
            Save order
          </LoadingButton>
        </Stack>
      </Toolbar>
    </HeaderRootStyle>
  );
}
