import { AdminRolesType } from '../types/roles';

const ADMIN_ROLES: AdminRolesType[] = [
  'Owner',
  'Manager',
  'Branch Manager',
  'Chef/Head Kitchen',
  'Prep Cook',
  'Cashier',
  'Waitstaff/Server',
  'Bartender',
];

const FULL_ACCESS_ROLES = ['Owner', 'Manager', 'Developer'];

export { ADMIN_ROLES, FULL_ACCESS_ROLES };
