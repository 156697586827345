import { BoxProps, Box, Typography, styled } from '@mui/material';
import React from 'react';
import { Dot } from 'src/styles';

interface Props {
  bgColor: string;
  textColor: string;
  label: string;
  fullWidth?: boolean;
  textAlign?: 'left' | 'center' | 'right';
  dot?: boolean;
  variant?: 'body1' | 'body2' | 'caption';
  sx?: BoxProps['sx'];
}

function SingleChip({
  bgColor,
  textAlign = 'center',
  textColor,
  label,
  fullWidth = false,
  dot = false,
  variant = 'body2',
  sx = {},
}: Props) {
  return (
    <Box
      sx={{
        ...(fullWidth
          ? { width: '100%' }
          : { display: 'flex', justifyContent: 'justify-start', alignItems: 'center' }),
      }}
    >
      <BoxStyle alignItems={textAlign} bgcolor={bgColor} textcolor={textColor} sx={sx}>
        {dot && <Dot sx={{ background: textColor, mr: '6px', width: '4px', height: '4px' }} />}
        <Typography variant={variant} fontWeight={500}>
          {label}
        </Typography>
      </BoxStyle>
    </Box>
  );
}

export default SingleChip;

type BoxStyleProps = {
  bgcolor: string;
  textcolor: string;
  align?: string;
};

const BoxStyle = styled(Box)<BoxStyleProps>(({ bgcolor, textcolor, align }) => ({
  background: bgcolor,
  color: textcolor,
  borderRadius: '6px',
  padding: '4px 8px',
  display: 'flex',
  alignItems: align,
  justifyContent: align,
}));
