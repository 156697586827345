export type ParamsType = {
  sort_by: string | null;
  ticket_status: string | null;
  end: string | null;
  start: string | null;
  branch: string | null;
};

const generateOverviewQueryParams = (params: ParamsType) => {
  const query = new URLSearchParams();
  Object.keys(params).forEach((key) => {
    if (
      params[key as keyof ParamsType] !== null &&
      params[key as keyof ParamsType] !== undefined &&
      params[key as keyof ParamsType] !== 'all' &&
      params[key as keyof ParamsType] !== ''
    ) {
      const value = params[key as keyof ParamsType];
      if (value) {
        query.append(key, value.toString());
      }
    }
  });

  return query.toString();
};

export default generateOverviewQueryParams;
