import { Box, Toolbar, IconButton } from '@mui/material';
import { Icon } from '@iconify/react';
import { useRouter } from 'next/router';
import HeaderBreadcrumbs from '@components/header/components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '@routes/paths';
import { HEADER } from 'src/config-global';
import useOffSetTop from '@hooks/useOffSetTop';
import useResponsive from '@hooks/useResponsive';
import { IconButtonAnimate } from '@components/animate';
import Iconify from '@components/iconify/Iconify';
import Logo from '@components/images/Logo';
import { BoxFlexSS } from 'src/styles';
import { useCompanyDetails } from '@redux/reducers/customers/company-details';
import HeaderRootStyle from '../styles';

type Props = {
  onOpenSidebar: VoidFunction;
  isCollapse?: boolean;
  verticalLayout?: boolean;
};

export default function CompanyDetailHeader({
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
}: Props) {
  const { company } = useCompanyDetails();
  const router = useRouter();

  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
  const isDesktop = useResponsive('up', 'lg');

  const handleBack = () => {
    router.back();
  };

  return (
    <HeaderRootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 5 },
        }}
      >
        {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

        {!isDesktop && (
          <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}
        <BoxFlexSS>
          <IconButton
            onClick={handleBack}
            size="small"
            sx={{ border: 1, borderColor: 'lightgray', mr: 2, mb: 'auto', mt: 1 }}
          >
            <Icon icon="eva:chevron-left-fill" color="gray" width={20} height={20} />
          </IconButton>
          <HeaderBreadcrumbs
            mb={0}
            heading={company.data.name}
            headingVariant="h5"
            gutterBottom={false}
            links={[
              {
                name: 'Company',
                href: PATH_DASHBOARD.customers.root,
              },
              { name: 'Company details' },
            ]}
          />
        </BoxFlexSS>

        <Box sx={{ flexGrow: 1 }} />
      </Toolbar>
    </HeaderRootStyle>
  );
}
