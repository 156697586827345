/* eslint-disable consistent-return */
import { AxiosInstance } from 'axios';
import {
  AvailableVariationWithPriceType,
  AvailableVariationType,
  ProductType,
} from 'src/@types/v2/products';
import { getVariationsByCategory, getVariationsByProduct } from '@redux/actions/variant';
import { getProductById } from '@redux/actions/products';
import { getCategoryById } from '@redux/actions/categories';
import {
  ProductFormProps,
  MenusType,
  MenuItemType,
  ProductFormVariationWithPriceType,
  ProductFormVariationType,
} from 'src/validations/restaurant/product/product-form';
import { VariationType } from 'src/@types/v2/variations';
import { AppDispatch } from '@redux/store';

const convertToProductFormData = async (
  currentProduct: ProductType,
  dispatch: AppDispatch,
  axiosInstance: AxiosInstance
) => {
  try {
    const menus: MenusType[] = [];

    if (!currentProduct) {
      return null;
    }

    const menuPromises =
      currentProduct.upsell_product && currentProduct.upsell_product?.menus
        ? currentProduct.upsell_product.menus.map(async (menu) => {
            const menuItems = (await Promise.all(
              menu.menu_items.map(async (item) => {
                if (item.product && menu.menu_type === 'product') {
                  const product = await dispatch(getProductById(item.product._id, axiosInstance));
                  if (product) {
                    const variations = await dispatch(
                      getVariationsByProduct(product._id, axiosInstance)
                    );
                    const selectedVariations: AvailableVariationWithPriceType[] =
                      item.available_variations;
                    const allVariations: VariationType[] = variations || [];
                    const modifiedVariations = modifyVariationsWithPrice(
                      selectedVariations,
                      allVariations
                    );

                    return {
                      product,
                      category: null,
                      image_url:
                        item.product.images.find((image) => image.primary)?.url ||
                        item.product.images[0].url,
                      item_name: item.product.name,
                      item_type: menu.menu_type,
                      item_id: item.product._id,
                      quantity: item.quantity,
                      selected_variations: modifiedVariations,
                      all_variations: allVariations.map((variation) => ({
                        ...variation,
                        additional_price: {
                          type: 'fixed',
                          value: 0,
                        },
                        use_product_price: false,
                      })),
                      all_products: [],
                      selected_products: [],
                      excluded_products: [],
                      use_product_price: item.use_product_price,
                      additional_price: item.additional_price,
                    };
                  }
                } else if (item.category && menu.menu_type === 'category') {
                  const category = await dispatch(
                    getCategoryById(item.category._id, axiosInstance)
                  );
                  const variations =
                    category &&
                    (await dispatch(getVariationsByCategory(category._id, axiosInstance)));
                  const selectedVariations: AvailableVariationWithPriceType[] =
                    item.available_variations;
                  const allVariations: VariationType[] = variations || [];
                  const modifiedVariations = modifyVariationsWithPrice(
                    selectedVariations,
                    allVariations
                  );
                  const allProducts = category?.products;

                  const excludedProducts = item.excluded_products.map((product) => {
                    const curProduct = allProducts?.find(
                      (curItem) => curItem._id === product.product
                    );
                    const excludedVariations = modifyVariations(
                      product.excluded_variations,
                      allVariations
                    );

                    const availableVariations = modifyVariations(selectedVariations, allVariations);

                    return {
                      product: curProduct,
                      excluded_variations: excludedVariations,
                      available_variations: availableVariations,
                    };
                  });

                  return {
                    category,
                    product: null,
                    image_url: item.category.image.url || '',
                    item_name: item.category.name || '',
                    item_type: menu.menu_type,
                    item_id: item.category._id || '',
                    quantity: item.quantity,
                    all_variations: allVariations.map((variation) => ({
                      ...variation,
                      additional_price: {
                        type: 'fixed',
                        value: 0,
                      },
                      use_product_price: false,
                    })),
                    selected_variations: modifiedVariations,
                    all_products: category?.products,
                    selected_products: [],
                    excluded_products: excludedProducts,
                    additional_price: item.additional_price,
                    use_product_price: item.use_product_price,
                  };
                }
              })
            )) as MenuItemType[];

            menus.push({
              menu_name: menu.menu_name,
              menu_type: menu.menu_type,
              menu_items: menuItems,
            });
          })
        : [];

    await Promise.all(menuPromises);

    const oldArray = currentProduct.upsell_product?.menus || [];
    const orderedMenus = menus.sort(
      (a: any, b: any) =>
        oldArray.findIndex((x) => x.menu_name === a.menu_name) -
        oldArray.findIndex((x) => x.menu_name === b.menu_name)
    );

    const data: ProductFormProps = {
      name: currentProduct.name,
      branches: currentProduct.branches?.map((item) => item._id) || [],
      images: currentProduct.images,
      categories:
        currentProduct.categories
          ?.filter((cat) => cat.category)
          .map((item) => ({
            label: item.name,
            value: item.category._id || '',
            primary: item.primary,
          })) || [],
      tags:
        currentProduct.tags?.map((item) => ({
          label: item.name,
          value: item._id || '',
        })) || [],
      upsell_product: {
        active: currentProduct?.upsell_product?.active || false,
        menus: orderedMenus,
      },
      description: currentProduct.description,
      metadata: currentProduct.metadata || '',
      web_availability: currentProduct.product_availability.web_availability,
      pos_availability: currentProduct.product_availability.pos_availability,
      shipping: currentProduct.shipping,
      sku: currentProduct.sku,
      split_item: currentProduct.split_item,
      base_price: currentProduct.base_price.toString(),
      extras: currentProduct.extras as any,
      variables:
        currentProduct?.variables
          ?.filter((v) => v.variable)
          .map((item) => ({
            ...item,
            ...item.variable,
            variable: item.variable._id,
            attributes: item.attributes.map((attr: any) => ({
              ...attr,
              id: attr.attribute._id,
              name: attr.attribute.name,
              attribute: attr.attribute._id,
            })),
          })) || [],
      variations:
        currentProduct?.variations
          ?.map((variation) => ({
            ...variation,
            variants: variation.variants.map((variant: any) => ({
              ...variant,
              attribute: variant.attribute._id,
              variable: variant.variable._id,
            })),
          }))
          .sort((a, b) => a._id.localeCompare(b._id)) || [],
    };

    return data;
  } catch (error) {
    console.log(error);
  }
};

const modifyVariationsWithPrice = (
  selectedVariations: AvailableVariationWithPriceType[],
  allVariations: VariationType[]
): ProductFormVariationWithPriceType[] => {
  const selected: ProductFormVariationWithPriceType[] = [];

  allVariations.forEach((variation) => {
    const idAttr = variation.variants.map((item) => item.attribute._id);
    const isFound = selectedVariations.find(
      (curSelected) =>
        JSON.stringify(curSelected.variants.map((item) => item.attribute)) ===
        JSON.stringify(idAttr)
    );

    if (isFound) {
      selected.push({
        ...variation,
        additional_price: isFound.additional_price,
        use_product_price: isFound.use_product_price,
      });
    }
  });

  return selected;
};

const modifyVariations = (
  selectedVariations: AvailableVariationType[],
  allVariations: VariationType[]
): ProductFormVariationType[] => {
  const selected: ProductFormVariationType[] = [];
  allVariations.forEach((variation) => {
    const idAttr = variation.variants.map((item) => item.attribute._id);
    const isFound = selectedVariations.find(
      (curSelected) =>
        JSON.stringify(curSelected.variants.map((item) => item.attribute)) ===
        JSON.stringify(idAttr)
    );

    if (isFound) {
      selected.push(variation);
    }
  });

  return selected;
};

export default convertToProductFormData;
