import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
// @mui
import { Box, Stack, Toolbar, Button, IconButton, Skeleton } from '@mui/material';
// components
import { Icon } from '@iconify/react';
import Logo from '@components/images/Logo';
import HeaderBreadcrumbs from '@components/header/components/HeaderBreadcrumbs';
import ResendGiftcardDialog from '@sections/dashboard/giftcard/giftcard-detail/dialog/ResendGiftcardDialog';
import DropdownFix from '@components/dropdown/DropdownFix';
import DisableGiftcardDialog from '@sections/dashboard/giftcard/giftcard-detail/dialog/DisableGiftcardDialog';
import Iconify from '@components/iconify/Iconify';
import { IconButtonAnimate } from '@components/animate';
import useOffSetTop from '@hooks/useOffSetTop';
import useResponsive from '@hooks/useResponsive';
// styles
import { BoxFlexSS } from 'src/styles';
// config
import { PATH_DASHBOARD } from '@routes/paths';
import { HEADER } from 'src/config-global';
import { ACTION_DATA } from '@sections/dashboard/giftcard/giftcard-list/table/issueGiftCardTable/issueGiftCardData';
import DeleteGiftcardDialog from '@sections/dashboard/giftcard/giftcard-detail/dialog/DeleteGiftcardDialog';
import { useGiftcard } from '@redux/reducers/giftcard';
import EnableGiftcardDialog from '@sections/dashboard/giftcard/giftcard-detail/dialog/EnableGiftcardDialog';
import HeaderRootStyle from '../styles';

type Props = {
  onOpenSidebar: VoidFunction;
  isCollapse?: boolean;
  verticalLayout?: boolean;
};

export default function GiftcardIssueDetailHeader({
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
}: Props) {
  const { giftcard, isLoading } = useGiftcard();
  const [code, setCode] = useState<string>('');
  const [shownCode, setShownCode] = useState('');
  const [showCode, setShowCode] = useState(false);
  const [isResendDialogOpen, setIsResendDialogOpen] = useState(false);
  const [isDisableDialogOpen, setIsDisableDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isEnableDialogOpen, setIsEnableDialogOpen] = useState(false);

  useEffect(() => {
    setCode(() => (giftcard?.individual_giftcard?.code ? giftcard.individual_giftcard.code : ''));
  }, [giftcard]);

  const router = useRouter();

  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
  const isDesktop = useResponsive('up', 'lg');

  const onClickItem = (val: string) => {
    switch (val) {
      case 'Disable gift card':
        setIsDisableDialogOpen(() => true);
        break;
      case 'Enable gift card':
        setIsEnableDialogOpen(() => true);
        break;
      case 'Delete gift card':
        setIsDeleteDialogOpen(() => true);
        break;
      default:
        break;
    }
  };

  const toggleDialog = () => {
    setIsResendDialogOpen(!isResendDialogOpen);
  };

  useEffect(() => {
    if (showCode) {
      const parsedCode = code.match(/.{1,4}/g)?.join(' ');
      setShownCode(() => String(parsedCode));
    } else {
      const hiddenCode = code
        .substring(0, code.length - 4)
        .replace(/./g, '•')
        .match(/.{1,4}/g)
        ?.join(' ');
      const combinedCode = hiddenCode?.concat(' ', code.substring(code.length - 4, code.length));
      setShownCode(() => String(combinedCode));
    }
  }, [showCode, code]);

  const handleBack = () => {
    router.back();
  };

  return (
    <HeaderRootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 5 },
        }}
      >
        {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

        {!isDesktop && (
          <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}
        <BoxFlexSS>
          <IconButton
            onClick={handleBack}
            size="small"
            sx={{ border: 1, borderColor: 'lightgray', mr: 2, mb: 'auto', mt: 1 }}
          >
            <Icon icon="eva:chevron-left-fill" color="gray" width={20} height={20} />
          </IconButton>
          {isLoading ? (
            <Skeleton variant="rounded" sx={{ borderRadius: '8px' }} width={250} height={50} />
          ) : (
            <HeaderBreadcrumbs
              mb={0}
              heading={shownCode}
              headingRightElement={
                <Box>
                  <IconButton onClick={() => setShowCode(!showCode)} edge="end">
                    <Iconify icon={showCode ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </Box>
              }
              headingVariant="h4"
              gutterBottom={false}
              links={[
                {
                  name: 'Gift card',
                  href: PATH_DASHBOARD.restaurant.giftcard,
                },
                { name: 'Details' },
              ]}
            />
          )}
        </BoxFlexSS>

        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <DropdownFix
            size="medium"
            withIcon
            data={
              giftcard?.individual_giftcard?.status === 'disabled'
                ? ACTION_DATA.filter((item) => item.status !== 'disabled')
                : ACTION_DATA.filter((item) => item.status !== 'enabled')
            }
            onClickItem={onClickItem}
            label="Action"
            width={100}
          />
          <Button onClick={toggleDialog} variant="outlined">
            Resend
          </Button>
        </Stack>
      </Toolbar>
      <ResendGiftcardDialog modalOpen={isResendDialogOpen} setModalOpen={setIsResendDialogOpen} />
      <DisableGiftcardDialog
        modalOpen={isDisableDialogOpen}
        setModalOpen={setIsDisableDialogOpen}
      />
      <EnableGiftcardDialog modalOpen={isEnableDialogOpen} setModalOpen={setIsEnableDialogOpen} />
      <DeleteGiftcardDialog modalOpen={isDeleteDialogOpen} setModalOpen={setIsDeleteDialogOpen} />
    </HeaderRootStyle>
  );
}
