// @mui
import { Box, Stack, Toolbar, Button, IconButton } from '@mui/material';
// components
import { Icon } from '@iconify/react';
import { useRouter } from 'next/router';
import { PATH_DASHBOARD } from '@routes/paths';
import HeaderBreadcrumbs from '@components/header/components/HeaderBreadcrumbs';
import { HEADER } from 'src/config-global';
import useOffSetTop from '@hooks/useOffSetTop';
import useResponsive from '@hooks/useResponsive';
import { useOrderDetails } from '@redux/reducers/orders/details';
import { IconButtonAnimate } from '@components/animate';
import Iconify from '@components/iconify/Iconify';
import Logo from '@components/images/Logo';
import { BoxFlexSS } from 'src/styles';
import { OrderStatusChip } from '@styles/orders';
import HeaderRootStyle from '../styles';

type Props = {
  onOpenSidebar: VoidFunction;
  isCollapse?: boolean;
  verticalLayout?: boolean;
};

export default function OrderEditHeader({
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
}: Props) {
  const router = useRouter();

  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
  const isDesktop = useResponsive('up', 'lg');

  const { selectedOrderDetails } = useOrderDetails();

  const cancelEdit = () => {
    router.back();
  };

  const handleBack = () => {
    router.back();
  };

  return (
    <HeaderRootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 5 },
        }}
      >
        {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

        {!isDesktop && (
          <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}
        <BoxFlexSS>
          <IconButton
            onClick={handleBack}
            size="small"
            sx={{ border: 1, borderColor: 'lightgray', mr: 2, mb: 'auto', mt: 1 }}
          >
            <Icon icon="eva:chevron-left-fill" color="gray" width={20} height={20} />
          </IconButton>
          {selectedOrderDetails && (
            <HeaderBreadcrumbs
              mb={0}
              heading={`Edit order #${selectedOrderDetails?.order_number || ''}`}
              headingRightElement={
                selectedOrderDetails && (
                  <Box ml={1}>
                    <OrderStatusChip orderStatus={selectedOrderDetails.order_status} />
                  </Box>
                )
              }
              headingVariant="h5"
              gutterBottom={false}
              links={[
                {
                  name: 'Orders',
                  href: PATH_DASHBOARD.orders.root,
                },
                {
                  name: 'Order details',
                  href: PATH_DASHBOARD.orders.orderDetails(selectedOrderDetails._id),
                },
                { name: 'Edit' },
              ]}
            />
          )}
        </BoxFlexSS>

        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <Button size="large" onClick={cancelEdit} variant="outlined">
            Cancel
          </Button>
          <Button size="large" variant="contained">
            Save changes
          </Button>
        </Stack>
      </Toolbar>
    </HeaderRootStyle>
  );
}
