import { AxiosInstance } from 'axios';
import { uploadBlobURLToLibrary } from '@utils/media-library';
import { GiftcardType } from 'src/@types/v2/giftcard';
import { NewGiftCardFormProps } from 'src/validations/restaurant/gift-card/new-giftcard';

const getGiftcardRequestData = async (
  values: NewGiftCardFormProps | GiftcardType,
  axiosInstance: AxiosInstance
) => {
  const uploadImages = async (
    images: {
      url: string;
      filename: string;
      alt: string;
      primary: boolean;
    }[]
  ): Promise<
    {
      url: string;
      filename: string;
      alt: string;
      primary: boolean;
    }[]
  > => {
    const imageUploadPromises = images.map(async (image) => {
      const imageUrl = await uploadBlobURLToLibrary({
        blobURL: image.url,
        axiosInstance,
      });
      return {
        filename: image.filename,
        url: imageUrl,
        alt: image.alt,
        primary: image.primary,
      };
    });
    const imageUploadResults = await Promise.all(imageUploadPromises);
    return imageUploadResults;
  };

  const finalImages =
    values.giftcard_type === 'product' ? await uploadImages(values.product_giftcard.images) : [];

  const productGiftcardData = { ...values.product_giftcard, images: finalImages };

  const bodyData = {
    giftcard_name: values.giftcard_name,
    giftcard_type: values.giftcard_type,
    branch: values.branch,
    product_giftcard: productGiftcardData,
    individual_giftcard: values.individual_giftcard,
  };
  console.log('FINAL BODY DATA', bodyData);
  return bodyData;
};

export default getGiftcardRequestData;
