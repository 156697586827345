interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const CheckWhiteIcon = ({ width = 24, height = 24, color = 'green' }: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="12" fill="white" />
    <path
      d="M17.3337 8L10.0003 15.3333L6.66699 12"
      stroke="#00AB55"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CheckWhiteIcon;
