import {
  DashboardCustomersPermissionsType,
  DashboardOrderDisplayPermissionsType,
  DashboardOrderPermissionsType,
  DashboardPaymentPermissionsType,
  DashboardPermissionsType,
  DashboardProductPermissionsType,
  DashboardReportsPermissionsType,
  DashboardSettingsPermissionsType,
  DashboardTeamManagementPermissionsType,
  FullPermissionsType,
  PosCheckoutPermissionsType,
  PosCustomerPermissionsType,
  PosDrawerPermissionsType,
  PosKitchenPermissionsType,
  PosOrderPermissionsType,
  PosPermissionsType,
  PosSettingsPermissionsType,
} from '../types/permissions';

// Dashboard ---------------------------------------

const DashboardReportsPermissions: DashboardReportsPermissionsType[] = [
  'dashboard::reports::read::limited',
  'dashboard::reports::read::full',
];
const DashboardProductPermissions: DashboardProductPermissionsType[] = [
  // Category
  'dashboard::category::read',
  'dashboard::category::write',
  // Coupon
  'dashboard::coupon::read',
  'dashboard::coupon::write',
  // Deals
  'dashboard::deals::read',
  'dashboard::deals::write',
  // Discount
  'dashboard::discount::read',
  'dashboard::discount::write',
  // Extras
  'dashboard::extras::read',
  'dashboard::extras::write',
  // Giftcard
  'dashboard::giftcard::read',
  'dashboard::giftcard::write',
  // Product
  'dashboard::product::read',
  'dashboard::product::write',
  // Tag
  'dashboard::tag::read',
  'dashboard::tag::write',
  // Variant
  'dashboard::variant::read',
  'dashboard::variant::write',
];
const DashboardOrderPermissions: DashboardOrderPermissionsType[] = [
  'dashboard::order::read',
  'dashboard::order::write',
];
const DashboardCustomersPermissions: DashboardCustomersPermissionsType[] = [
  'dashboard::customers::read',
  'dashboard::customers::write',
];
const DashboardOrderDisplayPermissions: DashboardOrderDisplayPermissionsType[] = [
  'dashboard::order_display::read',
  'dashboard::order_display::write',
];

const DashboardTeamManagementPermissions: DashboardTeamManagementPermissionsType[] = [
  'dashboard::team_management::read',
  'dashboard::team_management::write',
];

const DashboardPaymentPermissions: DashboardPaymentPermissionsType[] = [
  'dashboard::payment::write',
];
const DashboardSettingsPermissions: DashboardSettingsPermissionsType[] = [
  'dashboard::settings::write',
];

const DashboardPermissions: DashboardPermissionsType[] = [
  ...DashboardReportsPermissions,
  ...DashboardProductPermissions,
  ...DashboardOrderPermissions,
  ...DashboardCustomersPermissions,
  ...DashboardOrderDisplayPermissions,
  ...DashboardTeamManagementPermissions,
  ...DashboardPaymentPermissions,
  ...DashboardSettingsPermissions,
];

// POS ---------------------------------------
const PosCheckoutPermissions: PosCheckoutPermissionsType[] = ['pos::checkout::write'];
const PosOrderPermissions: PosOrderPermissionsType[] = ['pos::order::read', 'pos::order::write'];
const PosKitchenPermissions: PosKitchenPermissionsType[] = [
  'pos::kitchen::read',
  'pos::kitchen::write',
];
const PosCustomerPermissions: PosCustomerPermissionsType[] = [
  'pos::customers::read',
  'pos::customers::write',
];
const PosDrawerPermissions: PosDrawerPermissionsType[] = [
  'pos::drawer::read',
  'pos::drawer::write',
];
const PosSettingsPermissions: PosSettingsPermissionsType[] = ['pos::settings::write'];

const PosPermissions: PosPermissionsType[] = [
  ...PosOrderPermissions,
  ...PosKitchenPermissions,
  ...PosCustomerPermissions,
  ...PosDrawerPermissions,
  ...PosSettingsPermissions,
  ...PosCheckoutPermissions,
];

// Full Permissions --------------------------------

const FullPermissions: FullPermissionsType[] = [...DashboardPermissions, ...PosPermissions];

export {
  // Dashboard
  DashboardReportsPermissions,
  DashboardProductPermissions,
  DashboardOrderPermissions,
  DashboardCustomersPermissions,
  DashboardOrderDisplayPermissions,
  DashboardTeamManagementPermissions,
  DashboardPaymentPermissions,
  DashboardSettingsPermissions,
  DashboardPermissions,
  // POS
  PosCheckoutPermissions,
  PosOrderPermissions,
  PosKitchenPermissions,
  PosCustomerPermissions,
  PosDrawerPermissions,
  PosSettingsPermissions,
  PosPermissions,
  // Full Permissions
  FullPermissions,
};
