interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const CircleAlert = ({ width = 20, height = 21, color = '#E4AC04' }: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="alert-circle">
      <path
        id="Vector"
        d="M9.99935 18.8337C14.6017 18.8337 18.3327 15.1027 18.3327 10.5003C18.3327 5.89795 14.6017 2.16699 9.99935 2.16699C5.39698 2.16699 1.66602 5.89795 1.66602 10.5003C1.66602 15.1027 5.39698 18.8337 9.99935 18.8337Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M10 7.16675V10.5001"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M10 13.8333H10.0094"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default CircleAlert;
