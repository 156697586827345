interface Props {
  size?: number;
  color?: string;
}

const PickUpIcon = ({ size = 24, color = '#637381' }: Props) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.75 8.75V7.91667C3.50842 7.91667 3.27874 8.0215 3.12046 8.204C2.96217 8.3865 2.89088 8.6287 2.92504 8.86785L3.75 8.75ZM16.25 8.75L17.075 8.86785C17.1091 8.6287 17.0378 8.3865 16.8795 8.204C16.7213 8.0215 16.4916 7.91667 16.25 7.91667V8.75ZM4.79558 16.069L3.97062 16.1869L4.79558 16.069ZM15.2044 16.069L14.3795 15.9512L15.2044 16.069ZM3.75 9.58333H16.25V7.91667H3.75V9.58333ZM15.425 8.63215L14.3795 15.9512L16.0294 16.1869L17.075 8.86785L15.425 8.63215ZM13.5545 16.6667H6.44549V18.3333H13.5545V16.6667ZM5.62053 15.9512L4.57496 8.63215L2.92504 8.86785L3.97062 16.1869L5.62053 15.9512ZM6.44549 16.6667C6.03078 16.6667 5.67918 16.3617 5.62053 15.9512L3.97062 16.1869C4.14656 17.4185 5.20137 18.3333 6.44549 18.3333V16.6667ZM14.3795 15.9512C14.3208 16.3617 13.9692 16.6667 13.5545 16.6667V18.3333C14.7986 18.3333 15.8534 17.4185 16.0294 16.1869L14.3795 15.9512Z"
      fill={color}
    />
    <path
      d="M10.8332 12.084H9.1665"
      stroke={color}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5835 5L13.3335 2.5M5.41683 5L6.66683 2.5"
      stroke={color}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.5 7.08268C2.5 6.16221 3.24619 5.41602 4.16667 5.41602H15.8333C16.7538 5.41602 17.5 6.16221 17.5 7.08268V7.08268C17.5 8.00316 16.7538 8.74935 15.8333 8.74935H4.16667C3.24619 8.74935 2.5 8.00316 2.5 7.08268V7.08268Z"
      stroke={color}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PickUpIcon;
