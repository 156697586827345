interface Props {
  size?: number;
  color?: string;
}

const StoreCircleIcon = ({ size = 68, color = '#414A53' }: Props) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 68 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="34" cy="34" r="34" fill="#F8F8FA" />
    <path
      d="M21.332 30.832V45.082C21.332 46.8309 22.7498 48.2487 24.4987 48.2487H43.4987C45.2476 48.2487 46.6654 46.8309 46.6654 45.082V30.832"
      stroke={color}
      strokeWidth="3.16667"
      strokeLinejoin="round"
    />
    <path
      d="M29.4926 20.543H38.5048M29.4926 20.543L28.9767 26.5239C28.7685 29.2811 31.0768 31.6263 33.9987 31.6263C36.9206 31.6263 39.2289 29.2811 39.0207 26.5239L38.5048 20.543M29.4926 20.543H20.4805L19.0697 25.9188C18.4043 28.8563 20.7714 31.6263 23.947 31.6263C26.5639 31.6263 28.7359 29.7131 28.9223 27.2437L29.4926 20.543ZM38.5048 20.543H47.5169L48.9277 25.9188C49.5931 28.8563 47.226 31.6263 44.0504 31.6263C41.4335 31.6263 39.2615 29.7131 39.0751 27.2437L38.5048 20.543Z"
      stroke={color}
      strokeWidth="3.16667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default StoreCircleIcon;
