import { MenuItem, TextField } from '@mui/material';
import React from 'react';

type Props = {
  data: string[];
  selectedData: string;
  onChangeData: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  maxWidth?: number;
  size?: 'small' | 'medium';
};

export default function Dropdown({
  data,
  selectedData,
  onChangeData,
  label,
  maxWidth,
  size,
}: Props) {
  return (
    <TextField
      fullWidth
      select
      size={size}
      label={label}
      value={selectedData}
      onChange={onChangeData}
      SelectProps={{
        MenuProps: {
          sx: { '& .MuiPaper-root': { maxHeight: 260 } },
        },
      }}
      sx={{
        maxWidth: { sm: maxWidth },
        textTransform: 'capitalize',
      }}
    >
      {data.map((option) => (
        <MenuItem
          dense
          key={option}
          value={option}
          sx={{
            mx: 1,
            my: 0.5,
            borderRadius: 0.75,
            typography: 'body2',
            textTransform: 'capitalize',
          }}
        >
          {option}
        </MenuItem>
      ))}
    </TextField>
  );
}
