import { ReactNode } from 'react';

// ----------------------------------------------------------------------

type Props = {
  children?: ReactNode;
};

export default function LayoutLogoOnly({ children }: Props) {
  return <>{children}</>;
}
