import React from 'react';
import { CashIcon } from 'src/assets/icon/CashIcon';
import { CreditCardIcon } from 'src/assets/icon/CreditCardIcon';
import { PaymentIdType } from 'src/@types/v2/orders/list';
import { BoxFlexCC } from '..';

interface IconProps {
  size: number;
  color: string;
}

interface Props {
  paymentId: string;
  color?: string;
  size?: number;
}

const icons: Record<PaymentIdType, React.FC<IconProps>> = {
  cash: ({ size, color }) => <CashIcon size={size} color={color} />,
  aur: ({ size, color }) => <CreditCardIcon size={size} color={color} />,
  card: ({ size, color }) => <CreditCardIcon size={size} color={color} />,
  stripe: ({ size, color }) => <CreditCardIcon size={size} color={color} />,
  cod: ({ size, color }) => <CashIcon size={size} color={color} />,
  paypal: ({ size, color }) => <CreditCardIcon size={size} color={color} />,
  pos: ({ size, color }) => <CashIcon size={size} color={color} />,
};

const PaymentIcon = ({ paymentId, color = '#414A53', size = 20 }: Props) => {
  if (!(paymentId in icons)) {
    return null;
  }
  const IconComponent = icons[paymentId as PaymentIdType];

  return (
    <BoxFlexCC>
      <IconComponent size={size} color={color} />
    </BoxFlexCC>
  );
};

export default PaymentIcon;
