import { CartItemOrder } from 'src/validations/orders/create-order';

const convertCartItemToProduct = (item: CartItemOrder) => {
  switch (item.type) {
    // case 'pizza':
    //   const convertedPizzaItem = { ...item, detail: item.detail._data };
    //   return convertedPizzaItem;

    default:
      return item;
  }
};

export default convertCartItemToProduct;
