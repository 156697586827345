import { Box, Typography, Stack, MenuItem, Divider, Button, Skeleton } from '@mui/material';
import { useRouter } from 'next/router';
import Iconify from '@components/iconify/Iconify';
import { BoxFlexCC } from 'src/styles';
import Scrollbar from '@components/scrollbar/Scrollbar';
import { BranchType } from 'src/@types/v2/settings/branch';
import { CheckIcon } from 'src/assets/icon/check';
import { PATH_DASHBOARD } from '@routes/paths';
import { StoreCircleIcon } from 'src/assets/icon/store';

interface Props {
  handleClose: () => void;
  handleSelectAllBranch: () => void;
  handleSaveBranches: () => void;
  localActiveBranches: string[];
  setLocalActiveBranches: (val: string[]) => void;
  branches: BranchType[];
  isLoading: boolean;
}

const BranchSelector = ({
  localActiveBranches,
  handleClose,
  handleSelectAllBranch,
  setLocalActiveBranches,
  branches,
  handleSaveBranches,
  isLoading,
}: Props) => {
  const { push } = useRouter();
  const branchIds = branches.map((b) => b._id);
  const isSelectedAll = branchIds.every((id) => localActiveBranches.includes(id));

  const redirectCreateBranch = () => {
    push(PATH_DASHBOARD.settings.newLocation);
  };

  return (
    <Box
      sx={{
        width: '100%',
        borderLeft: 1,
        borderColor: '#E5E7E9',
      }}
    >
      <Typography
        variant="body2"
        color="text.secondary"
        sx={{
          px: 2,
          pt: 1.5,
          pb: 2,
        }}
      >
        Select active location
      </Typography>

      <Stack
        sx={{
          height: 250,
          px: isLoading ? 2 : 0,
        }}
      >
        {isLoading &&
          Array.from({ length: 3 }).map((_, index) => (
            <Skeleton key={index} variant="rounded" width="100%" height={10} />
          ))}
        {!isLoading && (
          <Scrollbar>
            {!branches.length ? (
              <Stack alignItems="center" gap={1}>
                <StoreCircleIcon />
                <Typography variant="subtitle1">No branches available</Typography>
                <Typography variant="body2">This store currently has no branches</Typography>
                <Button onClick={redirectCreateBranch} variant="contained">
                  + Create new location
                </Button>
              </Stack>
            ) : (
              <MenuItem onClick={handleSelectAllBranch}>
                {isSelectedAll && (
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{
                      py: '5px',
                    }}
                  >
                    <BoxFlexCC
                      sx={{
                        background: '#00AB55',
                        border: '2px solid',
                        borderColor: '#00AB55',
                        borderRadius: '4px',
                        width: '20px',
                        height: '20px',
                        color: '#fff',
                      }}
                    >
                      <Iconify icon="akar-icons:minus" width={12} height={12} />
                    </BoxFlexCC>
                    <Typography
                      variant="body1"
                      fontWeight={400}
                      sx={{
                        ml: 1,
                      }}
                    >
                      All location
                    </Typography>
                  </Stack>
                )}
                {!isSelectedAll && (
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{
                      py: '5px',
                    }}
                  >
                    <BoxFlexCC
                      sx={{
                        border: '2px solid',
                        borderColor: '#E5E7E9',
                        borderRadius: '4px',
                        width: '20px',
                        height: '20px',
                        color: '#fff',
                      }}
                    />
                    <Typography
                      variant="body1"
                      fontWeight={400}
                      sx={{
                        ml: 1,
                      }}
                    >
                      All location
                    </Typography>
                  </Stack>
                )}
              </MenuItem>
            )}
            {branches &&
              branches.map((branch) => (
                <BranchItem
                  key={branch._id}
                  branch={branch}
                  setLocalActiveBranches={setLocalActiveBranches}
                  localActiveBranches={localActiveBranches}
                />
              ))}
          </Scrollbar>
        )}
      </Stack>

      <Divider />

      <Stack direction="row" justifyContent="flex-end" sx={{ px: 1.5, mt: 1.5, mb: 0.5 }} gap={1}>
        <Button variant="text" color="error" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSaveBranches}>
          Save
        </Button>
      </Stack>
    </Box>
  );
};

const BranchItem = ({
  branch,
  localActiveBranches,
  setLocalActiveBranches,
}: {
  branch: BranchType;
  localActiveBranches: string[];
  setLocalActiveBranches: (val: string[]) => void;
}) => {
  const isActive = localActiveBranches ? localActiveBranches.includes(branch._id) : false;

  const handleChangeBranch = () => {
    if (isActive) {
      const newActive = localActiveBranches.filter((id) => id !== branch._id);
      setLocalActiveBranches(newActive);
    } else {
      setLocalActiveBranches([...localActiveBranches, branch._id]);
    }
  };

  return (
    <MenuItem onClick={handleChangeBranch}>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          py: '5px',
        }}
      >
        {isActive && (
          <BoxFlexCC
            sx={{
              background: '#00AB55',
              border: '2px solid',
              borderColor: '#00AB55',
              borderRadius: '4px',
              width: '20px',
              height: '20px',
            }}
          >
            <CheckIcon width={12} height={12} />
          </BoxFlexCC>
        )}
        {!isActive && (
          <BoxFlexCC
            sx={{
              border: '2px solid',
              borderColor: '#E5E7E9',
              borderRadius: '4px',
              width: '20px',
              height: '20px',
            }}
          />
        )}
        <Typography
          variant="body1"
          fontWeight={400}
          sx={{
            ml: 1,
          }}
        >
          {branch.location_name}
        </Typography>
      </Stack>
    </MenuItem>
  );
};

export default BranchSelector;
