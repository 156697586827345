import { Stack, Typography } from '@mui/material';
import React from 'react';
import { CircleCheckIcon } from 'src/assets/icon/circle';
import { Image } from '@components/images';
import { usePropelAuthContext } from 'src/auth/useAuthContext';
import { StoreIcon } from 'src/assets/icon/StoreIcon';

const StoreSelector = () => {
  const { user, setStoreId, currentStoreId } = usePropelAuthContext();

  const filtered = user?.stores.sort((a, b) =>
    a.store.business_name.localeCompare(b.store.business_name)
  );
  return (
    <Stack sx={{ p: '8px', minWidth: 210 }}>
      {filtered &&
        filtered.map((item) => (
          <Stack
            onClick={() => setStoreId(item.store._id)}
            sx={{
              cursor: 'pointer',
              ':hover': {
                bgcolor: '#FAFAFC',
              },
              p: 1,
              borderRadius: '5px',
            }}
            key={item._id}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center" gap={1}>
              {item.store.logo.url ? (
                <Image
                  alt={item.store.logo.alt}
                  src={item.store.logo.url || ''}
                  maxWidth={32}
                  maxHeight={32}
                  sx={{ borderRadius: '5px' }}
                />
              ) : (
                <StoreIcon size={24} />
              )}
              <Typography fontWeight={500} variant="subtitle1">
                {item.store.business_name}
              </Typography>
            </Stack>
            {currentStoreId === item.store._id && <CircleCheckIcon size={20} />}
          </Stack>
        ))}
    </Stack>
  );
};

export default StoreSelector;
