import { useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import {
  Drawer,
  Stack,
  Box,
  Typography,
  IconButton,
  Checkbox,
  TextField,
  Divider,
  Button,
  Switch,
  FormControlLabel,
} from '@mui/material';
// components
import { BoxFlexBC, BoxFlexCC, BoxFlexEE, BoxFlexSC } from 'src/styles';
import { IconButtonAnimate } from '@components/animate';
import { CrossIcon } from 'src/assets/icon/CrossIcon';
import { AlternativePrinterIcon } from 'src/assets/icon/PrinterIcon';
// store
import usePrintSettings from '@hooks/usePrintSettings';
import Iconify from '@components/iconify/Iconify';
import Dropdown from '@components/dropdown/Dropdown';

interface ItemProps {
  name: string;
}

export default function Autoprint() {
  const [open, setOpen] = useState<HTMLElement | null>(null);

  const {
    automaticPrinting,
    toggleAutomaticPrinting,
    autoprintOnReady,
    toggleAutoprintOnReady,
    printMode,
    availableModes,
    changePrintMode,
  } = usePrintSettings();

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleChangeMode = (e: React.ChangeEvent<HTMLInputElement>) => {
    changePrintMode(e.target.value as typeof printMode);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButtonAnimate
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
          ...(open && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
        }}
      >
        <AlternativePrinterIcon />
      </IconButtonAnimate>

      <Drawer
        anchor="right"
        open={Boolean(open)}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: '450px',
          },
        }}
      >
        <Stack
          sx={{
            px: '24px',
          }}
        >
          <Box
            sx={{
              position: 'sticky',
              top: 0,
              right: 0,
              zIndex: 9,
              py: '24px',
              bgcolor: 'white',
              // borderBottom: '1px solid #E5E7E9',
            }}
          >
            <BoxFlexBC mb={1}>
              <Typography variant="h5">Autoprint</Typography>
              <IconButton onClick={handleClose}>
                <CrossIcon />
              </IconButton>
            </BoxFlexBC>
            <Stack
              sx={{
                p: 1,
                px: 2,
                width: 1,
                border: 1,
                borderColor: 'lightgray',
                mt: 2,
                borderRadius: '8px',
              }}
              spacing={2}
              direction="column"
            >
              <BoxFlexBC>
                <Typography>Status</Typography>
                <FormControlLabel
                  control={
                    <Switch checked={automaticPrinting} onChange={toggleAutomaticPrinting} />
                  }
                  label={automaticPrinting ? 'Enabled' : 'Disabled'}
                  labelPlacement="start"
                />
              </BoxFlexBC>
            </Stack>
            <Stack
              sx={{
                p: 1,
                px: 2,
                width: 1,
                border: 1,
                borderColor: 'lightgray',
                mt: 2,
                borderRadius: '8px',
              }}
              spacing={2}
              direction="column"
            >
              <BoxFlexBC>
                <Typography>Print on Ready</Typography>
                <FormControlLabel
                  control={<Switch checked={autoprintOnReady} onChange={toggleAutoprintOnReady} />}
                  label={autoprintOnReady ? 'Enabled' : 'Disabled'}
                  labelPlacement="start"
                />
              </BoxFlexBC>
            </Stack>
            <Stack
              sx={{
                p: 1,
                px: 2,
                width: 1,
                border: 1,
                borderColor: 'lightgray',
                mt: 2,
                borderRadius: '8px',
              }}
              spacing={2}
              direction="column"
            >
              <BoxFlexBC>
                <Typography>Print Mode</Typography>
                <Dropdown
                  data={availableModes}
                  selectedData={printMode}
                  onChangeData={handleChangeMode}
                  label=""
                />
              </BoxFlexBC>
            </Stack>
          </Box>
        </Stack>
        {automaticPrinting ? (
          <Box
            sx={{
              display: 'flex',
              height: '100%',
              px: '24px',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: '20px',
            }}
          >
            {/* <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <Typography variant="h5">Manage autoprint</Typography>
              <Typography variant="subtitle2" color="text.secondary">
                Choose what you want to print automatically
              </Typography>
            </Box>
            <Item name={'Receipt'} />
            <Item name={'Order'} />
            <Item name={'Kitchen'} />
            <Item name={'Receipt payment'} /> */}
          </Box>
        ) : (
          <BoxFlexCC sx={{ height: '100%', flexDirection: 'column', px: '24px', gap: '20px' }}>
            <BoxFlexCC
              sx={{
                borderRadius: '50px',
                width: '82px',
                height: '82px',
                backgroundColor: '#F4F6F8',
              }}
            >
              <Iconify icon="carbon:printer" width="40px" height="40px" />
            </BoxFlexCC>
            <Typography variant="h4">Autoprint inactive</Typography>
            <Typography variant="subtitle2" color="text.secondary" textAlign="center">
              Activate it to use the auto print feature and print automatically when an order placed
            </Typography>
          </BoxFlexCC>
        )}
        <BoxFlexEE sx={{ borderTop: '1px solid #E5E7E9', padding: '15px 20px', gap: '20px' }}>
          <Button size="large" variant="outlined">
            Cancel
          </Button>
          <Button size="large" variant="contained">
            Save setting
          </Button>
        </BoxFlexEE>
      </Drawer>
    </>
  );
}

const Item = ({ name }: ItemProps) => (
  <BoxFlexSC>
    <Checkbox sx={{ alignSelf: 'flex-start', width: '24px', height: '24px' }} />
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', marginLeft: '10px' }}>
      <Typography variant="subtitle1">{name}</Typography>
      <Typography variant="subtitle2" color="text.secondary">
        Will automatic print receipt when order placed
      </Typography>
      <Box display="flex" gap="10px" alignItems="center">
        <Typography variant="subtitle2">How many do you want to print? </Typography>
        <TextField type="number" size="small" sx={{ width: '46px' }} />
        <Typography variant="subtitle2">copy</Typography>
      </Box>
      <Divider sx={{ mt: '10px' }} />
    </Box>
  </BoxFlexSC>
);
