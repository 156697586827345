interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const CheckIcon = ({ width = 16, height = 11, color = '#FFF' }: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6673 1L5.50065 10.1667L1.33398 6"
      stroke={color}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CheckIcon;
