interface Props {
  size?: number;
  color?: string;
}

const CircleCheckIcon2 = ({ size = 20, color = '#414A53' }: Props) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4500_112781)">
      <path
        d="M18.3346 9.2355V10.0022C18.3336 11.7992 17.7517 13.5477 16.6757 14.987C15.5998 16.4263 14.0874 17.4792 12.3641 17.9887C10.6408 18.4983 8.79902 18.4371 7.11336 17.8143C5.4277 17.1916 3.98851 16.0406 3.01044 14.5331C2.03236 13.0255 1.56779 11.2422 1.68603 9.44909C1.80427 7.65597 2.49897 5.9491 3.66654 4.58306C4.8341 3.21701 6.41196 2.26498 8.16479 1.86895C9.91763 1.47292 11.7515 1.65411 13.393 2.3855"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3333 3.33203L10 11.6737L7.5 9.1737"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4500_112781">
        <rect width={size} height={size} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default CircleCheckIcon2;
