interface Props {
  size?: number;
  color?: string;
}

export const CreditCardIcon = ({ size = 32, color = '#637381' }: Props) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 9.33268C4 7.85992 5.19391 6.66602 6.66667 6.66602H25.3333C26.8061 6.66602 28 7.85992 28 9.33268V22.666C28 24.1388 26.8061 25.3327 25.3333 25.3327H6.66667C5.19391 25.3327 4 24.1388 4 22.666V9.33268Z"
      stroke={color}
      strokeWidth="2.66667"
      strokeLinejoin="round"
    />
    <path d="M4 12H28" stroke={color} strokeWidth="2.66667" />
    <path
      d="M9.33203 18.6667C8.59565 18.6667 7.9987 19.2636 7.9987 20C7.9987 20.7364 8.59565 21.3333 9.33203 21.3333V18.6667ZM10.6654 21.3333C11.4017 21.3333 11.9987 20.7364 11.9987 20C11.9987 19.2636 11.4017 18.6667 10.6654 18.6667V21.3333ZM9.33203 21.3333H10.6654V18.6667H9.33203V21.3333Z"
      fill={color}
    />
  </svg>
);
