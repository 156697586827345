import React from 'react';

function StopwatchIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="#637381"
        d="M10 1a1 1 0 000 2h4a1 1 0 000-2h-4zm8.994 2.205a1.001 1.001 0 00-.564 1.762l1.424 1.207a1.001 1.001 0 001.293-1.528L19.723 3.44a.998.998 0 00-.729-.234zM12 4c-4.962 0-9 4.038-9 9s4.038 9 9 9 9-4.038 9-9-4.038-9-9-9zm0 3a1 1 0 011 1v5a1 1 0 01-2 0V8a1 1 0 011-1z"
      />
    </svg>
  );
}

export default StopwatchIcon;
