import { AxiosInstance } from 'axios';
import { uploadMediaFile } from '@redux/actions/media-library';
import { dispatch } from '@redux/store';
import { SnackbarType } from 'src/@types/v2/snackbar';
import { showSnackbar } from '@utils/snackbar';

interface Props {
  blobURL: string;
  axiosInstance: AxiosInstance;
  enqueueSnackbar?: SnackbarType;
}

const uploadBlobURLToLibrary = async ({
  blobURL,
  axiosInstance,
  enqueueSnackbar,
}: Props): Promise<string> => {
  try {
    if (!isBlobURL(blobURL)) {
      const urlValid = blobURL;
      return urlValid;
    }

    const filename = blobURL.substring(blobURL.lastIndexOf('/') + 1);
    const response = await fetch(blobURL);
    const blobData = await response.blob();
    const extension = blobData.type.split('/')[1];
    const fullFilename = `${filename}.${extension}`;

    const imageFile = new File([blobData], fullFilename, { type: blobData.type });

    const res = await dispatch(uploadMediaFile(imageFile, axiosInstance));

    return res.url;
  } catch (error) {
    showSnackbar({
      message: `Failed to upload image to the media library: ${error.message}`,
      variant: 'error',
      enqueueSnackbar,
    });
    throw new Error(`Failed to upload image to the media library: ${error.message}`);
  }
};

const isBlobURL = (url: string): boolean => url.startsWith('blob:');

export default uploadBlobURLToLibrary;
