// routes
import { PATH_DASHBOARD } from '@routes/paths';
import SvgIconStyle from 'src/styles/SvgIconStyle';
import { NavConfigProps } from '@components/nav-section/types';
import { InventoryIcon, ProductIcon, OrderIcon, TeamManagementIcon } from 'src/assets/icon/navbar';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1, backgroundColor: '#565B60' }}
  />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
  restaurant: <ProductIcon />,
  inventory: <InventoryIcon />,
  orders: <OrderIcon />,
  teamManagement: <TeamManagementIcon />,
  customers: getIcon('ic_customer'),
  integration: getIcon('ic_integration'),
  payment: getIcon('ic_payment'),
  settings: getIcon('ic_settings'),
  productSales: getIcon('ic_productsales'),
  kitchen: getIcon('ic_kitchen'),
};

const navConfig: NavConfigProps[] = [
  {
    subheader: '',
    // user must have one of these permissions to access this section
    permissions: ['dashboard::reports::read::limited', 'dashboard::reports::read::full'],
    items: [
      {
        title: 'dashboard',
        path: PATH_DASHBOARD.general.dashboard,
        icon: ICONS.dashboard,
        permissions: ['dashboard::reports::read::limited'],
      },
      {
        title: 'report',
        path: PATH_DASHBOARD.report.root,
        icon: ICONS.productSales,
        permissions: ['dashboard::reports::read::limited'],
        children: [
          {
            title: 'sales summary',
            path: PATH_DASHBOARD.report.salesSummary,
            permissions: ['dashboard::reports::read::limited'],
          },
          {
            title: 'sales by product',
            path: PATH_DASHBOARD.report.salesByProduct,
            permissions: ['dashboard::reports::read::limited'],
          },
          {
            title: 'sales by deal',
            path: PATH_DASHBOARD.report.salesByDeal,
            permissions: ['dashboard::reports::read::limited'],
          },
          {
            title: 'sales trend',
            path: PATH_DASHBOARD.report.salesTrend,
            permissions: ['dashboard::reports::read::limited'],
          },
          {
            title: 'sales by category',
            path: PATH_DASHBOARD.report.salesByCategory,
            permissions: ['dashboard::reports::read::limited'],
          },
          {
            title: 'sales by customer',
            path: PATH_DASHBOARD.report.salesByCustomer,
            permissions: ['dashboard::reports::read::limited'],
          },
          {
            title: 'sales by source',
            path: PATH_DASHBOARD.report.salesBySource,
            permissions: ['dashboard::reports::read::limited'],
          },
          {
            title: 'customer growth',
            path: PATH_DASHBOARD.report.customerGrowth,
            permissions: ['dashboard::reports::read::limited'],
          },
          {
            title: 'wolt drive',
            path: PATH_DASHBOARD.report.woltDrive,
            permissions: ['dashboard::reports::read::limited'],
          },
          {
            title: 'payment methods',
            path: PATH_DASHBOARD.report.paymentMethods,
            permissions: ['dashboard::reports::read::limited'],
          },
          {
            title: 'fulfillment',
            path: PATH_DASHBOARD.report.fulfillment,
            permissions: ['dashboard::reports::read::limited'],
          },
          {
            title: 'coupon',
            path: PATH_DASHBOARD.report.coupon,
            permissions: ['dashboard::reports::read::limited'],
          },
          {
            title: 'discount',
            path: PATH_DASHBOARD.report.discount,
            permissions: ['dashboard::reports::read::limited'],
          },
          {
            title: 'branch',
            path: PATH_DASHBOARD.report.branch,
            permissions: ['dashboard::reports::read::limited'],
          },
          {
            title: 'extras',
            path: PATH_DASHBOARD.report.extras,
            permissions: ['dashboard::reports::read::limited'],
          },
          {
            title: 'waiting time',
            path: PATH_DASHBOARD.report.waitingTime,
            permissions: ['dashboard::reports::read::limited'],
          },
        ],
      },
    ],
  },
  {
    subheader: 'GENERAL',
    permissions: [
      // Products
      'dashboard::product::read',
      'dashboard::deals::read',
      'dashboard::category::read',
      'dashboard::tag::read',
      'dashboard::extras::read',
      'dashboard::variant::read',
      'dashboard::giftcard::read',
      'dashboard::discount::read',
      'dashboard::coupon::read',
      // Orders
      'dashboard::order::read',
      // Customers
      'dashboard::customers::read',
    ],
    items: [
      {
        title: 'product',
        path: PATH_DASHBOARD.restaurant.root,
        icon: ICONS.restaurant,
        permissions: [
          'dashboard::product::read',
          'dashboard::deals::read',
          'dashboard::category::read',
          'dashboard::tag::read',
          'dashboard::extras::read',
          'dashboard::variant::read',
          'dashboard::giftcard::read',
          'dashboard::discount::read',
          'dashboard::coupon::read',
        ],
        children: [
          {
            title: 'product',
            path: PATH_DASHBOARD.restaurant.product,
            permissions: ['dashboard::product::read'],
          },
          {
            title: 'product deals',
            path: PATH_DASHBOARD.restaurant.deals,
            permissions: ['dashboard::deals::read'],
          },
          {
            title: 'category',
            path: PATH_DASHBOARD.restaurant.category,
            permissions: ['dashboard::category::read'],
          },
          {
            title: 'tag',
            path: PATH_DASHBOARD.restaurant.tag,
            permissions: ['dashboard::tag::read'],
          },
          {
            title: 'extras',
            path: PATH_DASHBOARD.restaurant.extras,
            permissions: ['dashboard::extras::read'],
          },
          {
            title: 'variant',
            path: PATH_DASHBOARD.restaurant.variant,
            permissions: ['dashboard::variant::read'],
          },
          {
            title: 'giftcard',
            path: PATH_DASHBOARD.restaurant.giftcard,
            permissions: ['dashboard::giftcard::read'],
          },
          {
            title: 'discount',
            path: PATH_DASHBOARD.restaurant.discount,
            permissions: ['dashboard::discount::read'],
          },
          {
            title: 'coupons',
            path: PATH_DASHBOARD.restaurant.coupons,
            permissions: ['dashboard::coupon::read'],
          },
        ],
      },
      {
        title: 'orders',
        path: PATH_DASHBOARD.orders.root,
        icon: ICONS.orders,
        permissions: ['dashboard::order::read'],
        children: [
          {
            title: 'orders',
            path: PATH_DASHBOARD.orders.root,
            permissions: ['dashboard::order::read'],
          },
        ],
      },
      {
        title: 'customers',
        path: PATH_DASHBOARD.customers.root,
        icon: ICONS.customers,
        permissions: ['dashboard::customers::read'],
      },
      {
        title: 'inventory management',
        path: PATH_DASHBOARD.inventory.root,
        icon: ICONS.inventory,
        feature: 'use-inventory-management-dashboard',
        permissions: ['dashboard::inventory::read'],
      },
    ],
  },
  {
    subheader: 'DEVICES',
    permissions: ['dashboard::order_display::read'],
    items: [
      {
        title: 'order display',
        path: PATH_DASHBOARD.orderDisplay.root,
        icon: ICONS.kitchen,
        permissions: ['dashboard::order_display::read'],
      },
    ],
  },
  {
    subheader: 'ADMIN',
    permissions: [
      // Settings
      'dashboard::settings::write',
      // Team Management
      'dashboard::team_management::read',
      // Payments
      'dashboard::payment::write',
    ],
    items: [
      {
        title: 'online store',
        path: PATH_DASHBOARD.onlineStore.root,
        icon: ICONS.integration,
        permissions: ['dashboard::widget::write', 'dashboard::widget::read'],
        children: [
          {
            title: 'quick start guide',
            path: PATH_DASHBOARD.onlineStore.quickStartGuide,
          },
          {
            title: 'widget',
            path: PATH_DASHBOARD.onlineStore.widget,
            permissions: ['dashboard::widget::write', 'dashboard::widget::read'],
          },
          {
            title: 'QR ordering',
            feature: 'online-store',
            path: PATH_DASHBOARD.onlineStore.qrOrdering,
            permissions: ['dashboard::widget::write', 'dashboard::widget::read'],
          },
          {
            title: 'developer tools',
            path: PATH_DASHBOARD.onlineStore.developerTools,
          },
        ],
      },
      // { title: 'table plan', path: PATH_DASHBOARD.tablePlan.root, icon: ICONS.integration },
      // { title: 'integration', path: PATH_DASHBOARD.general.integration, icon: ICONS.integration },
      // { title: 'payment', path: PATH_DASHBOARD.general.payment, icon: ICONS.payment },
      {
        title: 'settings',
        path: PATH_DASHBOARD.settings.root,
        icon: ICONS.settings,
        permissions: ['dashboard::settings::write'],
        children: [
          {
            title: 'settings',
            path: PATH_DASHBOARD.settings.root,
            permissions: ['dashboard::settings::write'],
          },
          {
            title: 'business',
            path: PATH_DASHBOARD.settings.business,
            permissions: ['dashboard::settings::write'],
          },
          {
            title: 'preferences',
            path: PATH_DASHBOARD.settings.preferences,
            permissions: ['dashboard::settings::write'],
          },
          {
            title: 'account',
            path: PATH_DASHBOARD.settings.account,
            permissions: ['dashboard::settings::write'],
          },
          {
            title: 'accountant key',
            path: PATH_DASHBOARD.settings.accountantKey,
            permissions: ['dashboard::settings::write'],
          },
          // {
          //   title: 'fulfillment',
          //   path: PATH_DASHBOARD.settings.fulfillment,
          //   permissions: ['dashboard::settings::write'],
          // },
          // {
          //   title: 'billing',
          //   path: PATH_DASHBOARD.settings.billing,
          //   permissions: ['dashboard::settings::write'],
          // },
          {
            title: 'taxes',
            feature: 'tax-rules',
            path: PATH_DASHBOARD.settings.taxes,
            permissions: ['dashboard::settings::write'],
          },
          // {
          //   title: 'notification',
          //   path: PATH_DASHBOARD.settings.notification,
          //   permissions: ['dashboard::settings::write'],
          // },
          {
            title: 'files',
            path: PATH_DASHBOARD.settings.files,
            permissions: ['dashboard::settings::write'],
          },
        ],
      },
      {
        title: 'team management',
        path: PATH_DASHBOARD.teamManagement.root,
        icon: ICONS.teamManagement,
        permissions: ['dashboard::team_management::read'],
        children: [
          {
            title: 'team member',
            path: PATH_DASHBOARD.teamManagement.members,
            permissions: ['dashboard::team_management::read'],
          },
          {
            title: 'role',
            path: PATH_DASHBOARD.teamManagement.role,
            permissions: ['dashboard::team_management::read'],
          },
        ],
      },
      {
        title: 'Payment',
        path: PATH_DASHBOARD.payment.root,
        icon: ICONS.payment,
        permissions: ['dashboard::payment::write'],
      },
    ],
  },
];

export default navConfig;
