import * as Sentry from '@sentry/nextjs';
import { AxiosInstance } from 'axios';
import { uploadBlobURLToLibrary } from '@utils/media-library';
import {
  ProductFormProps,
  ProductVariationFormType,
} from 'src/validations/restaurant/product/product-form';
import { notEmpty } from '@utils/object-helper';
import { AdditionalPriceType } from 'src/@types/v2/products';

const getProductRequestData = async (values: ProductFormProps, axiosInstance: AxiosInstance) => {
  try {
    type AvailableVariationWithPriceType = {
      variants: {
        variant: string;
        attribute: string;
      }[];
      additional_price: AdditionalPriceType;
      use_product_price: boolean;
    };
    type AvailableVariationType = {
      variants: {
        variant: string;
        attribute: string;
      }[];
    };
    type ExcludedProductType = {
      product: string;
      excluded_variations: AvailableVariationType[];
    };
    type MenuItemType = {
      category?: string;
      product?: string;
      quantity: number;
      use_product_price?: boolean;
      additional_price?: AdditionalPriceType;
      available_products?: string[] | null;
      available_variations?: AvailableVariationWithPriceType[] | null;
      free_extras?: number | null;
      excluded_products?: ExcludedProductType[] | null;
    };
    const newMenus: {
      menu_name: string;
      menu_type: string;
      menu_items: MenuItemType[];
    }[] = [];
    const newExtras: {
      name: string;
      parent: string | null;
      extras_id?: string;
      is_required: boolean;
      min_choose: number;
      max_choose: number;
      active: boolean;
      items: {
        name: string;
        price: number;
        include: boolean;
        item_parent: string;
        variants: {
          price: number;
          variant_item: string;
        }[];
      }[];
    }[] = [];

    if (values.extras && values.extras.length > 0) {
      values.extras.forEach((extra) =>
        newExtras.push({
          extras_id: extra.type === 'global-extra' ? null : (extra as any)._id,
          name: extra.name,
          parent: extra.parent || null,
          is_required: extra.is_required || false,
          min_choose: extra.min_choose || 0,
          max_choose: extra.max_choose || 0,
          active: extra.active || false,
          items: extra.items.map((item) => {
            if (item.extras_item_id) {
              return {
                name: item.name,
                price: item.price,
                include: item.include || false,
                item_parent: item.extras_item_id,
                variants: item.variants.map((variable) => ({
                  price: variable.price,
                  variant_item: variable.variant_item._id,
                })),
                available_for_variations: [],
                quantity: item.quantity,
              };
            }
            return {
              name: item.name,
              price: item.price,
              include: item.include || false,
              item_parent: item.item_parent || '',
              extras_item_id: item._id,
              variants: item.variants.map((variable) => ({
                price: variable.price,
                variant_item: variable.variant_item._id,
              })),
              available_for_variations: [],
              quantity: item.quantity,
            };
          }),
        })
      );
    }

    const uploadImages = async (
      images: {
        url: string;
        filename: string;
        alt: string;
        primary: boolean;
      }[]
    ): Promise<
      {
        url: string;
        filename: string;
        alt: string;
        primary: boolean;
      }[]
    > => {
      const imageUploadPromises = images.map(async (image) => {
        const imageUrl = await uploadBlobURLToLibrary({
          blobURL: image.url,
          axiosInstance,
        });
        return {
          filename: image.filename,
          url: imageUrl,
          alt: image.alt,
          primary: image.primary,
        };
      });
      const imageUploadResults = await Promise.all(imageUploadPromises);
      return imageUploadResults;
    };

    const finalImages = await uploadImages(values.images);

    const uploadVariationImage = async (
      image: ProductVariationFormType['images'][0]
    ): Promise<
      | {
          filename: string;
          url: string;
          alt: string;
          primary: boolean;
        }
      | undefined
    > => {
      try {
        const imageUrl = await uploadBlobURLToLibrary({
          blobURL: image.url,
          axiosInstance,
        });
        return {
          filename: image.filename,
          url: imageUrl,
          alt: image.alt,
          primary: image.primary,
        };
      } catch (error) {
        return undefined;
      }
    };

    const uploadVariationImages = async (variations: ProductVariationFormType[]) => {
      if (!variations || variations.length === 0) {
        return [];
      }

      const variationUploadImagePromises = variations.map((variation, index) => {
        if (!variation.images || variation.images.length === 0) {
          return [];
        }

        const variationImages = variation.images.map((image) => uploadVariationImage(image));

        return Promise.all(variationImages);
      });

      const variationUploadImageResults = await Promise.all(variationUploadImagePromises);

      const finalVariations = variations.map((variation, index) => ({
        ...variation,
        images: variationUploadImageResults[index]
          ? variationUploadImageResults[index].filter(notEmpty)
          : [],
      }));

      return finalVariations;
    };

    const finalVariations = await uploadVariationImages(values.variations);

    values.upsell_product.menus.forEach((menu) => {
      const menuItems = menu.menu_items.map((item) => {
        let newItem: MenuItemType = {
          category: '',
          product: '',
          quantity: 0,
          available_variations: null,
          free_extras: null,
          use_product_price: false,
          additional_price: {
            type: 'fixed',
            value: 0,
          },
          available_products: null,
          excluded_products: null,
        };

        const availableVariations: AvailableVariationWithPriceType[] = item.selected_variations.map(
          (selected) => ({
            variants: selected.variants.map((variant) => ({
              variant: variant.variable._id,
              attribute: variant.attribute._id,
            })),
            additional_price: selected.additional_price,
            use_product_price: selected.use_product_price,
          })
        );

        // @TODO: don't use any
        const excludedProducts: ExcludedProductType[] = item.excluded_products.map((product) => {
          const excludedVariations: any[] = product.excluded_variations.map((excluded) => ({
            variants: excluded.variants.map((variant) => ({
              variant: variant.variable,
              attribute: variant.attribute,
            })),
          }));

          return {
            product: product.product._id,
            excluded_variations: excludedVariations,
          };
        });

        if (item.product !== null) {
          newItem = {
            product: item.item_id,
            quantity: item.quantity,
            ...(availableVariations.length > 0 && { available_variations: availableVariations }),
            ...(item.selected_variations.length === 0 && {
              use_product_price: item.use_product_price,
            }),
            ...(item.selected_variations.length === 0 && {
              additional_price: item.additional_price,
            }),
            ...(item.excluded_products.length > 0 && {
              excluded_products: excludedProducts,
            }),
          };
        } else if (item.category !== null) {
          newItem = {
            category: item.item_id,
            quantity: item.quantity,
            ...(availableVariations.length > 0 && { available_variations: availableVariations }),
            ...(item.selected_variations.length === 0 && {
              use_product_price: item.use_product_price,
            }),
            ...(item.selected_variations.length === 0 && {
              additional_price: item.additional_price,
            }),
            ...(item.excluded_products.length > 0 && {
              excluded_products: excludedProducts,
            }),
          };
        }

        return newItem;
      });

      newMenus.push({
        menu_name: menu.menu_name,
        menu_type: menu.menu_type,
        menu_items: menuItems,
      });
    });

    const bodyData = {
      sku: values.sku,
      name: values.name,
      branches: values.branches,
      tags: values.tags.map((tag) => tag.value),
      categories: values.categories.map((item) => ({
        category: item.value,
        primary: item.primary,
      })),
      description: values.description,
      metadata: values.metadata,
      base_price: values.base_price ? parseInt(values.base_price, 10) : 0,
      available: true,
      product_availability: {
        web_availability: values.web_availability,
        pos_availability: values.pos_availability,
      },
      images: finalImages,
      upsell_product: {
        active: values.upsell_product.active,
        menus: newMenus,
      },
      extras: newExtras,
      variables: values.variables,
      variations:
        finalVariations?.map((variation) => ({
          ...variation,
          base_price: Number(variation.base_price) || 0,
        })) || [],
    };

    return bodyData;
  } catch (error) {
    console.log(error);

    Sentry.captureException(error);

    throw error;
  }
};

export default getProductRequestData;
