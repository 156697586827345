import { ReactNode } from 'react';
// @mui
import { Box, Typography, Link } from '@mui/material';
//
import { BoxFlexBC } from 'src/styles';
import Breadcrumbs, { Props as BreadcrumbsProps } from './Breadcrumbs';

// ----------------------------------------------------------------------

interface Props extends BreadcrumbsProps {
  action?: ReactNode;
  heading: string;
  headingVariant?: 'h5' | 'h6' | any;
  gutterBottom?: boolean;
  moreLink?: string | string[];
  mb?: number;
  headingRightElement?: ReactNode;
}

export default function HeaderBreadcrumbs({
  links,
  action,
  heading,
  mb = 5,
  gutterBottom = true,
  headingVariant = 'h4',
  moreLink = '' || [],
  headingRightElement,
  sx,
  ...other
}: Props) {
  return (
    <Box sx={{ mb, ...sx }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1 }}>
          <BoxFlexBC>
            <Typography variant={headingVariant} color="black" gutterBottom={gutterBottom}>
              {heading}
            </Typography>
            {headingRightElement}
          </BoxFlexBC>
          <Breadcrumbs links={links} {...other} />
        </Box>

        {action && <Box sx={{ flexShrink: 0 }}>{action}</Box>}
      </Box>

      <Box sx={{ mt: 0 }}>
        {typeof moreLink === 'string' ? (
          <Link href={moreLink} target="_blank" variant="body2" underline="none">
            {moreLink}
          </Link>
        ) : (
          moreLink.map((href) => (
            <Link
              noWrap
              key={href}
              href={href}
              variant="body2"
              target="_blank"
              underline="none"
              sx={{ display: 'table' }}
            >
              {href}
            </Link>
          ))
        )}
      </Box>
    </Box>
  );
}
