export type ParamsType = {
  page?: number | null;
  limit?: number | null;
  sort_by?: string;
  order_method?: string;
  order_status?: string;
  end: string | null;
  start: string | null;
  query?: string;
  branch: string[];
};

const generateOrderQueryParams = (params: ParamsType) => {
  const query = new URLSearchParams();
  Object.keys(params).forEach((key) => {
    if (key === 'branch') {
      if (params[key].length > 0) {
        query.append(key, params[key].join(','));
      }

      return;
    }

    if (
      params[key as keyof ParamsType] !== null &&
      params[key as keyof ParamsType] !== undefined &&
      params[key as keyof ParamsType] !== 'all' &&
      params[key as keyof ParamsType] !== ''
    ) {
      const value = params[key as keyof ParamsType];
      if (value) {
        query.append(key, value.toString());
      }
    }
  });

  return query.toString();
};

export default generateOrderQueryParams;
