import React from 'react';
// muis
import { Button, Card, Dialog, Typography, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import { useSnackbar } from '@components/snackbar';
import { useDispatch } from '@redux/store';
import { useGiftcard } from '@redux/reducers/giftcard';
import { enableIndividualGiftcard } from '@redux/actions/giftcard';
import { useAxios } from 'src/auth/AxiosContext';

export interface Props {
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const RootStyle = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(4, 4),
}));

export default function EnableGiftcardDialog({ modalOpen, setModalOpen, ...props }: Props) {
  const axiosInstance = useAxios();
  const dispatch = useDispatch();
  const { giftcard } = useGiftcard();
  const { enqueueSnackbar } = useSnackbar();

  const handleEnableClick = () => {
    try {
      if (
        giftcard?.giftcard_type === 'individual' &&
        typeof giftcard.individual_giftcard !== 'undefined'
      ) {
        const isEnabling = giftcard?.individual_giftcard?.status === 'disabled';

        if (isEnabling) {
          dispatch(enableIndividualGiftcard(giftcard._id, enqueueSnackbar, axiosInstance));
        }
        setModalOpen(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onClose = () => {
    setModalOpen(false);
  };

  return (
    <Dialog onClose={onClose} maxWidth="xs" fullWidth open={modalOpen} {...props}>
      <RootStyle>
        <Box>
          <Typography variant="h5" gutterBottom>
            Are you sure you want to enable the Gift card?
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            If the gift card is enable, the customer can be used the gift card again
          </Typography>

          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            marginTop="32px"
          >
            <Grid item xs={6}>
              <Button fullWidth variant="outlined" onClick={onClose}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth variant="contained" onClick={handleEnableClick}>
                Enable
              </Button>
            </Grid>
          </Grid>
        </Box>
      </RootStyle>
    </Dialog>
  );
}
