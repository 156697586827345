// next
import { Button, Stack } from '@mui/material';
import { usePropelAuthContext } from 'src/auth/useAuthContext';

export default function LoginFormPropel() {
  const { login } = usePropelAuthContext();

  return (
    <Stack>
      <Button fullWidth size="large" type="submit" variant="contained" onClick={login}>
        Sign in
      </Button>
    </Stack>
  );
}
