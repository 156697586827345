import { ProductType, ProductVariation } from 'src/@types/v2/products';

const convertProducts = {
  toVariants: (products: ProductType[]) => {
    if (!products) return [];
    const getFirstAttributes = products.map((product) =>
      product.variations.map((variation) => ({
        attribute: variation.name.substring(0, variation.name.indexOf(',')),
        variableId: variation.variants[0]?.variable,
        attributeId: variation.variants[0]?.attribute,
      }))
    );
    const getSecondAttributes = products.map((product) =>
      product.variations.map((variation) => ({
        attribute: variation.name.substring(variation.name.indexOf(',') + 1).trim(),
        variableId: variation.variants[1]?.variable,
        attributeId: variation.variants[1]?.attribute,
      }))
    );

    const mergedAttributes = [...getFirstAttributes, ...getSecondAttributes];

    const removeSameAttributes = mergedAttributes.map((item) =>
      item.filter(
        (variation, index, self) =>
          index === self.findIndex((t) => t.attribute === variation.attribute)
      )
    );
    const mergeAttributes = removeSameAttributes.reduce((acc, val) => acc.concat(val), []);
    const filteredSameAttributes = mergeAttributes.filter(
      (item, index, self) =>
        index ===
        self.findIndex((t) => t.attribute === item.attribute && t.variableId === item.variableId)
    );
    return filteredSameAttributes;
  },
  toVariations: (products: ProductType[]) => {
    if (!products) return [];
    const mergedVariations: ProductVariation[] = [];

    products.map((item) => item.variations.filter((variation) => mergedVariations.push(variation)));
    const removeSameVariations = mergedVariations.filter(
      (variation, index) =>
        mergedVariations.findIndex((item) => item.name === variation.name) === index
    );
    const orderByAlphabet = removeSameVariations.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    return orderByAlphabet;
  },
};

export default convertProducts;
