import { DialogTitle, IconButton, Typography, Stack, Dialog, Divider, Box } from '@mui/material';
import React, { ReactNode } from 'react';
import { BoxFlexBC } from 'src/styles';
import { CrossIcon } from 'src/assets/icon/CrossIcon';

export interface Props {
  isOpen: boolean;
  handleDialog: VoidFunction;
  children: ReactNode;
  title: string;
  subtitle?: string;
  divider?: boolean;
  component?: ReactNode;
  leftComponent?: ReactNode;
  stickyComponent?: ReactNode;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}

function DialogSimple({
  isOpen,
  handleDialog,
  children,
  title,
  subtitle,
  divider = true,
  component,
  stickyComponent,
  leftComponent,
  maxWidth = 'md',
}: Props) {
  return (
    <Dialog maxWidth={maxWidth} fullWidth open={isOpen} onClose={handleDialog}>
      <DialogTitle>
        <BoxFlexBC>
          <Stack gap={1} direction="row" alignItems="center">
            {leftComponent}
            <Box>
              <Typography variant="h5">{title}</Typography>
              {subtitle && <Typography variant="body2">{subtitle}</Typography>}
            </Box>
          </Stack>

          <Stack direction="row" alignItems="center" gap={2}>
            {component}
            <IconButton onClick={handleDialog}>
              <CrossIcon />
            </IconButton>
          </Stack>
        </BoxFlexBC>
      </DialogTitle>

      {divider && <Divider sx={{ mb: 2 }} />}
      {children}
      {stickyComponent && (
        <Stack
          pb={1}
          sx={{
            position: 'sticky',
            backgroundColor: 'white',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1,
          }}
        >
          <Divider sx={{ mb: 1 }} />
          <Stack mx={1}>{stickyComponent}</Stack>
        </Stack>
      )}
    </Dialog>
  );
}

export default DialogSimple;
