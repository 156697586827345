interface Props {
  size?: number;
  color?: string;
}

const CircleCheckIcon = ({ size = 20, color = '#00AB55' }: Props) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="10" r="10" fill={color} />
    <path
      d="M15.3333 6L7.99996 13.3333L4.66663 10"
      stroke="white"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CircleCheckIcon;
