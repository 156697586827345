import { Box, Button, SxProps, Typography } from '@mui/material';
import NextLink from 'next/link';
import Iconify from '@components/iconify/Iconify';
import PermissionBasedGuard from 'src/auth/PermissionBasedGuard';
import { DashboardPermissionsType } from 'src/auth/types/permissions';
import { DashboardPermissions } from 'src/auth/data/permissions';

function SectionHeader({
  title,
  href,
  btnText = 'New',
  permissions = DashboardPermissions,
  sx,
  children,
}: {
  title: string;
  href?: string;
  btnText?: string;
  permissions?: DashboardPermissionsType[];
  sx?: SxProps;
  children?: React.ReactNode;
}) {
  return (
    <Box sx={sx} mb={3}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography sx={{ fontSize: '1.75rem', fontWeight: '700' }}>{title}</Typography>
        </Box>
        {children}
        <PermissionBasedGuard permissions={permissions}>
          {href && (
            <Box sx={{ flexShrink: 0 }}>
              <Button
                LinkComponent={NextLink}
                href={href}
                variant="contained"
                size="large"
                sx={{
                  fontSize: '1rem',
                  fontWeight: '600',
                  borderRadius: '5px',
                  boxShadow: 'none',
                }}
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                {btnText}
              </Button>
            </Box>
          )}
        </PermissionBasedGuard>
      </Box>
    </Box>
  );
}

export default SectionHeader;
