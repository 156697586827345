import { AxiosInstance } from 'axios';
import {
  setMediaLibraryFiles,
  setPagination,
  hasError,
  startLoading,
} from '@redux/reducers/media-library';
import { AppDispatch, RootState } from '@redux/store';
import { MediaLibraryFileType } from 'src/@types/v2/media-library';

type GetMediaProps = {
  page?: number;
  limit?: number;
  merge?: boolean;
};

export function getMediaFiles(
  { page = 1, limit = 10, merge = false }: GetMediaProps,
  axiosInstance: AxiosInstance
) {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(startLoading());
      if (page === 1) {
        dispatch(setMediaLibraryFiles([]));
      }
      const { files } = await getState().mediaLibrary;
      const { data } = await axiosInstance.get(
        `/v2/media?sort_by=updated_at-desc&max_size=${limit}&page=${page}`
      );

      const res: MediaLibraryFileType[] = data.data;
      const { meta } = data;

      const newData = merge ? [...files, ...res] : res;

      dispatch(setPagination(meta));
      dispatch(setMediaLibraryFiles(newData));
      return { files: newData, pagination: meta };
    } catch (error: unknown) {
      dispatch(hasError(error));
      return null;
    }
  };
}

export function searchMediaFiles(query: string, axiosInstance: AxiosInstance) {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoading());
      const { data } = await axiosInstance.get(`/v2/media/search?path=${query}`);
      const res: MediaLibraryFileType[] = data.data;
      const { meta } = data;
      dispatch(setMediaLibraryFiles(res));
      dispatch(setPagination(meta));
      return { files: res, pagination: meta };
    } catch (error: unknown) {
      dispatch(hasError(error));
      return null;
    }
  };
}

export function getFileBuffer(filename: string, axiosInstance: AxiosInstance) {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoading());
      const { data } = await axiosInstance.get(`/v2/media/${filename}`);
      const buff = data.data;
      const fileData = filename.split('.');
      const fileExtension = fileData[1];
      const blob = new Blob([new Uint8Array(buff[0].data)], { type: `image/${fileExtension}` });
      const reader = new FileReader();
      await new Promise((resolve, reject) => {
        reader.onload = resolve;
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
      const base64 = reader.result;
      return base64;
    } catch (error: unknown) {
      dispatch(hasError(error));
      return null;
    }
  };
}

export function deleteMediaFile(
  folderPath: string,
  filename: string,
  id: string,
  axiosInstance: AxiosInstance
) {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(startLoading());
      const { files } = await getState().mediaLibrary;
      const path = folderPath ? `${folderPath}/${filename}` : filename;
      const url = `/v2/media/file?path=${path}`;
      await axiosInstance.delete(url);
      const newData = files.filter((file) => file.id !== id);
      dispatch(setMediaLibraryFiles(newData));
    } catch (error: unknown) {
      console.log(error);
      dispatch(hasError(error));
    }
  };
}

export function uploadMediaFile(file: File, axiosInstance: AxiosInstance) {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(startLoading());
      const { files: initialData } = await getState().mediaLibrary;
      const formData = new FormData();
      formData.append('file', file);
      const { data } = await axiosInstance.post(`/v2/media`, formData, {
        headers: {
          'Content-Type': `multipart/form-data`,
        },
      });
      const res: MediaLibraryFileType = data.data;
      const newData = [
        {
          id: res.id,
          url: res.url,
          filename: res.filename,
          type: res.type,
          updated_at: new Date().toISOString(),
        },
        ...initialData,
      ];

      dispatch(setMediaLibraryFiles(newData));
      return res;
    } catch (error: unknown) {
      console.log(error);
      dispatch(hasError(error));
      throw error;
    }
  };
}
