import React from 'react';

interface Props {
  color?: string;
  size?: number;
}

function InventoryIcon({ color = '#565B60', size = 24 }: Props) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icons8-inventory 1">
        <path
          id="Vector"
          d="M19.813 6.37725L12.407 3.08625C12.148 2.97125 11.853 2.97125 11.595 3.08625L4.188 6.37825C3.465 6.69925 3 7.41525 3 8.20525V19.0002C3 20.1052 3.895 21.0002 5 21.0002H19C20.105 21.0002 21 20.1052 21 19.0002V8.20525C21 7.41425 20.535 6.69825 19.813 6.37725ZM16 19.0002H14C13.448 19.0002 13 18.5522 13 18.0002V16.0002C13 15.4482 13.448 15.0002 14 15.0002H16C16.552 15.0002 17 15.4482 17 16.0002V18.0002C17 18.5522 16.552 19.0002 16 19.0002ZM13 13.0002H11C10.448 13.0002 10 12.5522 10 12.0002V10.0002C10 9.44825 10.448 9.00025 11 9.00025H13C13.552 9.00025 14 9.44825 14 10.0002V12.0002C14 12.5522 13.552 13.0002 13 13.0002ZM11 16.0002V18.0002C11 18.5522 10.552 19.0002 10 19.0002H8C7.448 19.0002 7 18.5522 7 18.0002V16.0002C7 15.4482 7.448 15.0002 8 15.0002H10C10.552 15.0002 11 15.4482 11 16.0002Z"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default InventoryIcon;
