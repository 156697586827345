import { forwardRef } from 'react';
// next
import NextLink from 'next/link';
// @mui
import { Box, ListItemText } from '@mui/material';
// hooks
import { useLocales } from 'src/locales';
// guards
import PermissionBasedGuard from 'src/auth/PermissionBasedGuard';
// type
import { NavItemProps } from '../types';
//
import Iconify from '../../iconify/Iconify';
import { isExternalLink } from '..';
import { ListItemStyle, ListItemTextStyle, ListItemIconStyle, ListItemStyleProps } from './styles';

// ----------------------------------------------------------------------
const ListItem = forwardRef<HTMLDivElement & HTMLAnchorElement, ListItemStyleProps>(
  (props, ref) => (
    <ListItemStyle {...props} ref={ref}>
      {props.children}
    </ListItemStyle>
  )
);

ListItem.displayName = 'ListItem';

export function NavItemRoot({ item, isCollapse, open = false, active, onOpen }: NavItemProps) {
  const { translate } = useLocales();

  const { title, path, icon, info, children, disabled, caption, roles } = item;

  const renderContent = (
    <>
      {icon && <ListItemIconStyle>{icon}</ListItemIconStyle>}
      <ListItemTextStyle
        disableTypography
        primary={`${translate(title)}`}
        // disable underline
        // secondary={
        //   <Tooltip title={`${translate(caption)}` || ''} arrow>
        //     <Typography
        //       noWrap
        //       variant="caption"
        //       component="div"
        //       sx={{ textTransform: 'initial', color: 'text.secondary' }}
        //     >
        //       {/* {`${translate(caption)}`} */}
        //     </Typography>
        //   </Tooltip>
        // }
        isCollapse={isCollapse}
      />
      {!isCollapse && (
        <>
          {info && info}
          {children && <ArrowIcon open={open} />}
        </>
      )}
    </>
  );

  if (children) {
    return (
      <PermissionBasedGuard permissions={item.permissions}>
        <ListItem onClick={onOpen} activeRoot={active} disabled={disabled} roles={roles}>
          {renderContent}
        </ListItem>
      </PermissionBasedGuard>
    );
  }

  return isExternalLink(path) ? (
    <PermissionBasedGuard permissions={item.permissions}>
      <ListItem
        component={NextLink}
        href={path}
        target="_blank"
        rel="noopener"
        disabled={disabled}
        roles={roles}
      >
        {renderContent}
      </ListItem>
    </PermissionBasedGuard>
  ) : (
    <PermissionBasedGuard permissions={item.permissions}>
      <ListItem
        component={NextLink}
        href={path}
        activeRoot={active}
        disabled={disabled}
        roles={roles}
      >
        {renderContent}
      </ListItem>
    </PermissionBasedGuard>
  );
}

// ----------------------------------------------------------------------

type NavItemSubProps = Omit<NavItemProps, 'isCollapse'>;

export function NavItemSub({ item, open = false, active = false, onOpen }: NavItemSubProps) {
  const { translate } = useLocales();

  const { title, path, info, children, permissions, disabled, caption, roles } = item;

  const renderContent = (
    <>
      <DotIcon active={active} />
      <ListItemText
        disableTypography
        primary={`${translate(title)}`}
        // secondary={
        //   <Tooltip title={`${translate(caption)}` || ''} arrow>
        //     <Typography
        //       noWrap
        //       variant="caption"
        //       component="div"
        //       sx={{ textTransform: 'initial', color: 'text.secondary' }}
        //     >
        //       {`${translate(caption)}`}
        //     </Typography>
        //   </Tooltip>
        // }
      />
      {info && info}
      {children && <ArrowIcon open={open} />}
    </>
  );

  if (children) {
    // This item handle items inside dropdown
    return (
      <PermissionBasedGuard permissions={permissions}>
        <ListItem onClick={onOpen} activeSub={active} subItem disabled={disabled} roles={roles}>
          {renderContent}
        </ListItem>
      </PermissionBasedGuard>
    );
  }

  // This item handle items inside dropdown
  return isExternalLink(path) ? (
    <PermissionBasedGuard permissions={permissions}>
      <ListItem
        component={NextLink}
        href={path}
        target="_blank"
        rel="noopener"
        subItem
        disabled={disabled}
        roles={roles}
      >
        {renderContent}
      </ListItem>
    </PermissionBasedGuard>
  ) : (
    <PermissionBasedGuard permissions={permissions}>
      <ListItem
        component={NextLink}
        href={path}
        activeSub={active}
        subItem
        disabled={disabled}
        roles={roles}
      >
        {renderContent}
      </ListItem>
    </PermissionBasedGuard>
  );
}

// ----------------------------------------------------------------------

type DotIconProps = {
  active: boolean;
};

export function DotIcon({ active }: DotIconProps) {
  return (
    <ListItemIconStyle>
      <Box
        component="span"
        sx={{
          width: 4,
          height: 4,
          borderRadius: '50%',
          bgcolor: 'text.disabled',
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shorter,
            }),
          ...(active && {
            transform: 'scale(2)',
            bgcolor: 'primary.main',
          }),
        }}
      />
    </ListItemIconStyle>
  );
}

// ----------------------------------------------------------------------

type ArrowIconProps = {
  open: boolean;
};

export function ArrowIcon({ open }: ArrowIconProps) {
  return (
    <Iconify
      icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
      sx={{ width: 16, height: 16, ml: 1 }}
    />
  );
}
