// @mui

import { Box, IconButton } from '@mui/material';
// components
import { Icon } from '@iconify/react';
import { useRouter } from 'next/router';
import HeaderBreadcrumbs from '@components/breadcrumbs/HeaderBreadcrumbs';
import { BoxFlexSS } from 'src/styles';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

type Props = {
  children?: React.ReactNode;
  links: LinkType[];
  heading: string;
};

type LinkType = {
  name: string;
  href?: string;
};

export default function HeaderGoBack({ children, links, heading }: Props) {
  const router = useRouter();

  const handleBack = () => {
    router.back();
  };

  return (
    <>
      <BoxFlexSS>
        <IconButton
          onClick={handleBack}
          size="small"
          sx={{ border: 1, borderColor: '#E5E7E9', mr: 2 }}
        >
          <Icon icon="eva:chevron-left-fill" color="gray" width={20} height={20} />
        </IconButton>
        <HeaderBreadcrumbs
          mb={0}
          heading={heading}
          headingVariant="h5"
          gutterBottom={false}
          links={links}
        />
      </BoxFlexSS>

      <Box sx={{ flexGrow: 1 }} />
      {children}
    </>
  );
}
