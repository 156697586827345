interface Props {
  size?: number;
  color?: string;
}

export const CashIcon = ({ size = 24, color = '#637381' }: Props) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 6C21 5.44772 20.5523 5 20 5H7C6.44772 5 6 5.44772 6 6V14C6 14.5523 6.44772 15 7 15H20C20.5523 15 21 14.5523 21 14V6Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 9H4C3.44772 9 3 9.44772 3 10V18C3 18.5523 3.44772 19 4 19H17C17.5523 19 18 18.5523 18 18V15"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="13.5"
      cy="10"
      r="1.5"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
