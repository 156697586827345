import React from 'react';
import { DineInIcon, PickUpIcon, DeliveryIcon } from 'src/assets/icon/order-method';
import { CashIcon } from 'src/assets/icon/CashIcon';
import { OrderMethodType } from 'src/@types/v2/orders/list';
import { WoltIcon } from 'src/assets/icon/WoltIcon';
import { BoxFlexCC } from '..';

interface IconProps {
  size: number;
  color: string;
}

const icons: Record<OrderMethodType, React.FC<IconProps>> = {
  shipping: ({ size, color }) => <DeliveryIcon size={size} color={color} />,
  delivery: ({ size, color }) => <DeliveryIcon size={size} color={color} />,
  pickup: ({ size, color }) => <PickUpIcon size={size} color={color} />,
  'dine-in': ({ size, color }) => <DineInIcon size={size} color={color} />,
  wolt: ({ size, color }) => <WoltIcon size={size} color={color} />,
  pending: ({ size, color }) => <CashIcon size={size} color={color} />,
  instant: ({ size, color }) => <CashIcon size={size} color={color} />,
};

interface Props {
  orderMethod: OrderMethodType;
  color?: string;
  size?: number;
}

const OrderMethodIcon = ({ orderMethod, color = '#414A53', size = 20 }: Props) => {
  const IconComponent = icons[orderMethod];
  if (!IconComponent) {
    return null;
  }
  return (
    <BoxFlexCC>
      <IconComponent size={size} color={color} />
    </BoxFlexCC>
  );
};

export default OrderMethodIcon;
