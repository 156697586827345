import { AxiosInstance } from 'axios';
import { AppDispatch } from '@redux/store';
import { CreateOrderFormProps } from 'src/validations/orders/create-order';
import { convertCartItems } from '@utils/orders';
import { SnackbarType } from 'src/@types/v2/snackbar';
import { openErrorModal, openSuccessModal } from '@redux/reducers/orders/dialog/success_modal';
import { showSnackbar } from '@utils/snackbar';
import { reset } from '@redux/reducers/orders/cart';

interface Props {
  payload: CreateOrderFormProps;
  axiosInstance: AxiosInstance;
  enqueueSnackbar: SnackbarType;
}

export const createOrder =
  ({ payload, enqueueSnackbar, axiosInstance }: Props) =>
  async (dispatch: AppDispatch) => {
    try {
      const {
        additional_cost,
        billing,
        customer_id,
        delivery_details,
        items,
        order_note,
        payment_id,
        pickup_details,
        order_type,
        order_status,
        is_paid,
        coupon,
        customer_note,
        scheduled_data,
        custom_discount,
        prioritized: prioritizedCart,
      } = payload;

      const bodyReq = {
        customer_id,
        coupon_code: coupon ? coupon.code : null,
        order_note,
        order_method: order_type,
        payment_id,
        order_status,
        branch: order_type === 'pickup' ? pickup_details.branch_id : delivery_details.branch?._id,
        currency: 'isk',
        shipping: {
          address: delivery_details.address || '',
          postcode: delivery_details.postcode || '',
          name: billing?.name || '',
          address_detail: delivery_details.detail_address || '',
          shipping_note: delivery_details.note || '',
        },
        schedule_date: scheduled_data?.date ? scheduled_data?.date.toISOString() : undefined,
        billing,
        additional_cost,
        items: convertCartItems(items),
        is_paid,
        customer_note,
        prioritized: prioritizedCart,
        platform: 'dashboard',
        source_name: 'dashboard',
        custom_discount: custom_discount.active ? custom_discount : null,
      };

      const { data } = await axiosInstance.post(`/v2/orders`, bodyReq);
      showSnackbar({
        message: 'Order successfully created',
        variant: 'success',
        enqueueSnackbar,
      });
      dispatch(reset());
      dispatch(openSuccessModal(data.data));
    } catch (err: unknown) {
      console.log(err);
      showSnackbar({
        message: 'Failed to create order. If the error persists, please contact support.',
        variant: 'error',
        enqueueSnackbar,
      });
      dispatch(openErrorModal('Error while create order'));
    }
  };
