function InstantIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.64844 12C5.34334 8.57664 8.36997 6 11.9984 6C16.1405 6 19.4984 9.35786 19.4984 13.5C19.4984 17.6421 16.1405 21 11.9984 21C10.31 21 8.7519 20.4421 7.49841 19.5005"
        stroke="#414A53"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 10V13"
        stroke="#414A53"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9 3H15"
        stroke="#414A53"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18 8L20 6"
        stroke="#414A53"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6 8L4 6"
        stroke="#414A53"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3 12.5H7.5M4 16H9M5 19.5H7.5"
        stroke="#414A53"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  );
}

export default InstantIcon;
