// @mui
import { styled } from '@mui/material/styles';
import { Box, Card, Stack, Link, Container, Typography } from '@mui/material';
// hooks
import Lottie from 'lottie-react';
import LoginFormPropel from '@sections/auth/login/LoginFormPropel';
import useResponsive from '../../hooks/useResponsive';
// guards
import GuestGuard from '../../auth/GuestGuard';
// components
import Page from '../../components/page/Page';
// sections
// Lotties
import logo from '../../lotties/login_logo.json';

// ----------------------------------------------------------------------

export default function Login() {
  const mdUp = useResponsive('up', 'md');

  return (
    <GuestGuard>
      <Page title="Login - Upsell">
        <RootStyle>
          {mdUp && (
            <SectionStyle>
              <Stack sx={{ px: 5, mb: 7 }}>
                <Typography variant="h3">Serve better,</Typography>
                <Typography variant="h3">Serve more.</Typography>
                <Typography variant="caption" color="gray">
                  For restaurant that love to serve their customers personal and digital
                </Typography>
              </Stack>
              <img
                style={{ height: 400 }}
                src="/assets/illustrations/illustration_login_dashboard.svg"
                alt="login"
              />
              <Box
                component="img"
                src="/assets/illustrations/login_pizza2.svg"
                sx={{ width: 300, height: 200, zIndex: 2, position: 'absolute', bottom: 0 }}
              />
              <Box
                component="img"
                src="/assets/illustrations/login_pizza1.svg"
                sx={{
                  width: 520,
                  height: 320,
                  zIndex: 2,
                  position: 'absolute',
                  top: ['30%'],
                  right: ['-15%'],
                }}
              />
            </SectionStyle>
          )}

          <Container maxWidth="sm">
            <ContentStyle>
              <Stack direction="column" alignItems="center" sx={{ mb: 1 }}>
                <Stack
                  sx={{
                    width: '100%',
                    maxWidth: '300px',
                  }}
                >
                  <Lottie loop={false} autoplay animationData={logo} height={70} />
                </Stack>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="h4" gutterBottom>
                    Hi, welcome back!
                  </Typography>
                </Box>
              </Stack>

              {/* <LoginForm /> */}
              <LoginFormPropel />

              <Typography variant="body2" align="center" sx={{ color: 'text.secondary', mt: 3 }}>
                By signing in you are agreeing to our &nbsp;
                <Link underline="always" color="text.primary" href="#">
                  Terms of Service
                </Link>
                &nbsp;and&nbsp;
                <Link underline="always" color="text.primary" href="#">
                  Privacy Policy
                </Link>
                .
              </Typography>
            </ContentStyle>
          </Container>
        </RootStyle>
      </Page>
    </GuestGuard>
  );
}

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxHeight: 780,
  maxWidth: 664,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));
