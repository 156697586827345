import { OrderType } from 'src/@types/v2/orders/list';

export const getQuantity = (items: any) => {
  let quantity = 0;
  items?.forEach((item: any) => {
    quantity += item.quantity;
  });
  return quantity;
};

export const calculatePriceBeforeDiscount = (order: OrderType) =>
  order.items?.reduce((prev, { total, discount_total = 0 }) => total + discount_total + prev, 0);
