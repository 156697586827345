import { CircularProgress, Collapse, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { OrderType } from 'src/@types/v2/orders/list';
import { CheckIcon } from 'src/assets/icon/check';

type Props = {
  type: 'idle' | 'success' | 'warning' | 'error' | 'pending' | 'cancelling' | 'cancelled';
  show: boolean;
  close?: () => void;
  order?: OrderType;
  error?: any;
};

const displayError = (error: any) => {
  if (!error) return '';
  if (typeof error === 'string') return error;
  const errorObj = error.error;

  if (!errorObj) return '';

  if (errorObj.detail) return errorObj.detail.message;

  if (errorObj.details) return errorObj.details;

  return '';
};

function WoltAlert({ type, show, close, order, error }: Props) {
  useEffect(() => {
    if (type === 'error' || type === 'pending') {
      const timeout = setTimeout(() => {
        if (close) {
          close();
        }
      }, 5000);
      return () => clearTimeout(timeout);
    }

    return () => {};
  }, [type]);

  if (
    order &&
    order.data &&
    order.data.wolt_drive_order_id &&
    order.data.wolt_status !== 'order.cancelled'
  )
    return (
      <Stack
        sx={{
          width: '100%',
          bgcolor: getWebhookAlertColor(order.data.wolt_status),
          color: '#fff',
        }}
      >
        <Stack
          direction="row"
          width="100%"
          alignItems="center"
          justifyContent="space-between"
          spacing={1}
          p={1.5}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            {getWebhookAlertMessage(order.data.wolt_status)}
          </Stack>
        </Stack>
      </Stack>
    );

  return (
    <Collapse
      in={show}
      sx={{
        width: '100%',
        bgcolor: type === 'error' ? '#FF4842' : '#00C2E8',
        color: '#fff',
      }}
      orientation="vertical"
    >
      <Stack
        direction="row"
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        spacing={1}
        p={1.5}
      >
        {getAlertMessage(type)}
        {error && (
          <Typography color="#fff" variant="body2">
            {displayError(error)}
          </Typography>
        )}
        {type === 'pending' && <CircularProgress size={16} color="inherit" />}
      </Stack>
    </Collapse>
  );
}

const getAlertMessage = (type: Props['type']) => {
  switch (type) {
    case 'success':
      return (
        <Stack direction="row" alignItems="center" spacing={1}>
          <CheckIcon />
          <Typography color="#fff" variant="body2">
            <strong>Accepted!</strong> Wolt driver will pick up this order
          </Typography>
        </Stack>
      );
    case 'pending':
      return (
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography color="#fff" variant="body2">
            Waiting confirmation from Wolt driver...
          </Typography>
        </Stack>
      );
    case 'error':
      return (
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography color="#fff" variant="body2">
            Opps Wolt driver currently unavailable for this delivery
          </Typography>
        </Stack>
      );
    default:
      return '';
  }
};

export const getWebhookAlertColor = (status?: string) => {
  switch (status) {
    case 'order.received':
    case 'order.picked_up':
      return '#00C2E8';
    case 'order.delivered':
      return '#00AB55';
    case 'order.rejected':
    case 'order.customer_no_show':
      return '#FF4842';
    default:
      return '';
  }
};

export const getWebhookAlertMessage = (status?: string) => {
  switch (status) {
    case 'order.received':
    case 'order.picked_up':
      return (
        <Stack direction="row" alignItems="center" spacing={1}>
          <CheckIcon />
          <Typography color="#fff" variant="body2">
            <strong>Accepted!</strong> Wolt driver will pick up this order
          </Typography>
        </Stack>
      );
    case 'order.delivered':
      return (
        <Stack direction="row" alignItems="center" spacing={1}>
          <CheckIcon />
          <Typography color="#fff" variant="body2">
            <strong>Delivered!</strong> Wolt driver has delivered this order
          </Typography>
        </Stack>
      );
    case 'order.customer_no_show':
      return (
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography color="#fff" variant="body2">
            Delivery could not be made. Customer not reached - Wolt drive
          </Typography>
        </Stack>
      );
    case 'order.rejected':
      return (
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography color="#fff" variant="body2">
            Opps Wolt driver currently unavailable for this delivery
          </Typography>
        </Stack>
      );
    default:
      return null;
  }
};

export default WoltAlert;
