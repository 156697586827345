const getNextStep = (orderStatus: string, orderMethod: string): string => {
  switch (orderStatus) {
    case 'order_received':
      return 'processing';
    case 'waiting_payment':
      return 'processing';
    case 'processing':
      switch (orderMethod) {
        case 'delivery':
          return 'ready_to_ship';
        case 'pickup':
          return 'ready_to_pickup';
        default:
          break;
      }
      break;
    case 'ready_to_ship':
      return 'on_delivery';
    case 'ready_to_pickup':
    case 'delivered':
    case 'on_delivery':
      return 'completed';
    default:
      break;
  }
  throw new Error(`Invalid input: orderStatus=${orderStatus}, orderMethod=${orderMethod}`);
};

export default getNextStep;
