interface Props {
  size?: number;
  color?: string;
}

export const MailIcon = ({ size = 20, color = '#637381' }: Props) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.33366 3.33398H16.667C17.5837 3.33398 18.3337 4.08398 18.3337 5.00065V15.0007C18.3337 15.9173 17.5837 16.6673 16.667 16.6673H3.33366C2.41699 16.6673 1.66699 15.9173 1.66699 15.0007V5.00065C1.66699 4.08398 2.41699 3.33398 3.33366 3.33398Z"
      stroke={color}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.3337 5L10.0003 10.8333L1.66699 5"
      stroke={color}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
