import { SingleChip } from '@components/chip';

interface Props {
  datePaid: Date | null;
  fullWidth?: boolean;
  textAlign?: 'left' | 'right' | 'center';
}
const PaymentStatusChip = ({ datePaid, fullWidth = false, textAlign = 'center' }: Props) => (
  <SingleChip
    fullWidth={fullWidth}
    textAlign={textAlign}
    label={datePaid ? 'Paid' : 'Unpaid'}
    bgColor={datePaid ? '#E1F1FF' : '#FFF9DA'}
    textColor={datePaid ? '#1890FF' : '#E4AC04'}
  />
);

export default PaymentStatusChip;
