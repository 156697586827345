type Homoglyphs = {
  [key: string]: RegExp;
};

const HOMOGLYPHS: Homoglyphs = {
  a: /[àáâãäåāăąǎȁȃȧ]/gi,
  b: /[bƀƃɓ]/gi,
  c: /[cçćĉċč]/gi,
  d: /[dďđɖɗ]/gi,
  e: /[èéêëēĕėęěɛɘə]/gi,
  f: /[fƒ]/gi,
  g: /[gĝğġģǥɠ]/gi,
  h: /[hĥȟħɦ]/gi,
  i: /[iìíîïĩīĭįıɪí]/gi,
  j: /[jĵɉ]/gi,
  k: /[kķĸƙ]/gi,
  l: /[lĺļľŀłɫɬ]/gi,
  m: /[mɱ]/gi,
  n: /[nñńņňŉŋɲɳ]/gi,
  o: /[oòóôõöøōŏőơɵɔ]/gi,
  p: /[pƥ]/gi,
  q: /[qʠ]/gi,
  r: /[rŕŗřɍɹɾ]/gi,
  s: /[sśŝşšșʂ]/gi,
  t: /[tţťŧƫƭʈ]/gi,
  u: /[uùúûüũūŭůűųưú]/gi,
  v: /[vʋ]/gi,
  w: /[wŵ]/gi,
  x: /[xẋ]/gi,
  y: /[yýÿŷɏ]/gi,
  z: /[zźżžƶ]/gi,
  ae: /[æǣ]/gi,
  th: /[þ]/gi,
  dh: /[ð]/gi,
  oe: /[œ]/gi,
};

// Pre-compile the regular expressions
const patterns = Object.values(HOMOGLYPHS);

/**
 * Replaces homoglyphs in a string with their corresponding ASCII characters.
 * Currently focused on Icelandic characters.
 *
 * @param str - The string to convert
 * @returns The converted string
 */
const latinize = (str: string): string => {
  for (let i = 0; i < patterns.length; i += 1) {
    str = str.replace(patterns[i], Object.keys(HOMOGLYPHS)[i]);
  }
  return str;
};

export default latinize;
