import { CartItemOrder, OutputSide } from 'src/validations/orders/create-order';
import { convertVariations } from '@utils/variation';
import { determineFreeExtras } from './calculatePrice';

const formatExtras = (item: OutputSide) => {
  if (!item.variables.length) {
    return item.extras.map((extra) => ({
      extras_id: extra._id,
      items: extra.items.map((curItem) => ({
        doubled: curItem.doubled,
        extras_item_id: curItem._id,
        free_extras: 0,
      })),
    }));
  }

  const attributes = item.variables.map((variable) => variable.attribute);

  const freeExtras = determineFreeExtras(item);

  const formattedExtras = item.extras.map((extra) => {
    const productExtra = item._data.extras.find((productExt: any) => productExt._id === extra._id);
    return {
      extras_id: extra._id,
      items: extra.items.map((curItem: any) => {
        const productExtraItem = productExtra.items.find(
          (productExtItem: any) => productExtItem._id === curItem._id
        );

        const isFree = freeExtras.includes(curItem._id);
        const isFreeDoubled = freeExtras.filter((id) => id === curItem._id).length === 2;

        const getFreeExtras = () => {
          if (isFreeDoubled) {
            return 2;
          }
          if (isFree) {
            return 1;
          }
          return 0;
        };

        if (!productExtraItem.variants || !productExtraItem.variants.length) {
          return {
            extras_variant_item_id: null,
            doubled: curItem.doubled,
            extras_item_id: curItem._id,
            free_extras: getFreeExtras(),
            quantity: curItem.quantity,
          };
        }

        const variant_id = productExtraItem.variants.find(
          // (varItem: any) => varItem.variant_item._id === attribute
          (varItem: any) => attributes.includes(varItem.variant_item._id)
        ).variant_item._id;

        return {
          extras_variant_item_id: variant_id,
          doubled: curItem.doubled,
          extras_item_id: curItem._id,
          free_extras: getFreeExtras(),
          quantity: curItem.quantity,
        };
      }),
    };
  });

  return formattedExtras;
};

const convertCartItems = (items: CartItemOrder[]) => {
  const formatted = items.map((item) => {
    if (item.type === 'split') {
      // handle split product
      const merged = [item.output.left, item.output.right];
      const formatSplitProduct = merged.map((splitItem: OutputSide) => ({
        product_id: splitItem._data._id,
        product_variation: convertVariations.toVariant(splitItem.variables, splitItem._data)?._id,
        quantity: 1,
        item_note: '',
        extras: formatExtras(splitItem),
      }));

      return {
        custom_price: item.custom_price ? parseInt(item.price, 10) : undefined,
        quantity: item.quantity,
        item_note: '',
        type: 'split_product',
        split_items: formatSplitProduct,
      };
    }
    if (item.output._data?.deals_type) {
      const formatMenus =
        item.output.deals &&
        item.output.deals.map((offerItem: any) => {
          if (offerItem.split_mode) {
            const formatExtra = (offerItemSide: any) =>
              offerItem.extra_data && offerItem._data.extras && offerItem._data.extras.length > 0
                ? formatExtras(offerItemSide)
                : [];

            const splitItemLeft = {
              menu_item_id: offerItem.menu_item_id,
              product_id: offerItem.extra_data.left._data._id,
              product_variation: convertVariations.toVariant(
                offerItem.extra_data.left.variables,
                offerItem.extra_data.left._data
              )?._id,
              quantity: 1,
              extras: formatExtra(offerItem.extra_data.left),
            };

            const splitItemRight = {
              menu_item_id: offerItem.menu_item_id,
              product_id: offerItem.extra_data.right._data._id,
              product_variation: convertVariations.toVariant(
                offerItem.extra_data.right.variables,
                offerItem.extra_data.right._data
              )?._id,
              quantity: 1,
              extras: formatExtra(offerItem.extra_data.right),
            };

            const formatMenuItems = {
              menu_item_id: offerItem.menu_item_id,
              quantity: 1,
              type: 'split_product',
              split_items: [splitItemLeft, splitItemRight],
            };

            return {
              menu_id: offerItem.menu_id,
              menu_items: [formatMenuItems],
            };
          }
          const formattedExtras =
            offerItem.extra_data && offerItem._data.extras && offerItem._data.extras.length > 0
              ? formatExtras(offerItem.extra_data.left)
              : [];

          const formatMenuItems = {
            menu_item_id: offerItem.menu_item_id,
            product_id: offerItem._data._id,
            product_variation:
              offerItem.extra_data &&
              convertVariations.toVariant(offerItem.extra_data.left.variables, offerItem._data)
                ?._id,
            extras: formattedExtras,
            quantity: 1,
          };

          return {
            menu_id: offerItem.menu_id,
            menu_items: [formatMenuItems],
          };
        });

      return {
        custom_price: item.custom_price ? parseInt(item.price, 10) : undefined,
        deals_id: item.output._data._id,
        quantity: item.quantity,
        item_note: '',
        menus: formatMenus,
      };
    }
    if (item.type === 'simple_extras') {
      // handle simple, variation, and variation with extas product
      const formattedExtras =
        item.output._data.extras && item.output._data.extras.length > 0
          ? formatExtras(item.output)
          : [];

      return {
        custom_price: item.custom_price ? parseInt(item.price, 10) : undefined,
        product_id: item.output._id,
        quantity: item.quantity,
        item_note: '',
        extras: formattedExtras,
      };
    }
    // handle simple, variation, and variation with extas product
    const formattedExtras =
      item.output._data.extras && item.output._data.extras.length > 0
        ? formatExtras(item.output)
        : [];

    return {
      custom_price: item.custom_price ? parseInt(item.price, 10) : undefined,
      product_id: item.output._id,
      product_variation: convertVariations.toVariant(item.output.variables, item.output._data)?._id,
      quantity: item.quantity,
      item_note: '',
      extras: formattedExtras,
    };
  });

  return formatted;
};

export default convertCartItems;
