import { AxiosInstance } from 'axios';
import {
  setAdminBranches,
  hasError,
  startLoading,
  setFullBranches,
  setCurrentBranch,
  setCurrentBranchSetting,
} from '@redux/reducers/settings/branches';
import { BranchType } from 'src/@types/v2/settings/branch';
import { AppDispatch, RootState } from '@redux/store';
import { AdminRolesType } from 'src/auth/types/roles';
import { FULL_ACCESS_ROLES } from 'src/auth/data/roles';
import { sanitizeObject } from '@utils/object-helper';
import { showSnackbar } from '@utils/snackbar';
import { SnackbarType } from 'src/@types/v2/snackbar';
import { uploadBlobURLToLibrary } from '@utils/media-library';

export function getAdminBranches(axiosInstance: AxiosInstance) {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoading());
      const { data } = await axiosInstance.get('/v2/branches');
      dispatch(setAdminBranches(data.data));
      return data.data as BranchType[];
    } catch (error) {
      dispatch(hasError(error));
      return [];
    }
  };
}

export function getFullBranches(adminRole: AdminRolesType, axiosInstance: AxiosInstance) {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(startLoading());
      const { adminBranches } = getState().branches;
      const { data } = await axiosInstance.get(`/v2/branches?full=true`);
      const branches: BranchType[] = data.data;
      let selectedBranches = [];
      if (FULL_ACCESS_ROLES.includes(adminRole)) {
        selectedBranches = branches;
      } else {
        selectedBranches = adminBranches;
      }

      const filteredData = selectedBranches.filter((b) => b.environment !== 'development');

      dispatch(setFullBranches(filteredData));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getBranchById(branchId: string, axiosInstance: AxiosInstance) {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoading());
      const { data } = await axiosInstance.get(`/v2/branches/${branchId}`);
      dispatch(setCurrentBranch(data.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

interface UpdateBranchSettingProps {
  payload: {
    qr_ordering?: {
      is_accepting_qr?: boolean;
      is_done_setup?: boolean;
      place_order_instruction?: string;
      get_order_instruction?: string;
      card_message?: string;
      color?: {
        background: string;
        title: string;
        paragraph: string;
        qr_background: string;
      };
      logo?: {
        logo_url: string;
        qr_icon_url: string;
      };
      qr_type?: string;
    };
  };
  branchId: string;
  axiosInstance: AxiosInstance;
  enqueueSnackbar?: SnackbarType;
  redirect?: () => void;
  message?: string;
}

export function updateBranchSetting({
  branchId,
  payload,
  axiosInstance,
  enqueueSnackbar,
  redirect,
  message = 'Branch settings updated successfully',
}: UpdateBranchSettingProps) {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoading());
      const sanitizedData = sanitizeObject(payload);
      const logoUploadPromise = sanitizedData.qr_ordering?.logo?.logo_url
        ? uploadBlobURLToLibrary({
            blobURL: sanitizedData.qr_ordering.logo.logo_url,
            axiosInstance,
          })
        : Promise.resolve(null);

      const qrIconUploadPromise = sanitizedData.qr_ordering?.logo?.qr_icon_url
        ? uploadBlobURLToLibrary({
            blobURL: sanitizedData.qr_ordering.logo.qr_icon_url,
            axiosInstance,
          })
        : Promise.resolve(null);

      const [logoUrl, qrIconUrl] = await Promise.all([logoUploadPromise, qrIconUploadPromise]);

      if (logoUrl && sanitizedData.qr_ordering?.logo?.logo_url) {
        sanitizedData.qr_ordering.logo.logo_url = logoUrl;
      }

      if (qrIconUrl && sanitizedData.qr_ordering?.logo?.qr_icon_url) {
        sanitizedData.qr_ordering.logo.qr_icon_url = qrIconUrl;
      }

      const { data } = await axiosInstance.put(`/v2/branches/${branchId}/settings`, sanitizedData);
      dispatch(setCurrentBranchSetting(data.data));
      showSnackbar({
        message,
        variant: 'success',
        enqueueSnackbar,
        redirect,
      });
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}
