// MUI Component
import { Stack, Toolbar } from '@mui/material';
// Router
import { useRouter } from 'next/router';
// Redux
import { useTeamManagement } from '@redux/reducers/team-management';
// Utils
import useOffSetTop from '@hooks/useOffSetTop';
import useResponsive from '@hooks/useResponsive';
// Component
import { IconButtonAnimate } from '@components/animate';
import Iconify from '@components/iconify/Iconify';
import Logo from '@components/images/Logo';
import { HeaderGoBack } from '@components/header';
import { HEADER } from 'src/config-global';
import { EditIcon } from 'src/assets/icon/edit';
// Path
import { PATH_DASHBOARD } from '@routes/paths';
import HeaderRootStyle from '../styles';

type Props = {
  onOpenSidebar: VoidFunction;
  isCollapse?: boolean;
  verticalLayout?: boolean;
  headingComponent: React.ReactNode;
};

export default function MemberDetailHeader({
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
  headingComponent,
}: Props) {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
  const isDesktop = useResponsive('up', 'lg');
  const router = useRouter();
  const { member } = useTeamManagement();

  const actionList = [
    { label: 'Edit information', value: 'Edit information', icon: <EditIcon size={24} /> },
    { label: 'Edit permission', value: 'Edit permission', icon: <EditIcon size={24} /> },
    { label: 'Edit access', value: 'Edit access', icon: <EditIcon size={24} /> },
  ];

  const handleSelectAction = (action: string) => {
    if (member) {
      if (action === 'Edit information') {
        router.push(
          PATH_DASHBOARD.teamManagement.membersEditPersonalInformation(
            member.properties.mongoAdminId
          )
        );
      } else if (action === 'Edit permission') {
        router.push(
          PATH_DASHBOARD.teamManagement.membersEditPermission(member.properties.mongoAdminId)
        );
      } else if (action === 'Edit access') {
        router.push(
          PATH_DASHBOARD.teamManagement.membersEditAccess(member.properties.mongoAdminId)
        );
      }
    }
  };

  return (
    <HeaderRootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 5 },
        }}
      >
        {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}
        {!isDesktop && (
          <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}
        <Stack width="100%" direction="row" justifyContent="space-between" alignItems="center">
          <HeaderGoBack
            heading="Member details"
            links={[
              {
                name: 'Team management',
                href: PATH_DASHBOARD.teamManagement.members,
              },
              { name: 'Details' },
            ]}
          />
          {/* <Stack sx={{ color: 'black' }}>
            <DropdownAction
              data={actionList}
              onClickItem={handleSelectAction}
              textButton="Action"
            />
          </Stack> */}
        </Stack>
      </Toolbar>
    </HeaderRootStyle>
  );
}
