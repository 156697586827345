interface Props {
  size?: number;
  color?: string;
}

export const PrinterIcon = ({ size = 20, color = '#637381' }: Props) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 6.50065V1.83398H12V6.50065"
      stroke={color}
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.99967 12.5H2.66634C2.31272 12.5 1.97358 12.3595 1.72353 12.1095C1.47348 11.8594 1.33301 11.5203 1.33301 11.1667V7.83333C1.33301 7.47971 1.47348 7.14057 1.72353 6.89052C1.97358 6.64048 2.31272 6.5 2.66634 6.5H13.333C13.6866 6.5 14.0258 6.64048 14.2758 6.89052C14.5259 7.14057 14.6663 7.47971 14.6663 7.83333V11.1667C14.6663 11.5203 14.5259 11.8594 14.2758 12.1095C14.0258 12.3595 13.6866 12.5 13.333 12.5H11.9997"
      stroke={color}
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 9.83398H4V15.1673H12V9.83398Z"
      stroke={color}
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const AlternativePrinterIcon = ({ size = 20, color = '#637381' }: Props) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 0C4.448 0 4 0.448 4 1V5H3C1.35 5 0 6.35 0 8V12C0 13.105 0.895 14 2 14H4V16C4 17.105 4.895 18 6 18H14C15.105 18 16 17.105 16 16V14H18C19.105 14 20 13.105 20 12V8C20 6.35 18.65 5 17 5H16V1C16 0.448 15.552 0 15 0C14.448 0 14 0.448 14 1V5H6V1C6 0.448 5.552 0 5 0ZM7.87891 0C7.37691 0 7.12547 0.606938 7.48047 0.960938L9.17969 2.66016C9.63269 3.11316 10.3673 3.11316 10.8203 2.66016L12.5215 0.960938C12.8755 0.606938 12.6231 0 12.1211 0H7.87891ZM17 7C17.6 7 18 7.4 18 8C18 8.6 17.6 9 17 9C16.4 9 16 8.6 16 8C16 7.4 16.4 7 17 7ZM6.5 11H13.5C13.776 11 14 11.224 14 11.5V15.5C14 15.776 13.776 16 13.5 16H6.5C6.224 16 6 15.776 6 15.5V11.5C6 11.224 6.224 11 6.5 11Z"
      fill={color}
    />
  </svg>
);

export const RetryPrintIcon = ({ size = 20, color = '#637381' }: Props) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 4.00391V10.0039H7"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.51 14.9985C4.15839 16.8389 5.38734 18.4187 7.01166 19.4999C8.63598 20.5811 10.5677 21.1051 12.5157 20.993C14.4637 20.8808 16.3226 20.1387 17.8121 18.8782C19.3017 17.6178 20.3413 15.9075 20.7742 14.0048C21.2072 12.1022 21.0101 10.1104 20.2126 8.32958C19.4152 6.54873 18.0605 5.07528 16.3528 4.13125C14.6451 3.18722 12.6769 2.82375 10.7447 3.0956C8.81245 3.36745 7.02091 4.2599 5.64 5.63848L1 9.99848"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
