import { ProductImage } from 'src/@types/v2/products';

const getPrimaryProductImage = (images: ProductImage[]) => {
  if (!images || images.length === 0) {
    return '';
  }

  const primary = images.find((image: any) => image.primary);

  if (primary) {
    return primary.url;
  }

  return images[0].url;
};

export default getPrimaryProductImage;
