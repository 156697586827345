import { OrderStatusCountType } from 'src/@types/v2/orders/list';

const getTotalCurrentOrdersByStatus = (
  orderStatus: string,
  orderStatusCount: OrderStatusCountType
) => {
  switch (orderStatus) {
    case 'all':
      return orderStatusCount.all;
    case 'order_received':
      return orderStatusCount.order_received;
    case 'waiting_payment':
      return orderStatusCount.waiting_payment;
    case 'processing':
      return orderStatusCount.processing;
    case 'ready_to_ship':
      return orderStatusCount.ready_to_ship;
    case 'ready_to_pickup':
      return orderStatusCount.ready_to_pickup;
    case 'on_delivery':
      return orderStatusCount.on_delivery;
    case 'completed':
      return orderStatusCount.completed;
    case 'refunded':
      return orderStatusCount.refunded;
    case 'cancelled':
      return orderStatusCount.cancelled;
    default:
      return 0;
  }
};
export default getTotalCurrentOrdersByStatus;
