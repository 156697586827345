import { AxiosInstance } from 'axios';
import { uploadMediaFile } from '@redux/actions/media-library';
import { dispatch } from '@redux/store';
import { MediaLibraryFileType } from 'src/@types/v2/media-library';

// Upload image to media library
interface Props {
  imageFile: File;
  axiosInstance: AxiosInstance;
}

const uploadImageToLibrary = async ({
  imageFile,
  axiosInstance,
}: Props): Promise<MediaLibraryFileType> => {
  const res = await dispatch(uploadMediaFile(imageFile, axiosInstance));
  return res;
};

export default uploadImageToLibrary;
