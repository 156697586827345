import { OrderStatusType } from 'src/@types/v2/orders/list';

const getOrderStatusLabel = (orderStatus: OrderStatusType): string => {
  switch (orderStatus) {
    case 'order_received':
      return 'Order Received';
    case 'processing':
      return 'Preparing';
    case 'waiting_payment':
      return 'Waiting Payment';
    case 'ready_to_pickup':
      return 'Waiting Pick Up';
    case 'ready_to_ship':
      return 'Waiting Deliver';
    case 'on_delivery':
      return 'On Delivery';
    case 'delivered':
      return 'Delivered';
    case 'completed':
      return 'Completed';
    case 'cancelled':
      return 'Cancelled';
    case 'refunded':
      return 'Refunded';
    case 'failed':
      return 'Failed';
    default:
      return 'Unknown';
  }
};

export default getOrderStatusLabel;
