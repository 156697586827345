import { ReactNode } from 'react';
import AuthGuard from '../auth/AuthGuard';
import MainLayout from './main';
import DashboardLayout from './dashboard';
import { DashboardHeaderVariant } from './dashboard/header/types';
import { LayoutFlat, LayoutLogoOnly } from './custom';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
  variant?: 'main' | 'dashboard' | 'logoOnly' | 'flat';
  dashboardHeaderVariant?: DashboardHeaderVariant;
  heading?: ReactNode;
};

export default function Layout({
  variant = 'dashboard',
  children,
  dashboardHeaderVariant = 'main',
  heading: HeadingComponent,
}: Props) {
  if (variant === 'logoOnly') {
    return <LayoutLogoOnly> {children} </LayoutLogoOnly>;
  }

  if (variant === 'main') {
    return <MainLayout>{children}</MainLayout>;
  }

  if (variant === 'flat') {
    // if using this variant don't need container
    return (
      <AuthGuard>
        <LayoutFlat>{children}</LayoutFlat>
      </AuthGuard>
    );
  }

  return (
    <AuthGuard>
      <DashboardLayout dashboardHeaderVariant={dashboardHeaderVariant} heading={HeadingComponent}>
        {children}
      </DashboardLayout>
    </AuthGuard>
  );
}
