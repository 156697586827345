import { useState, ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { HEADER, NAVBAR } from 'src/config-global';
import palette from 'src/theme/palette';
import { useSettingsContext } from '@components/settings';
import { DashboardHeaderVariant, HeaderComponentsType } from '@layouts/dashboard/header/types';
import useResponsive from '../../hooks/useResponsive';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';
import NavbarHorizontal from './navbar/NavbarHorizontal';
import {
  CustomHeader,
  CustomerDetailHeader,
  CompanyDetailHeader,
  GiftcardIssueDetailHeader,
  OrderCreateHeader,
  OrderDetailHeader,
  OrderEditHeader,
  OrderOverviewHeader,
  RoleDetailHeader,
  MemberDetailHeader,
  InventorySettingsHeader,
} from './header/custom';

type Props = {
  children: ReactNode;
  dashboardHeaderVariant?: DashboardHeaderVariant;
  heading?: ReactNode;
};

const headerComponents: HeaderComponentsType = {
  custom: CustomHeader,
  main: DashboardHeader,
  orderDetail: OrderDetailHeader,
  editOrder: OrderEditHeader,
  newOrder: OrderCreateHeader,
  customerDetail: CustomerDetailHeader,
  companyDetail: CompanyDetailHeader,
  roleDetail: RoleDetailHeader,
  memberDetail: MemberDetailHeader,
  issueGiftCardDetail: GiftcardIssueDetailHeader,
  orderOverview: OrderOverviewHeader,
  inventorySettings: InventorySettingsHeader,
};

export default function DashboardLayout({
  children,
  dashboardHeaderVariant,
  heading: HeadingCustomComponent,
}: Props) {
  const { collapseClick, isCollapse } = useCollapseDrawer();

  const { themeLayout, themeMode } = useSettingsContext();

  const isLight = themeMode === 'light';

  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);

  const verticalLayout = themeLayout === 'vertical';
  const Header = headerComponents[dashboardHeaderVariant || 'custom'];

  if (verticalLayout) {
    return (
      <Box sx={{ backgroundColor: isLight ? palette('light').background.other : palette('dark') }}>
        {dashboardHeaderVariant && (
          <Header
            headingComponent={HeadingCustomComponent}
            onOpenSidebar={() => setOpen(true)}
            verticalLayout={verticalLayout}
          />
        )}

        {isDesktop ? (
          <NavbarHorizontal />
        ) : (
          <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        )}

        <Box
          component="main"
          sx={{
            px: { lg: 2 },
            pt: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
            },
            pb: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
            },
          }}
        >
          {children}
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        backgroundColor: isLight ? palette('light').background.other : palette('dark'),
        minHeight: { lg: 1 },
      }}
    >
      {dashboardHeaderVariant && (
        <Header
          headingComponent={HeadingCustomComponent}
          onOpenSidebar={() => setOpen(true)}
          isCollapse={isCollapse}
        />
      )}

      <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

      <MainStyle collapseClick={collapseClick}>{children}</MainStyle>
    </Box>
  );
}

// ----------------------------------------------------------------------

type MainStyleProps = {
  collapseClick: boolean;
};

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})<MainStyleProps>(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

// ----------------------------------------------------------------------
