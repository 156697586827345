import { AxiosInstance } from 'axios';
import { getVariantsSuccess, startLoading, hasError, setSubmitting } from '@redux/reducers/variant';
import { AppDispatch } from '@redux/store';
import { showSnackbar } from '@utils/snackbar';
import { SnackbarType } from 'src/@types/v2/snackbar';
import { VariationType } from 'src/@types/v2/variations';

export function getVariants(axiosInstance: AxiosInstance) {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoading());
      const { data } = await axiosInstance.get(`/v2/variants`);
      dispatch(getVariantsSuccess(data.data));
    } catch (error: unknown) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getVariationsByCategory(id: string, axiosInstance: AxiosInstance) {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoading());
      const { data } = await axiosInstance.get(`/v2/variations?category=${id}`);
      return data.data as VariationType[];
    } catch (error) {
      dispatch(hasError(error));
      return [];
    }
  };
}

// ----------------------------------------------------------------------

export function getVariationsByProduct(id: string, axiosInstance: AxiosInstance) {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoading());
      const { data } = await axiosInstance.get(`/v2/variations?product=${id}`);
      return data.data as VariationType[];
    } catch (error) {
      dispatch(hasError(error));
      return [];
    }
  };
}

// ----------------------------------------------------------------------

export function deleteVariant(
  ids: string[],
  enqueueSnackbar: SnackbarType,
  axiosInstance: AxiosInstance
) {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoading());
      await Promise.all(ids.map((id) => axiosInstance.delete(`/v2/variants/${id}`)));

      showSnackbar({
        message: 'Variant deleted successfully',
        variant: 'success',
        enqueueSnackbar,
      });
      dispatch(getVariants(axiosInstance));
    } catch (error: unknown) {
      showSnackbar({
        message: 'Failed to delete variant. If the error persists, please contact support.',
        variant: 'error',
        enqueueSnackbar,
      });
      dispatch(hasError(error));
    }
  };
}

export function addVariantWithCallback(
  values: Partial<{
    visibility: string;
    name: string;
    options: string[] | null;
    variant_id: string;
  }>,
  axiosInstance: AxiosInstance,
  cb?: (data: any, response: 'success' | 'error') => void
) {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(setSubmitting(true));
      const res = await axiosInstance.post('/v2/variants', values);
      if (cb) {
        cb(res.data.data, 'success');
      }
      dispatch(getVariants(axiosInstance));
      dispatch(setSubmitting(false));
    } catch (error: unknown) {
      if (cb) {
        cb(error, 'error');
      }
      dispatch(hasError(error));
      dispatch(setSubmitting(false));
    }
  };
}

// ----------------------------------------------------------------------

export function addVariant(
  values: Partial<{
    visibility: string;
    name: string;
    options: string[] | null;
    variant_id: string;
  }>,
  enqueueSnackbar: SnackbarType,
  axiosInstance: AxiosInstance,
  redirect?: () => void,
  cb?: (data: any) => void
) {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(setSubmitting(true));
      const res = await axiosInstance.post('/v2/variants', values);
      showSnackbar({
        message: 'Variant created successfully',
        variant: 'success',
        enqueueSnackbar,
        redirect,
      });
      if (cb) {
        cb(res.data.data);
      }
      dispatch(getVariants(axiosInstance));
      dispatch(setSubmitting(false));
    } catch (error: unknown) {
      showSnackbar({
        message: 'Failed to create variant. If the error persists, please contact support.',
        variant: 'error',
        enqueueSnackbar,
      });
      dispatch(hasError(error));
      dispatch(setSubmitting(false));
    }
  };
}

// ----------------------------------------------------------------------
export function updateVariant(
  values: Partial<{
    visibility: string;
    name: string;
    options: any[];
    variant_id: string;
  }>,
  id: string | undefined,
  enqueueSnackbar: SnackbarType,
  redirect: () => void,
  axiosInstance: AxiosInstance
) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    dispatch(setSubmitting(true));

    try {
      await axiosInstance.put(`/v2/variants/${id}`, values);
      showSnackbar({
        message: 'Variant updated successfully',
        variant: 'success',
        enqueueSnackbar,
        redirect,
      });
      dispatch(getVariants(axiosInstance));
      dispatch(setSubmitting(false));
    } catch (error: unknown) {
      showSnackbar({
        message: 'Failed to update variant. If the error persists, please contact support.',
        variant: 'error',
        enqueueSnackbar,
      });
      dispatch(setSubmitting(false));
      dispatch(hasError(error));
    }
  };
}
