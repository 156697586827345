// @mui
import { Box, Toolbar } from '@mui/material';
// components
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { HEADER } from 'src/config-global';
import useOffSetTop from '@hooks/useOffSetTop';
import useResponsive from '@hooks/useResponsive';
import { IconButtonAnimate } from '@components/animate';
import Iconify from '@components/iconify/Iconify';
import Logo from '@components/images/Logo';
import { useBranches } from '@redux/reducers/settings/branches';
import { dispatch } from '@redux/store';
import { getAdminBranches } from '@redux/actions/settings/branches';
import HeaderGoBack from '@components/header/HeaderGoBack';
import { PATH_DASHBOARD } from '@routes/paths';
import DropdownAction from '@components/dropdown/DropdownAction';
import { useAxios } from 'src/auth/AxiosContext';
import HeaderRootStyle from '../styles';

type Props = {
  onOpenSidebar: VoidFunction;
  isCollapse?: boolean;
  verticalLayout?: boolean;
};

export default function OrderOverviewHeader({
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
}: Props) {
  const axiosInstance = useAxios();
  const { adminBranches } = useBranches();
  const router = useRouter();
  const { id } = router.query;

  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    // check if the id exist in branchData
    if (adminBranches.length === 0) {
      dispatch(getAdminBranches(axiosInstance));
    }
  }, [id]);

  const currentBranch = adminBranches.find((branch) => branch._id === id);

  // get label and value and push all location
  const LOCATION_DATA = adminBranches.map((item) => ({
    label: item.location_name,
    value: item._id,
  }));

  const LOCATION_WITHOUT_CURRENT = LOCATION_DATA.filter((item) => item.value !== id);

  const clickSelectBranch = (branchId: string) => {
    router.push(PATH_DASHBOARD.orders.overviewDetails(branchId));
  };

  return (
    <HeaderRootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 5 },
        }}
      >
        {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}
        {!isDesktop && (
          <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}
        <HeaderGoBack
          heading={currentBranch?.location_name || ''}
          links={[
            { name: 'Order Overview', href: PATH_DASHBOARD.orders.overview },
            { name: 'Details' },
          ]}
        />
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ color: 'black' }}>
          <DropdownAction
            data={LOCATION_WITHOUT_CURRENT}
            onClickItem={clickSelectBranch}
            textButton={currentBranch?.location_name || ''}
          />
        </Box>
      </Toolbar>
    </HeaderRootStyle>
  );
}
