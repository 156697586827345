import { Divider, Grid } from '@mui/material';
import React from 'react';
import { OrderType } from 'src/@types/v2/orders/list';
import Scrollbar from '@components/scrollbar/Scrollbar';
import DialogSimple from '@components/dialog/DialogSimple';
import LeftSection from './LeftSection';
import RightSection from './RightSection';

export interface Props {
  isOpen: boolean;
  handleDialog: VoidFunction;
  selectedOrderDetails: OrderType;
}

function TransactionHistoryDialog({ isOpen, handleDialog, selectedOrderDetails }: Props) {
  return (
    <DialogSimple
      maxWidth="lg"
      title="Detail transaction"
      isOpen={isOpen}
      handleDialog={handleDialog}
    >
      <Scrollbar sx={{ maxHeight: 50 * 8 }}>
        <Grid container>
          <Grid item xs={8} sx={{ borderRight: '1px solid #E5E7E9' }}>
            <LeftSection selectedOrderDetails={selectedOrderDetails} />
          </Grid>
          <Grid item xs={4}>
            <RightSection selectedOrderDetails={selectedOrderDetails} />
          </Grid>
        </Grid>
      </Scrollbar>
      <Divider sx={{ mt: 2 }} />
    </DialogSimple>
  );
}

export default TransactionHistoryDialog;
