import { SingleChip } from '@components/chip';
import { getOrderStatusLabel } from '@utils/orders';
import { OrderStatusType } from 'src/@types/v2/orders/list';

interface Props {
  orderStatus: OrderStatusType;
  fullWidth?: boolean;
  textAlign?: 'left' | 'right' | 'center';
}
const OrderStatusChip = ({ orderStatus, fullWidth = false, textAlign = 'center' }: Props) => {
  const getStatusColor = (status: OrderStatusType) => {
    switch (status) {
      case 'order_received':
      case 'processing':
        return { backgroundColor: '#FFF9DA', color: '#E4AC04' };
      case 'waiting_payment':
        return { backgroundColor: '#F6F6F7', color: '#454F5B' };
      case 'ready_to_pickup':
      case 'ready_to_ship':
      case 'on_delivery':
        return { backgroundColor: '#E1F1FF', color: '#1890FF' };
      case 'delivered':
      case 'completed':
        return { backgroundColor: '#EBF8F1', color: '#00AB55' };
      case 'cancelled':
      case 'refunded':
      case 'failed':
        return { backgroundColor: '#FFE7D9', color: '#B72136' };
      default:
        return { backgroundColor: '#F6F6F7', color: '#454F5B' };
    }
  };
  return (
    <SingleChip
      fullWidth={fullWidth}
      textAlign={textAlign}
      label={getOrderStatusLabel(orderStatus)}
      bgColor={getStatusColor(orderStatus).backgroundColor}
      textColor={getStatusColor(orderStatus).color}
    />
  );
};

export default OrderStatusChip;
