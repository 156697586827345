import { DropdownFixDataType } from '@components/dropdown/types';
import { TrashIcon } from 'src/assets/icon/TrashIcon';

export const ACTION_DATA: DropdownFixDataType[] = [
  { value: 'Action', hidden: true }, // for dummy data
  { value: 'Disable gift card', status: 'disabled' },
  { value: 'Enable gift card', status: 'enabled' },
  {
    value: 'Delete gift card',
    status: 'deleted',
    variant: 'error',
    icon: <TrashIcon color="#FF4842" />,
  },
];
