import { InputAdornment, SxProps, TextField, TextFieldProps, Theme } from '@mui/material';
import React from 'react';
import Iconify from '@components/iconify/Iconify';

type Props = {
  filterName?: string;
  placeholder: string;
  onFilterName: (value: string) => void;
  sx?: SxProps<Theme>;
} & TextFieldProps;

function SearchTextField({ filterName, onFilterName, placeholder, sx, ...props }: Props) {
  return (
    <TextField
      sx={{ ...sx }}
      fullWidth
      value={filterName}
      onChange={(event) => onFilterName(event.target.value)}
      placeholder={placeholder}
      {...props}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Iconify
              icon="eva:search-fill"
              sx={{ color: 'text.disabled', width: 20, height: 20 }}
            />
          </InputAdornment>
        ),
      }}
    />
  );
}

export default SearchTextField;
