// @mui
import { styled } from '@mui/material/styles';
import { List, Box, ListSubheader } from '@mui/material';
// hooks
import { useLocales } from 'src/locales';
// type
import PermissionBasedGuard from 'src/auth/PermissionBasedGuard';
import { NavSectionProps } from '../types';
//
import { NavListRoot } from './NavList';
// guards

// ----------------------------------------------------------------------
export const ListSubheaderStyle: any = styled((props) => (
  <ListSubheader disableSticky disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.overline,
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  color: theme.palette.text.secondary,
}));

// ----------------------------------------------------------------------

export default function NavSectionVertical({
  navConfig,
  isCollapse = false,
  ...other
}: NavSectionProps) {
  const { translate } = useLocales();

  return (
    <Box {...other}>
      {navConfig.map((group) => (
        <PermissionBasedGuard permissions={group.permissions} key={group.subheader}>
          <List disablePadding sx={{ px: 2 }}>
            <ListSubheaderStyle
              sx={{
                ...(isCollapse && {
                  opacity: 0,
                }),
              }}
            >
              {translate(group.subheader)}
            </ListSubheaderStyle>

            {group.items.map((list) => (
              <NavListRoot key={list.title + list.path} list={list} isCollapse={isCollapse} />
            ))}
          </List>
        </PermissionBasedGuard>
      ))}
    </Box>
  );
}
