import { AxiosInstance } from 'axios';
import {
  setGiftcardData,
  setGiftcardListData,
  hasError,
  startLoading,
  stopLoading,
} from '@redux/reducers/giftcard';
import { AppDispatch, RootState } from '@redux/store';
import { getGiftcardRequestData } from '@utils/giftcard';
import { showSnackbar } from '@utils/snackbar';
import { SnackbarType } from 'src/@types/v2/snackbar';
import { GiftcardType, IndividualGiftcardType } from 'src/@types/v2/giftcard';
import { NewGiftCardFormProps } from 'src/validations/restaurant/gift-card/new-giftcard';

export function getGiftcards(axiosInstance: AxiosInstance) {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoading());
      const { data } = await axiosInstance.get(`/v2/giftcards`);
      dispatch(setGiftcardListData(data.data));
    } catch (error: any) {
      dispatch(hasError(error));
    }
  };
}

export function getGiftcardByID(id: string, axiosInstance: AxiosInstance) {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoading());
      const { data } = await axiosInstance.get(`/v2/giftcards/${id}`);
      dispatch(setGiftcardData(data.data));
      return data.data;
    } catch (error) {
      dispatch(hasError(error));
      return null;
    }
  };
}

export function issueIndividualGiftcard(
  data: Omit<IndividualGiftcardType, '_id' | 'createdAt'>,
  enqueueSnackbar: SnackbarType,
  redirect: () => void,
  axiosInstance: AxiosInstance
) {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoading());
      await axiosInstance.post(`/v2/giftcards`, data).then(() => {
        dispatch(getGiftcards(axiosInstance));
        showSnackbar({
          message: 'Individual Giftcard issued successfully',
          variant: 'success',
          enqueueSnackbar,
          redirect,
        });
      });
    } catch (error: any) {
      showSnackbar({
        message: error.error.message,
        variant: 'error',
        enqueueSnackbar,
      });
      dispatch(hasError(error));
      dispatch(stopLoading());
    }
  };
}

export function addProductGiftcard(
  values: NewGiftCardFormProps,
  enqueueSnackbar: SnackbarType,
  redirect: () => void,
  axiosInstance: AxiosInstance
) {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (values.individual_giftcard) {
        throw new Error('`individual_giftcard` property must be `null` for ProductGiftcard');
      }

      const bodyData = await getGiftcardRequestData(values, axiosInstance);
      const { data } = await axiosInstance.post('/v2/giftcards', bodyData);
      const { data: initialData } = await getState().giftcards;
      showSnackbar({
        message: 'Product Giftcard added successfully',
        variant: 'success',
        enqueueSnackbar,
        redirect,
      });
      const newData = [...initialData, data.data];
      dispatch(setGiftcardListData(newData));
    } catch (error: any) {
      showSnackbar({
        message:
          'An error occurred while add giftcard. If the error persists, please contact support.',
        variant: 'error',
        enqueueSnackbar,
      });
      dispatch(hasError(error));
      dispatch(stopLoading());
    }
  };
}

export function updateGiftcard(
  values: GiftcardType,
  id: string,
  enqueueSnackbar: SnackbarType,
  axiosInstance: AxiosInstance
) {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const bodyData = await getGiftcardRequestData(values, axiosInstance);
      await axiosInstance.put(`/v2/giftcards/${id}`, bodyData);
      const { data: initialData } = await getState().giftcards;
      showSnackbar({
        message: 'Product Giftcard updated successfully',
        variant: 'success',
        enqueueSnackbar,
      });
      const newData = initialData.map((deal: any) => {
        if (deal._id === id) {
          return {
            ...deal,
            ...bodyData,
          };
        }
        return deal;
      });
      dispatch(setGiftcardListData(newData));
    } catch (error: any) {
      showSnackbar({
        message:
          'An error occurred while update giftcard. If the error persists, please contact support.',
        variant: 'error',
        enqueueSnackbar,
      });
      dispatch(hasError(error));
    }
  };
}

export function disableIndividualGiftcard(
  id: string,
  enqueueSnackbar: SnackbarType,
  axiosInstance: AxiosInstance
) {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoading());
      axiosInstance.put(`/v2/giftcards/disable/${id}`).then(() => {
        dispatch(getGiftcards(axiosInstance));
        dispatch(getGiftcardByID(id, axiosInstance));
        showSnackbar({
          message: 'Gift card successfully disabled',
          variant: 'success',
          enqueueSnackbar,
        });
      });
    } catch (error: any) {
      showSnackbar({
        message:
          'An error occurred while disable giftcard. If the error persists, please contact support.',
        variant: 'error',
        enqueueSnackbar,
      });
      dispatch(hasError(error));
    }
  };
}

export function enableIndividualGiftcard(
  id: string,
  enqueueSnackbar: SnackbarType,
  axiosInstance: AxiosInstance
) {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoading());
      axiosInstance.put(`/v2/giftcards/enable/${id}`).then(() => {
        dispatch(getGiftcards(axiosInstance));
        dispatch(getGiftcardByID(id, axiosInstance));
        showSnackbar({
          message: 'Gift card successfully enabled',
          variant: 'success',
          enqueueSnackbar,
        });
      });
    } catch (error: any) {
      showSnackbar({
        message:
          'An error occurred while enable giftcard. If the error persists, please contact support.',
        variant: 'error',
        enqueueSnackbar,
      });
      dispatch(hasError(error));
    }
  };
}

export function deleteGiftcards(
  ids: string[],
  enqueueSnackbar: SnackbarType,
  axiosInstance: AxiosInstance,
  redirect?: () => void
) {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoading());

      await Promise.all(ids.map((id) => axiosInstance.delete(`/v2/giftcards/${id}`))).then(() => {
        dispatch(getGiftcards(axiosInstance));
        showSnackbar({
          message: 'Gift card has been deleted',
          variant: 'success',
          enqueueSnackbar,
          redirect,
        });
      });
    } catch (error: any) {
      showSnackbar({
        message: 'Failed to delete giftcard. If the error persists, please contact support.',
        variant: 'error',
        enqueueSnackbar,
      });
      dispatch(hasError(error));
    }
  };
}
