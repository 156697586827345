interface Props {
  size?: number;
  color?: string;
}

const DeliveryIcon = ({ size = 24, color = '#637381' }: Props) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.25 9.58398C11.25 10.0442 11.6231 10.4173 12.0833 10.4173C12.5436 10.4173 12.9167 10.0442 12.9167 9.58398H11.25ZM3.44364 13.7883L3.80515 13.0375L3.44364 13.7883ZM3.33333 12.2867V5.00065H1.66667V12.2867H3.33333ZM4.16667 4.16732H10.4167V2.50065H4.16667V4.16732ZM3.08213 14.5392L3.86926 14.9182L4.59228 13.4165L3.80515 13.0375L3.08213 14.5392ZM12.9167 9.58398V5.00065H11.25V9.58398H12.9167ZM10.4167 4.16732C10.8769 4.16732 11.25 4.54041 11.25 5.00065H12.9167C12.9167 3.61994 11.7974 2.50065 10.4167 2.50065V4.16732ZM3.33333 5.00065C3.33333 4.54041 3.70643 4.16732 4.16667 4.16732V2.50065C2.78595 2.50065 1.66667 3.61994 1.66667 5.00065H3.33333ZM1.66667 12.2867C1.66667 13.247 2.21682 14.1225 3.08213 14.5392L3.80515 13.0375C3.51672 12.8986 3.33333 12.6068 3.33333 12.2867H1.66667Z"
      fill={color}
    />
    <path
      d="M17.4999 9.58377H18.3332C18.3332 9.41088 18.2794 9.24227 18.1793 9.10131L17.4999 9.58377ZM12.9165 5.13932H12.0832V6.80599H12.9165V5.13932ZM16.5562 13.7881L16.1947 13.0373L16.5562 13.7881ZM16.1306 14.9179L16.9177 14.5389L16.1947 13.0373L15.4076 13.4163L16.1306 14.9179ZM18.1793 9.10131L16.1135 6.19194L14.7546 7.15686L16.8204 10.0662L18.1793 9.10131ZM18.3332 12.2864V9.58377H16.6665V12.2864H18.3332ZM14.0751 5.13932H12.9165V6.80599H14.0751V5.13932ZM16.1135 6.19194C15.6446 5.53162 14.8849 5.13932 14.0751 5.13932V6.80599C14.345 6.80599 14.5983 6.93675 14.7546 7.15686L16.1135 6.19194ZM16.9177 14.5389C17.783 14.1223 18.3332 13.2468 18.3332 12.2864H16.6665C16.6665 12.6066 16.4831 12.8984 16.1947 13.0373L16.9177 14.5389Z"
      fill={color}
    />
    <path
      d="M13.1404 14.166H6.8584"
      stroke={color}
      strokeWidth="1.66667"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <circle
      cx="14.9997"
      cy="15.0007"
      r="1.66667"
      stroke={color}
      strokeWidth="1.66667"
      strokeLinecap="round"
    />
    <circle
      cx="4.99967"
      cy="15.0007"
      r="1.66667"
      stroke={color}
      strokeWidth="1.66667"
      strokeLinecap="round"
    />
    <path
      d="M9.16699 9.16602H5.00033"
      stroke={color}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.91699 10.834L8.50625 11.4232C8.26792 11.6616 7.90948 11.7329 7.59809 11.6039C7.28669 11.4749 7.08366 11.171 7.08366 10.834H7.91699ZM9.58366 9.16732L10.1729 8.57806C10.4984 8.9035 10.4984 9.43114 10.1729 9.75657L9.58366 9.16732ZM7.91699 7.50065H7.08366C7.08366 7.1636 7.28669 6.85974 7.59809 6.73075C7.90948 6.60177 8.26792 6.67306 8.50625 6.9114L7.91699 7.50065ZM7.32774 10.2447L8.9944 8.57806L10.1729 9.75657L8.50625 11.4232L7.32774 10.2447ZM8.9944 9.75657L7.32774 8.08991L8.50625 6.9114L10.1729 8.57806L8.9944 9.75657ZM8.75033 7.50065V10.834H7.08366L7.08366 7.50065H8.75033Z"
      fill={color}
    />
  </svg>
);

export default DeliveryIcon;
