import { Toolbar } from '@mui/material';
import { HEADER } from 'src/config-global';
import useOffSetTop from '@hooks/useOffSetTop';
import useResponsive from '@hooks/useResponsive';
import { IconButtonAnimate } from '@components/animate';
import Iconify from '@components/iconify/Iconify';
import Logo from '@components/images/Logo';
import HeaderRootStyle from '../styles';

type Props = {
  onOpenSidebar: VoidFunction;
  isCollapse?: boolean;
  verticalLayout?: boolean;
  headingComponent: React.ReactNode;
};

export default function CustomHeader({
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
  headingComponent,
}: Props) {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
  const isDesktop = useResponsive('up', 'lg');

  return (
    <HeaderRootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 5 },
        }}
      >
        {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}
        {!isDesktop && (
          <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}
        {headingComponent}
      </Toolbar>
    </HeaderRootStyle>
  );
}
