import React from 'react';

const CheckBigIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="none" viewBox="0 0 56 56">
    <path
      fill="#00AB55"
      d="M28 56c15.464 0 28-12.536 28-28S43.464 0 28 0 0 12.536 0 28s12.536 28 28 28z"
    />
    <path
      fill="#fff"
      stroke="#F2F5F9"
      strokeWidth="0.7"
      d="M23.765 34.034l.273.25.234-.286L37.254 18.14a2.712 2.712 0 114.198 3.436L24.836 41.872a.35.35 0 01-.507.036l-9.762-8.946a2.45 2.45 0 01-.151-3.462l.354-.386a2.45 2.45 0 013.461-.151l5.534 5.07z"
    />
  </svg>
);

export default CheckBigIcon;
