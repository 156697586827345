import { Box, Stack, Toolbar, Divider } from '@mui/material';
import useOffSetTop from '@hooks/useOffSetTop';
import useResponsive from '@hooks/useResponsive';
import { HEADER } from 'src/config-global';
import Logo from '@components/images/Logo';
import Iconify from '@components/iconify/Iconify';
import { IconButtonAnimate } from '@components/animate';
import AccountPopover from './components/AccountPopover';
import StorePopover from './store/StorePopover';
import WaitingTime from './components/WaitingTime';
import Autoprint from './components/Autoprint';
import HeaderRootStyle from './styles';

type Props = {
  onOpenSidebar: VoidFunction;
  isCollapse?: boolean;
  verticalLayout?: boolean;
};

export default function DashboardHeader({
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
}: Props) {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
  const isDesktop = useResponsive('up', 'lg');

  return (
    <HeaderRootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
      <Toolbar
        sx={{
          minHeight: '100% !important',
        }}
      >
        {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

        {!isDesktop && (
          <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <StorePopover isCollapse={isCollapse} />
          {/* <Searchbar /> */}
        </Stack>

        <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />

        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          {/* <LanguagePopover /> */}
          {/* <NotificationsPopover /> */}
          {/* <ContactsPopover /> */}
          <WaitingTime />
          <Autoprint />
          <AccountPopover isCollapse={isCollapse} />
        </Stack>
      </Toolbar>
    </HeaderRootStyle>
  );
}
