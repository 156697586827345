import { OrderStatusType, OrderType } from 'src/@types/v2/orders/list';

const allStatuses: OrderStatusType[] = [
  'order_received',
  'waiting_payment',
  'processing',
  'ready_to_ship',
  'ready_to_pickup',
  'on_delivery',
  'completed',
];

const orderStatusesPickup: OrderStatusType[] = [
  'order_received',
  'waiting_payment',
  'processing',
  'ready_to_pickup',
  'completed',
];

const orderStatusesDelivery: OrderStatusType[] = [
  'order_received',
  'waiting_payment',
  'processing',
  'ready_to_ship',
  'on_delivery',
  'completed',
];

const restructureOrder = {
  forCardOverview: (
    data: OrderType[]
  ): {
    all: { order_status: OrderStatusType; total: number }[];
    pickup: { order_status: OrderStatusType; total: number }[];
    delivery: { order_status: OrderStatusType; total: number }[];
  } => {
    if (!data) return { all: [], pickup: [], delivery: [] };

    return {
      all: allStatuses.map((status) => {
        const total = data.filter((item) => item.order_status === status).length;
        return {
          order_status: status,
          total: total || 0,
        };
      }),
      pickup: orderStatusesPickup.map((status) => {
        const total = data.filter((item) => item.order_status === status).length;
        return {
          order_status: status,
          total: total || 0,
        };
      }),
      delivery: orderStatusesDelivery.map((status) => {
        const total = data.filter((item) => item.order_status === status).length;
        return {
          order_status: status,
          total: total || 0,
        };
      }),
    };
  },
  forDetailOverview: (
    data: OrderType[]
  ): {
    pickup: { status: OrderStatusType; orders: OrderType[] }[];
    delivery: { status: OrderStatusType; orders: OrderType[] }[];
  } => {
    if (!data) return { pickup: [], delivery: [] };

    const groupOrdersByStatus = (orders: OrderType[], orderStatuses: OrderStatusType[]) => {
      const ordersByStatus = orderStatuses.map((status) => ({
        status,
        orders: orders.filter((order) => order.order_status === status),
      }));
      return ordersByStatus;
    };

    const pickup = groupOrdersByStatus(data, orderStatusesPickup);
    const delivery = groupOrdersByStatus(data, orderStatusesDelivery);

    return { pickup, delivery };
  },
};

export default restructureOrder;
