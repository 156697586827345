import { AxiosInstance } from 'axios';
import { setSelectedOrderDetails, startLoading } from '@redux/reducers/orders/details';
import { AppDispatch } from '@redux/store';

export const getOrderById =
  (orderId: string, axiosInstance: AxiosInstance) => async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoading());
      const { data } = await axiosInstance.get(`/v2/orders/${orderId}?populate=coupon`);
      dispatch(setSelectedOrderDetails(data.data));
    } catch (err) {
      console.log(err);
    }
  };

export const prioritizeOrder =
  (orderId: string, axiosInstance: AxiosInstance) => async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoading());
      await axiosInstance.put(`/v2/orders/${orderId}/prioritize`);
      dispatch(getOrderById(orderId, axiosInstance));
    } catch (error: any) {
      console.log(error);
    }
  };
