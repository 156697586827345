// @mui
import { Box, Toolbar } from '@mui/material';
// components
import { useRouter } from 'next/router';
import { PATH_DASHBOARD } from '@routes/paths';
import { HEADER } from 'src/config-global';
import useOffSetTop from '@hooks/useOffSetTop';
import useResponsive from '@hooks/useResponsive';
import { IconButtonAnimate } from '@components/animate';
import Iconify from '@components/iconify/Iconify';
import Logo from '@components/images/Logo';
import { BoxFlexSS } from 'src/styles';
import { useBranches } from '@redux/reducers/settings/branches';
import { HeaderGoBack } from '@components/breadcrumbs';
import HeaderRootStyle from '../styles';

type Props = {
  onOpenSidebar: VoidFunction;
  isCollapse?: boolean;
  verticalLayout?: boolean;
};

export default function InventorySettingsHeader({
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
}: Props) {
  const router = useRouter();
  const { adminBranches } = useBranches();
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
  const isDesktop = useResponsive('up', 'lg');

  const handleBack = () => {
    router.back();
  };
  const branchId = router.query.branchId as string;
  const branch = adminBranches.find((b) => b._id === branchId);

  return (
    <HeaderRootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 5 },
        }}
      >
        {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

        {!isDesktop && (
          <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}
        <BoxFlexSS>
          <HeaderGoBack
            heading={`${branch?.location_name} - Inventory settings`}
            links={[
              {
                name: 'Inventory management',
                href: PATH_DASHBOARD.inventory.root,
              },
              { name: 'Settings' },
            ]}
          />
        </BoxFlexSS>

        <Box sx={{ flexGrow: 1 }} />
      </Toolbar>
    </HeaderRootStyle>
  );
}
