import { format, getTime, formatDistanceToNow, isValid } from 'date-fns';

export function fDate(date: Date | string | number | null, formatStr?: string) {
  if (!date) return 'Date is null';
  const fm = formatStr || 'dd MMM yyyy';
  const parsedDate = new Date(date);
  return isValid(parsedDate) ? format(parsedDate, fm) : 'Invalid Date';
}

export function fDateMonthShort(date: Date | string | number) {
  const parsedDate = new Date(date);
  return isValid(parsedDate) ? format(parsedDate, 'dd MMM yyyy') : 'Invalid Date';
}

export function fDateTime(date: Date | string | number) {
  const parsedDate = new Date(date);
  return isValid(parsedDate) ? format(parsedDate, 'dd MMM yyyy p') : 'Invalid Date';
}

export function fTimestamp(date: Date | string | number) {
  const parsedDate = new Date(date);
  return isValid(parsedDate) ? getTime(parsedDate) : 'Invalid Date';
}

export function fDateTimeSuffix(date: Date | string | number) {
  const parsedDate = new Date(date);
  return isValid(parsedDate) ? format(parsedDate, 'dd/MM/yyyy hh:mm p') : 'Invalid Date';
}

export function fDateFull(date: Date | string | number) {
  const parsedDate = new Date(date);
  return isValid(parsedDate) ? format(parsedDate, "dd MMMM yyyy, 'at' hh:mm aa") : 'Invalid Date';
}

export function fToNow(date: Date | string | number) {
  const parsedDate = new Date(date);
  return isValid(parsedDate)
    ? formatDistanceToNow(parsedDate, { addSuffix: true })
    : 'Invalid Date';
}

export function fTime(date: Date | string | number) {
  const parsedDate = new Date(date);
  return isValid(parsedDate) ? format(parsedDate, 'hh:mm p') : 'Invalid Date';
}
