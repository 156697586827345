import React from 'react';

function ProductIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 3C2.448 3 2 3.448 2 4V6C2 6.552 2.448 7 3 7H21C21.552 7 22 6.552 22 6V4C22 3.448 21.552 3 21 3H3ZM4 9C3.448 9 3 9.448 3 10V19C3 20.105 3.895 21 5 21H19C20.105 21 21 20.105 21 19V10C21 9.448 20.552 9 20 9H4ZM15 16H17C17.552 16 18 16.448 18 17C18 17.552 17.552 18 17 18H15C14.448 18 14 17.552 14 17C14 16.448 14.448 16 15 16Z"
        fill="#565B60"
      />
    </svg>
  );
}

export default ProductIcon;
