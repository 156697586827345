import React from 'react';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { OrderType } from 'src/@types/v2/orders/list';
import { MailIcon } from 'src/assets/icon/MailIcon';
import { PhoneIcon } from 'src/assets/icon/PhoneIcon';

interface Props {
  selectedOrderDetails: OrderType;
}

function RightSection({ selectedOrderDetails }: Props) {
  const { customer, billing } = selectedOrderDetails;

  if (!customer) {
    return (
      <>
        <Box mx={3}>
          <Typography mt="24px" variant="h6">
            Customer info
          </Typography>
          <Typography variant="body1" mt={2}>
            {billing.name}
          </Typography>
          {billing.email?.email_address && (
            <Stack direction="row" mt={1.5}>
              <MailIcon />
              <Typography sx={{ ml: 1 }} variant="body1">
                {billing.email?.email_address}
              </Typography>
            </Stack>
          )}
          {billing.phone && (
            <Stack direction="row" mt={1.5}>
              <PhoneIcon />
              <Typography sx={{ ml: 1 }} variant="body1">
                {billing.phone}
              </Typography>
            </Stack>
          )}
        </Box>
        <Divider sx={{ my: '20px' }} />
        {/* <Stack mx={3} gap={1}>
          <Button
            startIcon={<FileTextIcon size={18} />}
            variant="outlined"
            color="inherit"
            fullWidth
          >
            Receipt
          </Button>
          <Button startIcon={<RepeatIcon size={18} />} variant="outlined" color="inherit" fullWidth>
            Refund
          </Button>
          <Button
            startIcon={<PrinterIcon size={18} />}
            variant="outlined"
            color="inherit"
            fullWidth
          >
            Print order
          </Button>
        </Stack> */}
      </>
    );
  }

  return (
    <>
      <Box mx={3}>
        <Typography mt="24px" variant="h6">
          Customer info
        </Typography>
        <Typography variant="body1" mt={2}>
          {customer.name}
        </Typography>
        <Typography variant="body1" color="text.secondary" mt={1}>
          SSN: {customer.ssn}
        </Typography>
        <Stack direction="row" mt={1.5}>
          <MailIcon />
          <Typography sx={{ ml: 1 }} variant="body1">
            {customer.email.email_address}
          </Typography>
        </Stack>
        <Stack direction="row" mt={1.5}>
          <PhoneIcon />
          <Typography sx={{ ml: 1 }} variant="body1">
            {customer.phone.phone_number}
          </Typography>
        </Stack>
      </Box>
      <Divider sx={{ my: '20px' }} />
      {/* <Stack mx={3} gap={1}>
        <Button startIcon={<FileTextIcon size={18} />} variant="outlined" color="inherit" fullWidth>
          Receipt
        </Button>
        <Button startIcon={<RepeatIcon size={18} />} variant="outlined" color="inherit" fullWidth>
          Refund
        </Button>
        <Button startIcon={<PrinterIcon size={18} />} variant="outlined" color="inherit" fullWidth>
          Print order
        </Button>
      </Stack> */}
    </>
  );
}

export default RightSection;
