const getInitials = (input: string) => {
  if (input) {
    const names = input.split(' ');
    let initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  }
  return 'U';
};

export default getInitials;
