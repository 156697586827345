interface Props {
  size?: number;
  color?: string;
}

const DineInIcon = ({ size = 24, color = '#637381' }: Props) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.671438 10.742C0.620613 11.1994 0.950226 11.6114 1.40765 11.6622C1.86507 11.713 2.27709 11.3834 2.32791 10.926L0.671438 10.742ZM1.08301 0.000651121C0.622771 0.000651121 0.249675 0.373747 0.249675 0.833984C0.249675 1.29422 0.622771 1.66732 1.08301 1.66732V0.000651121ZM15.6714 10.926C15.7223 11.3834 16.1343 11.713 16.5917 11.6622C17.0491 11.6114 17.3787 11.1994 17.3279 10.742L15.6714 10.926ZM16.9163 1.66732C17.3766 1.66732 17.7497 1.29422 17.7497 0.833984C17.7497 0.373747 17.3766 0.000651121 16.9163 0.000651121V1.66732ZM2.33301 1.66732H15.6663V0.000651121H2.33301V1.66732ZM14.833 0.833984V3.33398H16.4997V0.833984H14.833ZM15.6663 2.50065H2.33301V4.16732H15.6663V2.50065ZM3.16634 3.33398V0.833984H1.49967V3.33398H3.16634ZM2.32791 10.926L3.16124 3.42601L1.50477 3.24196L0.671438 10.742L2.32791 10.926ZM2.33301 0.000651121H1.08301V1.66732H2.33301V0.000651121ZM17.3279 10.742L16.4946 3.24196L14.8381 3.42601L15.6714 10.926L17.3279 10.742ZM15.6663 1.66732H16.9163V0.000651121H15.6663V1.66732Z"
      fill={color}
    />
  </svg>
);

export default DineInIcon;
