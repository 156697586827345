import { styled, Box, IconButton, Avatar, alpha } from '@mui/material';

export const BoxFlexCC = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const BoxFlexBC = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const BoxFlexBE = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'end',
}));

export const BoxFlexBS = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'start',
}));

export const BoxFlexSC = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'justify-start',
  alignItems: 'center',
}));

export const BoxFlexEC = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'justify-end',
  alignItems: 'center',
}));

export const BoxFlexEE = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'end',
}));

export const BoxFlexSS = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'justify-start',
  alignItems: 'start',
}));

export const CustomAvatar = styled(Avatar)(() => ({
  color: 'white',
}));

export const Dot = styled(Box)(({ theme }) => ({
  width: '5px',
  height: '5px',
  borderRadius: '100%',
  background: alpha(theme.palette.grey[500], 0.32),
}));

export const BorderBC = styled(BoxFlexBC)(({ theme }) => ({
  padding: theme.spacing(2),
  border: '1px solid #E5E7E9',
  borderRadius: '8px',
}));

export const BorderBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  border: '1px solid #E5E7E9',
  borderRadius: '8px',
}));

export const BoxGreyBC = styled(BoxFlexBC)(({ theme }) => ({
  padding: '20px',
  background: theme.palette.background.neutral,
  border: '1px solid #E5E7E9',
  borderRadius: '5px',
}));

export const BoxGrey = styled(Box)(({ theme }) => ({
  padding: '20px',
  background: theme.palette.background.neutral,
  border: '1px solid #E5E7E9',
  borderRadius: '5px',
}));

export const CircleBorder = styled(IconButton)(() => ({
  border: '0.727273px solid #E5E7E9',
  borderRadius: '100%',
  padding: '5px',
  cursor: 'pointer',
}));
