import { Box, Stack, Toolbar, Button, IconButton, Typography } from '@mui/material';
import { Icon } from '@iconify/react';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { prioritizeOrder } from '@redux/actions/orders/details';
import HeaderBreadcrumbs from '@components/header/components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '@routes/paths';
import { useDispatch } from '@redux/store';
import { HEADER } from 'src/config-global';
import useOffSetTop from '@hooks/useOffSetTop';
import useResponsive from '@hooks/useResponsive';
import { IconButtonAnimate } from '@components/animate';
import Iconify from '@components/iconify/Iconify';
import Logo from '@components/images/Logo';
import { BoxFlexSS } from 'src/styles';
import { useOrderDetails } from '@redux/reducers/orders/details';
import TransactionHistoryDialog from '@sections/dashboard/orders/order-details/dialog/transaction-history/TransactionHistoryDialog';
import { useAxios } from 'src/auth/AxiosContext';
import {
  getWebhookAlertColor,
  getWebhookAlertMessage,
} from '@sections/dashboard/order-display/layout-compact/WoltAlert';
import { OrderStatusChip } from '@styles/orders';
import HeaderRootStyle from '../styles';

type Props = {
  onOpenSidebar: VoidFunction;
  isCollapse?: boolean;
  verticalLayout?: boolean;
};

export default function OrderDetailHeader({
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
}: Props) {
  const axiosInstance = useAxios();
  const { selectedOrderDetails } = useOrderDetails();
  const [selectedAction, setSelectedAction] = useState('');
  const [isTransactionHistoryOpen, setIsTransactionHistoryOpen] = useState(false);

  const router = useRouter();
  const dispatch = useDispatch();

  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
  const isDesktop = useResponsive('up', 'lg');

  const handleDialog = () => {
    setIsTransactionHistoryOpen((prev) => !prev);
  };

  useEffect(() => {
    switch (selectedAction) {
      case 'Edit order':
        if (selectedOrderDetails) {
          router.push(PATH_DASHBOARD.orders.orderEdit(selectedOrderDetails._id));
        }
        break;
      default:
    }
  }, [selectedAction]);

  const handleBack = () => {
    router.back();
  };

  return (
    <HeaderRootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 5 },
        }}
      >
        {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

        {!isDesktop && (
          <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}
        <BoxFlexSS>
          <IconButton
            onClick={handleBack}
            size="small"
            sx={{ border: 1, borderColor: 'lightgray', mr: 2, mb: 'auto', mt: 1 }}
          >
            <Icon icon="eva:chevron-left-fill" color="gray" width={20} height={20} />
          </IconButton>
          {selectedOrderDetails && selectedOrderDetails.secondary_order_number ? (
            <HeaderBreadcrumbs
              mb={0}
              heading={`${
                selectedOrderDetails.secondary_order_number < 10
                  ? `0${selectedOrderDetails.secondary_order_number}`
                  : selectedOrderDetails.secondary_order_number
              }`}
              headingRightElement={
                <>
                  <Box
                    component="span"
                    sx={{
                      ml: 2,
                      width: 4,
                      height: 4,
                      borderRadius: '50%',
                      bgcolor: 'text.disabled',
                    }}
                  />
                  <Typography variant="body1" sx={{ ml: 2 }} color="black">
                    #{selectedOrderDetails?.order_number}
                  </Typography>
                  <Box sx={{ ml: 2 }}>
                    <OrderStatusChip orderStatus={selectedOrderDetails?.order_status || ''} />
                  </Box>
                </>
              }
              headingVariant="h5"
              gutterBottom={false}
              links={[
                {
                  name: 'Orders',
                  href: PATH_DASHBOARD.orders.root,
                },
                { name: 'Order details' },
              ]}
            />
          ) : (
            <HeaderBreadcrumbs
              mb={0}
              heading={`Order #${selectedOrderDetails?.order_number || ''}`}
              headingRightElement={
                selectedOrderDetails?.order_status && (
                  <Box sx={{ ml: 2 }}>
                    <OrderStatusChip orderStatus={selectedOrderDetails?.order_status || ''} />
                  </Box>
                )
              }
              headingVariant="h5"
              gutterBottom={false}
              links={[
                {
                  name: 'Orders',
                  href: PATH_DASHBOARD.orders.root,
                },
                { name: 'Order details' },
              ]}
            />
          )}
        </BoxFlexSS>

        <Box sx={{ flexGrow: 1 }} />
        {selectedOrderDetails && (
          <TransactionHistoryDialog
            isOpen={isTransactionHistoryOpen}
            handleDialog={handleDialog}
            selectedOrderDetails={selectedOrderDetails}
          />
        )}
        {selectedOrderDetails?.order_status !== 'completed' && (
          <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
            <Button size="large" onClick={handleDialog} variant="outlined">
              View transaction history
            </Button>
            {!selectedOrderDetails?.prioritized && (
              <Button
                size="large"
                onClick={() => {
                  if (selectedOrderDetails) {
                    dispatch(prioritizeOrder(selectedOrderDetails._id, axiosInstance));
                  }
                }}
                variant="outlined"
                sx={{
                  color: '#EA9444',
                  border: '1px solid #EA9444',
                  '&:hover': {
                    backgroundColor: '#f7f1eb',
                    border: '1px solid #EA9444',
                  },
                }}
              >
                <Iconify
                  icon="ic:round-star-outline"
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
                <Typography variant="body1" sx={{ ml: 1 }}>
                  Set order as prioritize
                </Typography>
              </Button>
            )}
            {selectedOrderDetails?.prioritized && (
              <Button
                size="large"
                onClick={() => {
                  if (selectedOrderDetails) {
                    dispatch(prioritizeOrder(selectedOrderDetails._id, axiosInstance));
                  }
                }}
                variant="outlined"
                sx={{
                  color: 'white',
                  border: '1px solid #EA9444',
                  backgroundColor: '#EA9444',
                  '&:hover': {
                    backgroundColor: '#EA9444',
                    border: '1px solid #EA9444',
                  },
                }}
              >
                <Iconify
                  icon="ic:round-star-outline"
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
                <Typography variant="body1" sx={{ ml: 1 }}>
                  Prioritized order
                </Typography>
              </Button>
            )}
          </Stack>
        )}
        {selectedOrderDetails?.order_status === 'completed' && (
          <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
            <Button size="large" onClick={handleDialog} variant="outlined">
              View transaction history
            </Button>
          </Stack>
        )}
      </Toolbar>
      {selectedOrderDetails && selectedOrderDetails.data?.wolt_drive_order_id && (
        <Stack
          sx={{
            width: '100%',
            bgcolor: getWebhookAlertColor(selectedOrderDetails?.data?.wolt_status),
            color: '#fff',
          }}
        >
          <Stack
            direction="row"
            width="100%"
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
            p={1.5}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              {getWebhookAlertMessage(selectedOrderDetails?.data?.wolt_status)}
            </Stack>
          </Stack>
        </Stack>
      )}
    </HeaderRootStyle>
  );
}
