import { Stack, Typography } from '@mui/material';
import Avatar from './Avatar';
import AvatarInitial from './AvatarInitial';

interface Props {
  url: string;
  alt: string;
  profileColor: string;
  title: string;
  subtitle?: string;
}

export default function AvatarCustom({ url, alt, profileColor, title, subtitle }: Props) {
  return (
    <Stack direction="row" alignItems="center">
      {url ? <Avatar alt={alt} src={url} /> : <AvatarInitial text={title} bgColor={profileColor} />}
      <Stack direction="column" sx={{ px: 1.5, textAlign: 'start' }}>
        <Typography variant="subtitle2" noWrap color="black">
          {title}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
          {subtitle}
        </Typography>
      </Stack>
    </Stack>
  );
}
