import { Stack, Typography } from '@mui/material';
import React from 'react';
import SingleChip from './SingleChip';

interface Props {
  limit: number;
  names: string[];
}

const MultiChips = ({ limit, names }: Props) => (
  <Stack direction="row" alignItems="center" spacing={1}>
    {names.slice(0, limit).map((name, i) => (
      <SingleChip
        key={i}
        variant="caption"
        sx={{ borderRadius: '50px' }}
        label={name}
        bgColor="#F2F2F2"
        textColor="#212B36"
      />
    ))}
    {names.length > limit && (
      <Typography variant="subtitle2" color="primary">
        + {names.length - limit} more
      </Typography>
    )}
  </Stack>
);

export default MultiChips;
