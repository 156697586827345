import { OrderMethodType } from 'src/@types/v2/orders/list';

const getOrderMethodLabel = (orderMethod: OrderMethodType): string => {
  switch (orderMethod) {
    case 'pickup':
      return 'Pickup';
    case 'delivery':
      return 'Delivery';
    case 'dine-in':
      return 'Dine-in';
    case 'wolt':
      return 'Wolt';
    case 'shipping':
      return 'Shipping';
    case 'instant':
      return 'Instant';
    case 'pending':
      return 'Pending';
    default:
      return 'Unknown';
  }
};

export default getOrderMethodLabel;
