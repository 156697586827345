import { useState, ReactNode, useEffect } from 'react';
// next
import { useRouter } from 'next/router';
// hooks
import { usePropelAuthContext } from 'src/auth/useAuthContext';
import Login from '../pages/auth/login';
// components
import LoadingScreen from '../components/loading/LoadingScreen';
import { redirectToKDS } from './utils';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

export default function AuthGuard({ children }: Props) {
  const { isAuthenticated, isInitialized } = usePropelAuthContext();
  const { permissions } = usePropelAuthContext();
  const { pathname, push } = useRouter();

  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  useEffect(() => {
    if (requestedLocation && pathname !== requestedLocation) {
      push(requestedLocation);
    }
    if (isAuthenticated) {
      const kdsUrl = redirectToKDS(pathname, permissions);
      const redirectUrl = kdsUrl;
      setRequestedLocation(redirectUrl);
    }
  }, [isAuthenticated, pathname, permissions, push, requestedLocation]);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  return <>{children}</>;
}
