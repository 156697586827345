import { Button, Menu, Box, MenuItem, Typography } from '@mui/material';
import React from 'react';
import Iconify from '@components/iconify/Iconify';
import { BoxFlexSS } from 'src/styles';

type DropdownFixType = {
  data: {
    value: string;
    label: string;
    icon?: JSX.Element;
    variant?: string;
    disabled?: boolean;
  }[];
  onClickItem: (val: string) => void;
  textButton?: string;
  menuWidth?: number;
};

export default function DropdownAction({
  data,
  onClickItem,
  textButton = 'Action',
  menuWidth = 140,
}: DropdownFixType) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const determineColor = (variant: string) => {
    switch (variant) {
      case 'error':
        return 'error';
      case 'primary':
        return 'primary';
      default:
        return '';
    }
  };
  return (
    <>
      <Button
        size="large"
        variant="outlined"
        color="inherit"
        endIcon={<Iconify icon={open ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'} />}
        onClick={handleClick}
        sx={{ background: '#fff', py: 2 }}
      >
        <Typography variant="body2">{textButton}</Typography>
      </Button>

      <Menu
        TransitionProps={{ timeout: 0 }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          sx: { width: menuWidth, maxHeight: 300 },
        }}
      >
        {data.map(
          (item, i) =>
            !item.disabled && (
              <MenuItem key={i} onClick={() => onClickItem(item.value)} value={item.value}>
                <BoxFlexSS>
                  {item.icon && <Box mr={2}>{item.icon}</Box>}
                  <Typography variant="body2" color={determineColor(`${item.variant}`)}>
                    {item.label}
                  </Typography>
                </BoxFlexSS>
              </MenuItem>
            )
        )}
      </Menu>
    </>
  );
}
