import { AxiosInstance } from 'axios';
import {
  setCategories,
  hasError,
  startLoading,
  setCurrentCategory,
  setSubmitting,
} from '@redux/reducers/categories';
import { SnackbarType } from 'src/@types/v2/snackbar';
import { NewCategoryFormProps } from 'src/validations/restaurant/category/new-category';
import { AppDispatch, RootState } from '@redux/store';
import { showSnackbar } from '@utils/snackbar';
import { uploadBlobURLToLibrary } from '@utils/media-library';
import { CategoryType } from 'src/@types/v2/categories';

export function getCategories(axiosInstance: AxiosInstance) {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoading());
      const { data } = await axiosInstance.get(`/v2/categories`);
      dispatch(setCategories(data.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getCategoryById(categoryId: string, axiosInstance: AxiosInstance) {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoading());
      const url = `/v2/categories/${categoryId}`;
      const { data } = await axiosInstance.get(url);
      dispatch(setCurrentCategory(data.data));
      return data.data as CategoryType;
    } catch (error) {
      dispatch(hasError(error));
      return undefined;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteCategory(
  ids: string[],
  enqueueSnackbar: SnackbarType,
  axiosInstance: AxiosInstance
) {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoading());
      await Promise.all(ids.map((id) => axiosInstance.delete(`/v2/categories/${id}`)));
      dispatch(getCategories(axiosInstance));
      showSnackbar({
        message:
          ids.length > 1 ? 'Categories deleted successfully' : 'Category deleted successfully',
        variant: 'success',
        enqueueSnackbar,
      });
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function addCategory(
  values: NewCategoryFormProps,
  enqueueSnackbar: SnackbarType,
  redirect: () => void,
  axiosInstance: AxiosInstance
) {
  return async (dispatch: AppDispatch) => {
    try {
      let imageUrl = values.image;

      if (imageUrl) {
        imageUrl = await uploadBlobURLToLibrary({
          blobURL: imageUrl,
          axiosInstance,
        });
      }

      const productIds = values.products.map((item) => item._id);

      const newData = {
        name: values.name,
        accountant_keys: values.accountantKey,
        image: imageUrl,
        products: productIds,
        visibility: values.visibility ? 'visible' : 'hidden',
        parent: values.parentCategory || null,
        color: values.color,
        display_on_kitchen: values.display_on_kitchen,
      };

      const finalData = Object.fromEntries(Object.entries(newData).filter(([_, v]) => v != null));
      await axiosInstance.post(`/v2/categories`, finalData);
      showSnackbar({
        message: 'Success create category',
        variant: 'success',
        enqueueSnackbar,
        redirect,
      });
    } catch (error) {
      showSnackbar({
        message: 'Failed to create category. If the error persists, please contact support.',
        variant: 'error',
        enqueueSnackbar,
      });
      dispatch(hasError(error));
    }
  };
}

export function addQuickCategory(
  values: NewCategoryFormProps,
  enqueueSnackbar: SnackbarType,
  axiosInstance: AxiosInstance
) {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { data: categoriesData } = getState().categories;
      let imageUrl = values.image;

      if (imageUrl) {
        imageUrl = await uploadBlobURLToLibrary({
          blobURL: imageUrl,
          axiosInstance,
        });
      }

      const productIds = values.products.map((item) => item._id);

      const newData = {
        name: values.name,
        accountant_keys: values.accountantKey,
        image: imageUrl,
        products: productIds,
        visibility: values.visibility ? 'visible' : 'hidden',
        parent: values.parentCategory || null,
        color: values.color,
        display_on_kitchen: values.display_on_kitchen,
      };

      const finalData = Object.fromEntries(Object.entries(newData).filter(([_, v]) => v != null));
      const { data } = await axiosInstance.post(`/v2/categories`, finalData);
      const parentId = values.parentCategory;
      let mergedData = [];
      if (parentId) {
        mergedData = categoriesData.map((category) => {
          if (category._id === parentId) {
            return {
              ...category,
              sub_categories: [...(category.sub_categories || []), data.data],
            };
          }
          return category;
        });
      } else {
        mergedData = [...categoriesData, data.data];
      }

      dispatch(setCategories(mergedData));
      showSnackbar({
        message: 'Success create category',
        variant: 'success',
        enqueueSnackbar,
      });
    } catch (error) {
      showSnackbar({
        message: 'Failed to create category. If the error persists, please contact support.',
        variant: 'error',
        enqueueSnackbar,
      });
      dispatch(hasError(error));
    }
  };
}

export function updateCategory(
  values: NewCategoryFormProps,
  categoryId: string,
  enqueueSnackbar: SnackbarType,
  redirect: () => void,
  axiosInstance: AxiosInstance
) {
  return async (dispatch: AppDispatch) => {
    try {
      let imageUrl = values.image;

      if (imageUrl) {
        imageUrl = await uploadBlobURLToLibrary({
          blobURL: imageUrl,
          axiosInstance,
        });
      }

      const productIds = values.products.map((item) => item._id);

      const newData = {
        name: values.name,
        accountant_keys: values.accountantKey,
        image: imageUrl,
        products: productIds,
        visibility: values.visibility ? 'visible' : 'hidden',
        parent: values.parentCategory || null,
        color: values.color,
        display_on_kitchen: values.display_on_kitchen,
      };

      const finalData = Object.fromEntries(Object.entries(newData).filter(([_, v]) => v != null));

      await axiosInstance.put(`/v2/categories/${categoryId}`, finalData);

      showSnackbar({
        message: 'Success update category',
        variant: 'success',
        enqueueSnackbar,
        redirect,
      });
    } catch (error) {
      showSnackbar({
        message: 'Failed to update category. If the error persists, please contact support.',
        variant: 'error',
        enqueueSnackbar,
      });
      dispatch(hasError(error));
    }
  };
}

export function editVisibilityCategory(id: string, data: boolean, axiosInstance: AxiosInstance) {
  return async (dispatch: AppDispatch) => {
    try {
      await axiosInstance.put(`/v1/categories/update/${id}`, { available: data });
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function updateCategoryKitchenDisplay(
  values: { name: string; display_on_kitchen: boolean },
  id: string,
  axiosInstance: AxiosInstance
) {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoading());
      const newData = {
        name: values.name,
        display_on_kitchen: values.display_on_kitchen,
      };

      const finalData = Object.fromEntries(Object.entries(newData).filter(([_, v]) => v != null));

      await axiosInstance.put(`/v2/categories/${id}`, finalData).then((res) => {
        dispatch(getCategories(axiosInstance));
      });
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function updateCategoriesPosition(
  categories: CategoryType[],
  enqueueSnackbar: SnackbarType,
  axiosInstance: AxiosInstance,
  cb?: () => void
) {
  return async (dispatch: AppDispatch) => {
    dispatch(setSubmitting(true));

    try {
      await Promise.all(
        categories.map((cat) =>
          axiosInstance.put(`/v2/categories/${cat._id}`, {
            position: cat.position,
          })
        )
      );

      dispatch(getCategories(axiosInstance));

      showSnackbar({
        message: 'Category position updated successfully',
        variant: 'success',
        enqueueSnackbar,
      });
    } catch (error) {
      console.log(error);
      showSnackbar({
        message: 'Error updating category position. If the error persists, please contact support.',
        variant: 'error',
        enqueueSnackbar,
      });
      dispatch(hasError(error));
    } finally {
      dispatch(setSubmitting(false));
      if (cb) cb();
    }
  };
}
